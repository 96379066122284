import { api, LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { CardState } from "../../tbui/card/types";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import { getClient } from "tbme/clientAdapter";
import {
  GetCommunityTags,
  GetCommunityTags_communityTags,
  GetCommunityTags_profile_PublicProfile_communityTags,
  GetCommunityTagsVariables,
} from "./gql/GetCommunityTags";
import {COMMUNITY_TAGS_QUERY} from "./query";
import { COMMUNITY_TAGS_MUTATION } from "./mutation";
import { updateCommunityTagsVariables } from "./gql/updateCommunityTags";
import {chassis} from "tbme/localState";

interface CommunityTag {
  id: string;
  title: string;
  imageUrl: string;
}

export default class extends LightningElement {
  private isSelf: boolean = false;
  private labels: LabelTranslations = undefined!;
  private communityTags: GetCommunityTags_profile_PublicProfile_communityTags[] = [];
  private firstFetchLoading: boolean = true;
  private cardState: CardState = "loading";
  private showMoreTags: boolean = false;
  private tags: any[] = undefined!;
  private disabled: boolean = false;

  private variables: GetCommunityTagsVariables = {
    queryCommunityTags: false,
    hasSlug: false,
  };

  private options: GetCommunityTags_communityTags[] = [];
  private isEdit: boolean = false;

  @api initialTagsMax: number = 3;

  /** Should the community tags component be visible */
  get isVisible() {
    return (
      !this.disabled &&
      (this.isSelf || (this.hasCommunityTags && !this.firstFetchLoading))
    );
  }

  /* Are there any tags associated with this profile */
  get hasCommunityTags() {
    return this.communityTags.length > 0;
  }

  /** The subset of community tags that are initially visible */
  get visibleTags() {
    return this.communityTags.slice(0, this.initialTagsMax);
  }

  /** Are there more tags that aren't being displayed  */
  get moreTags() {
    return (
      this.initialTagsMax >= 0 &&
      this.communityTags?.length > this.initialTagsMax
    );
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "communityTags.title",
      "communityTags.emptyCommunityTagsContent",
      "communityTags.viewAllTags",
      "communityTags.sourceLabel",
      "communityTags.selectedLabel",
      "communityTags.label",
      "communityTags.editButton",
      "aboutMe.edit",
    ],
  })
  private handleLabels(labels: {
    communityTags: LabelTranslations;
    aboutMe: LabelTranslations;
  }) {
    this.labels = {
      ...labels.communityTags,
      ...labels.aboutMe,
    };
  }

  private handleEditClick() {
    this.isEdit = !this.isEdit;
  }

  private async handleSaveRequest(event: CustomEvent) {
    const editModal = this.template.querySelector(
      `[data-id="edit-modal"]`
    ) as any;

    const options = {
      variables: { tags: event.detail.value } as updateCommunityTagsVariables,
      mutation: COMMUNITY_TAGS_MUTATION,
    };

    const request = () => getClient().mutate(options);

    try {
      const { data, errors } = await request();
      if (errors || !data.updateCommunityTags.communityTags) {
        editModal.onSave("ERRORS");
      } else {
        editModal.onSave("SUCCESS");
        this.communityTags = data.updateCommunityTags.communityTags;
      }
    } catch (error) {
      editModal.onSave("ERROR");
    }
  }

  private handleViewAll() {
    this.showMoreTags = true;
  }

  private handleHide() {
    this.showMoreTags = false;
  }

  connectedCallback() {
    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    const isSelf = chassisData?.isSelf || false;
    
    const queryCommunityTags = !!isSelf;
    this.isSelf = isSelf;
    
    this.variables = {
      slug: username,
      hasSlug: !!username,
      queryCommunityTags,
    };
  }

  @wire(QueryAdapter, {
    query: COMMUNITY_TAGS_QUERY,
    variables: "$variables",
  })
  private handleResult(result: QueryAdapterValue<GetCommunityTags>) {
    const { data, loading, error, errors } = result;
    if (loading) {
      this.cardState = "loading";
      return;
    }
    
    const profile = data.profile;
    if (!profile) {
      return;
    }
    
    if (
      error ||
      errors ||
      profile.__typename !== "PublicProfile" ||
      !profile.communityTags ||
      (this.isSelf && !data.communityTags)
    ) {
      if (
        errors?.some(
          (e) =>
            e.extensions?.statusCode == "503" &&
            e.path?.includes("communityTags")
        )
      ) {
        this.disabled = true;
      }
      this.cardState = "loadFailed";
      this.firstFetchLoading = false;
      return;
    }

    this.communityTags = profile.communityTags ?? [];
    this.options = data.communityTags ?? [];
    this.cardState = "default";
    this.firstFetchLoading = false;
  }
}
