import { ApolloQueryResult, gql } from "@apollo/client";
import { api, LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { fetchCommunityProfile } from "tbtbc/communityData";
import { getError, getIsLoading, getUserId } from "../communityData/queryUtils";
import { LegacyCommunityConnections } from "../communityData/types";
import {
  UserActionsContext,
  UserActionsContext_siteInfo_urls,
} from "./gql/UserActionsContext";
import { follow, unfollow } from "../communityData/followActions";
import { Size } from "@devforce/th-components/modules/tds/button/button";

export const USER_ACTIONS_QUERY = gql`
  query UserActionsContext {
    profileUser @client {
      __typename
      ... on PublicProfile {
        isSelf

        communityUser @client {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
            error
          }
          ... on CommunityUser {
            id
          }
        }
      }
    }

    siteInfo @client {
      urls {
        tbcHireMe
        tbcMessageUser
      }
    }
  }
`;

export default class extends LightningElement {
  @api size: Size = "medium";
  @api showHireMeButton = false;

  @wire(MultiLabelAdapter, {
    labels: ["tbcUserActions.message", "tbcUserActions.hireMe"],
  })
  private labels: LabelTranslations = undefined!;
  private urls: UserActionsContext_siteInfo_urls = undefined!;
  private visible = false;

  connectedCallback() {
    fetchCommunityProfile();
  }

  @wire(QueryAdapter, {
    query: USER_ACTIONS_QUERY,
  })
  private handleResult({ data }: ApolloQueryResult<UserActionsContext>) {
    if (!data) {
      return;
    }

    this.urls = data.siteInfo.urls;

    const { profileUser } = data;

    if (!profileUser || profileUser.__typename !== "PublicProfile") {
      return;
    }

    const { communityUser, isSelf } = profileUser;
    const isTbcUser = !!getUserId(communityUser);

    if (getIsLoading(communityUser) || getError(communityUser)) {
      this.visible = false;
      return;
    }

    this.visible = !isSelf && isTbcUser;
  }
}
