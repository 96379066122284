import { api, wire, LightningElement } from "lwc";
import { LabelTranslations, t, MultiLabelAdapter } from "tbme/localization";
import { showToast } from "tds/toaster";

export default class extends LightningElement {
  @api title!: string;
  @api selectedLabel!: string;
  @api sourceLabel!: string;
  @api label!: string;
  isLoading: boolean = false;
  _isEdit?: boolean = false;
  _selection?: any[];
  _options?: any[];
  _isModified: boolean = false;

  @api
  onSave(status: string) {
    this.isLoading = false;
    if (status === "SUCCESS") {
      this.dispatchEvent(new CustomEvent("requestclose"));
      showToast({
        message: t("communityTags.successToast"),
        variant: "success",
      });
    } else {
      showToast({
        message: t("communityTags.errorToast"),
        variant: "error",
      });
    }
  }

  @api get isEdit() {
    return this._isEdit;
  }
  set isEdit(val: boolean | undefined) {
    this._isEdit = val;

    if (this._isEdit) {
      this._isModified = false;
    }
    const picklist = this.template.querySelector(
      `[data-id="dueling-picklist"]`
    ) as any;
    picklist?.focus();
  }

  @api get options() {
    return this._options;
  }
  set options(vals: any[] | undefined) {
    this._options = vals?.map((tag: any) => {
      return { value: tag.id, label: tag.title };
    });
  }

  @api get selection() {
    return this._selection;
  }
  set selection(vals: any[] | undefined) {
    this._selection = vals?.map((tag: any) => {
      return tag.id;
    });
  }

  get saveDisabled(): boolean {
    return this.isLoading || !this._isModified;
  }

  @wire(MultiLabelAdapter, {
    labels: ["saveButton", "cancelButton"],
  })
  private labels: LabelTranslations = {};
  private handleRequestClose = () => {
    if (!this.isLoading && this.isEdit) {
      const picklist = this.template.querySelector(
        `[data-id="dueling-picklist"]`
      ) as any;
      picklist.value = this.selection;
      this.dispatchEvent(new CustomEvent("requestclose"));
      picklist.focus();
    }
  };
  private handleSaveButtonClick = () => {
    if (!this.saveDisabled) {
      this.isLoading = true;
      const picklist = this.template.querySelector(
        `[data-id="dueling-picklist"]`
      ) as any;
      const saveVals = picklist.value;
      this.dispatchEvent(
        new CustomEvent("saverequest", {
          composed: true,
          bubbles: true,
          detail: { value: saveVals },
        })
      );
    }
  };

  private handleOnChange(event: CustomEvent) {
    this._isModified =
      event?.detail?.value != null &&
      this.selection != null &&
      event.detail.value.toString() !== this.selection.toString();
  }
}
