import { LightningElement, api } from "lwc";
import { ApolloClient, DataProxy, NormalizedCacheObject } from "@apollo/client";
import { createMockClient } from "./mockClient";
import { MockedResponse } from "@apollo/client/testing";
import { memoizeOnce } from "../../../shared/memoize";

export interface MockConfig {
  mocks: MockedResponse<any>[];
  writeQueries: DataProxy.WriteQueryOptions<any, any>[];
  writeFragments: DataProxy.WriteFragmentOptions<any, any>[];
}

export default class extends LightningElement {
  private mockClient: ApolloClient<NormalizedCacheObject> = undefined!;
  private pending?: Promise<void> | null;
  private config: MockConfig = {
    mocks: [],
    writeQueries: [],
    writeFragments: [],
  };

  /**
   * Providing the getter allows for the `client` to be retrieved from the property on the element. The setter should
   * never be used.
   */
  @api get client(): ApolloClient<NormalizedCacheObject> {
    return this.mockClient;
  }
  /**
   * This setter is provided because it is required by lwc, however, it should never be used as the client will be
   * re-created when the config properties change. Providing the getter allows for the `client` to be retrieved from
   * the property on the element.
   */
  set client(value) {
    return;
  }

  @api
  get mocks() {
    return this.config.mocks;
  }
  set mocks(value) {
    this.config.mocks = value;

    this.updateClient();
  }

  @api
  get writeQueries() {
    return this.config.writeQueries;
  }
  set writeQueries(value) {
    this.config.writeQueries = value;

    this.updateClient();
  }

  @api
  get writeFragments() {
    return this.config.writeFragments;
  }
  set writeFragments(value) {
    this.config.writeFragments = value;

    this.updateClient();
  }

  private updateClient() {
    const { mocks, writeQueries, writeFragments } = this;
    this.memoizedUpdate({ mocks, writeQueries, writeFragments });
  }

  private memoizedUpdate = memoizeOnce(
    ({ mocks, writeQueries, writeFragments }: MockConfig) => {
      this.mockClient = createMockClient(mocks);

      writeQueries.forEach((options) => {
        this.client.writeQuery(options);
      });

      writeFragments.forEach((options) => {
        this.client.writeFragment(options);
      });
    }
  );

  connectedCallback() {
    this.updateClient();
  }
}
