import { WireAdapter } from 'lwc';
import { get, set } from 'lodash';

type IISCompleteProfileResponse = {
  isProfileComplete: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: Record<string, any>;
  location?: string;
};

export class IISUtilAdapter<Value, Config>
  implements WireAdapter<Value, Config, Record<string, any>>
{
  dataCallback?: (val: IISUtil) => void;

  constructor(cb: (val: IISUtil) => void) {
    this.dataCallback = cb;
  }

  async connect() {
    const iisUtil = await getIISUtil();
    this.dataCallback?.(iisUtil);
    return iisUtil;
  }

  disconnect() {}

  update() {}
}

export const getIISUtil = async () => {
  if (!window.IISUtil) {
    await loadIIS();
  }

  return window.IISUtil;
};

const loadIIS = async () => {
  try {
    const env = getEnv();
    const version = artifactVersionMap[getEnv()];
    const iisVersion = window?.sfdcBase?.iisVersion || `v1-${version}`;
    const asset = env === 'prod' ? 'iis.min.js' : 'iis.js';
    const { default: iis } = await import(
      `https://a.sfdcstatic.com/digital/iis/${iisVersion}/${asset}`
    );
    window.IISUtil = iis as IISUtil;
    // Override IIS util function to ensure userInfo is decoded.l
    if (window.IISUtil?.getUserInfo && window.IISUtil?.refreshUserInfo) {
      window.IISUtil.getUserInfo = ((getUserInfo) => async () => {
        const u = await getUserInfo();
        const userInfo = u?.userInfo || u;
        return decodeJSONObject(userInfo, encodedUserInfoFields);
      })(window.IISUtil.refreshUserInfo);
      window.IISUtil.refreshUserInfo = ((refreshUserInfo) => async () => {
        const u = await refreshUserInfo();
        const userInfo = u?.userInfo || u;
        return decodeJSONObject(userInfo, encodedUserInfoFields);
      })(window.IISUtil.refreshUserInfo);
    }
  } catch (e) {
    console.error('unable to load iis.js:', e);
  }
};

// TODO: Possibly refactor when sfdcBase.env is set in each environment
export const getEnv = (): string => {
  let env = 'dev';
  if (window?.sfdcBase?.env) {
    env = window?.sfdcBase?.env;
  } else if (window.location.host) {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'www' || subdomain === 'tbid') {
      env = 'prod';
    } else if (subdomain.startsWith('www-') || subdomain.startsWith('tbid-')) {
      env = subdomain.split('-')[1].replace(/[0-9]/g, '');
      env = env === 'stage' ? 'uat' : env;
    }
  }
  return env.toLowerCase();
};

const artifactVersionMap: { [env: string]: string } = {
  qa: 'latest',
  uat: 'stage',
  prod: 'stable',
  dev: 'latest',
  int: 'stage',
  hotfix: 'stable',
  perf: 'stage',
  onboard: 'stable',
};

export const encodedUserInfoFields = [
  'custom_attributes.CompanyName',
  'custom_attributes.Title',
];

/**
 * Decodes url encoded property values of a JSON object.
 *
 * @param userInfo The raw JSON object with encoded free form fields.
 * @param applyTo An optional list of field paths (period delimited: custom_attributes.CompanyName) to apply the decoding to.
 * When left empty, it will apply the decoding to the entire userInfo object.
 * @returns
 *   UserInfo object with decoded fields.
 */
export const decodeJSONObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any> | undefined,
  applyTo: Array<string> | undefined = undefined
): Record<string, unknown> | undefined => {
  try {
    if (object && applyTo) {
      applyTo.forEach((fieldPath) => {
        const fieldValue = get(object, fieldPath);
        if (typeof fieldValue === 'object') {
          set(
            object,
            fieldPath,
            decodeJSONObject(fieldValue as Record<string, unknown>)
          );
        }

        if (typeof fieldValue === 'string') {
          set(object, fieldPath, decodeURIComponent(fieldValue));
        }
      });
      return object;
    } else {
      const string = JSON.stringify(object);
      const decodedString = decodeURIComponent(string);
      return JSON.parse(decodedString) as Record<string, unknown> | undefined;
    }
  } catch (e) {
    return object;
  }
};
