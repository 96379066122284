import { profileUser as setProfileUser } from "tbme/localState";
import { LocalProfile } from "../../localState/types";
import { ProfileContext, UserObjectContext } from "../types";
import { t, legacyFormat } from "tbme/localization";

export class ProfileUserContextError extends Error {}

const fieldMapping = {
  bio: "AboutMe",
  companyName: "CompanyName",
  companySize: "Company_Size__c",
  companyWebsite: "Company_Website__c",
  country: "Country",
  countryCode: "CountryCode",
  facebook: "FacebookProfileLink__c",
  givenName: "FirstName",
  id: "Id",
  isPublicProfile: "Is_Public_Profile__c",
  familyName: "LastName",
  linkedIn: "LinkedInProfileLink__c",
  avatarUrl: "MediumPhotoUrl",
  pronoun: "Pronoun__c",
  relationshipToSalesforce: "Relationship_To_Salesforce__c",
  role: "TBID_Role__c",
  slug: "TrailblazerId__c",
  state: "State",
  stateCode: "StateCode",
  title: "Title",
  twitter: "TwitterProfileLink__c",
  website: "WebsiteLink__c",
};

const translateFields = (profileUser: UserObjectContext) => {
  const result: any = {};

  for (const [target, source] of Object.entries(fieldMapping)) {
    const value = profileUser[source as keyof UserObjectContext];
    result[target] = value !== undefined ? value : null;
  }

  return result as LocalProfile;
};

/**
 * Update profileUser variable from identity object set by visualforce page.
 */
export const setProfileUserState = ({
  profileUser,
  profilePhotoUrl,
  profileUserBgImageUrl,
  isAvailableForHire,
  isCommunityUser,
  isExtProfileUser,
}: ProfileContext) => {
  if (!profileUser) {
    return;
  }

  const fullName = getFullName({ profileUser });

  if (!fullName || !profilePhotoUrl || !profileUserBgImageUrl) {
    return;
  }

  setProfileUser({
    ...translateFields(profileUser),
    backgroundImageUrl: profileUserBgImageUrl,
    avatarUrl: profilePhotoUrl,
    fullName,
    isAvailableForHire: !!isAvailableForHire,
    isCommunityUser: !!isCommunityUser,
    isExternalUser: !!isExtProfileUser,
  });
};

export const getFullName = ({ profileUser }: Partial<ProfileContext>) => {
  // Use both formatting schemes until all of the translations use the new form
  // TODO: removing legacy formatting after translations are updated
  return profileUser
    ? legacyFormat(
        t("fullName", {
          givenName: profileUser.FirstName,
          familyName: profileUser.LastName,
        }),
        profileUser.FirstName,
        profileUser.LastName
      )
    : "";
};

export const getProfileUserId = ({
  profileUser,
}: Partial<ProfileContext>): string => {
  return profileUser ? profileUser.Id : "";
};
