import { useApexMethod } from "tbme/legacyApexBridge";
import { fetchTbcUsers } from "./fetchUsers";
import { legacyCommunityConnections } from "./state";
import { sharedUrls } from "../../tbme/localState/urls";
import {
  CommunityConnectionCollection,
  LegacyCommunityConnections,
} from "./types";
import { currentUser as getCurrentUser } from "tbme/localState";
import { logger } from "tbme/logger";

interface ApexFollowArgs {
  contextProfileUserCommunityUserId: string;
  isMockProfile: boolean;
  uid: string;
}

interface ApexUnfollowArgs {
  existingEntitySubscriptionId: string;
  isMockProfile: boolean;
  uid: string;
}

interface Viewer {
  id: string;
  avatarUrl: string;
  fullName: string;
}

const apexFollow = useApexMethod<ApexFollowArgs, string>(
  "TrailblazerConnectionsController.followProfileUser"
);

const apexUnfollow = useApexMethod<ApexUnfollowArgs, boolean>(
  "TrailblazerConnectionsController.unFollowProfileUser"
);

export const follow = async (
  profileUserId: string,
  isExternalUser: boolean
): Promise<boolean> => {
  const { contextUser, currentUser } = await fetchTbcUsers(
    profileUserId,
    isExternalUser
  );

  if (!contextUser || !contextUser.ExternalId || !currentUser) {
    throw new Error("No community user found.");
  }

  try {
    const result = await apexFollow({
      uid: profileUserId,
      isMockProfile: isExternalUser,
      contextProfileUserCommunityUserId: contextUser.ExternalId,
    });

    if (result) {
      const user = getCurrentUser()!;
      const viewer = {
        id: currentUser.ExternalId,
        avatarUrl: user.avatarUrl,
        fullName: user.fullName,
      };
      legacyCommunityConnections(
        updateConnectionsOnFollow(
          legacyCommunityConnections() as LegacyCommunityConnections,
          viewer,
          result
        )
      );
    }

    return !!result;
  } catch (e) {
    logger.error(e);
    return false;
  }
};

export const unfollow = async (
  profileUserId: string,
  isExternalUser: boolean,
  viewerSubscriptionId: string
): Promise<boolean> => {
  if (!viewerSubscriptionId) {
    return false;
  }

  const { currentUser } = await fetchTbcUsers(profileUserId, isExternalUser);

  if (!currentUser) {
    throw new Error("No community user found.");
  }

  try {
    const result = await apexUnfollow({
      uid: profileUserId,
      isMockProfile: isExternalUser,
      existingEntitySubscriptionId: viewerSubscriptionId,
    });

    if (result) {
      const user = getCurrentUser()!;
      const viewer = {
        id: currentUser.ExternalId,
        avatarUrl: user.avatarUrl,
        fullName: user.fullName,
      };

      legacyCommunityConnections(
        updateConnectionsOnUnfollow(
          legacyCommunityConnections() as LegacyCommunityConnections,
          viewer
        )
      );
    }

    return result;
  } catch (error) {
    logger.error(error);
    return false;
  }
};

/**
 * Exported for tests
 */
export function updateConnectionsOnFollow(
  currentValue: LegacyCommunityConnections,
  viewer: Viewer,
  subscriptionId?: string
) {
  const result = fillFollowers(currentValue.followers);

  result.count++;

  if (result.connections!.length < 5) {
    const { id, avatarUrl, fullName } = viewer;
    result.connections = [
      {
        __typename: "CommunityConnection",
        id: id,
        name: `${fullName}`,
        url: sharedUrls.profile(),
        imageUrl: `${avatarUrl}`,
      },
      ...result.connections!,
    ];
  }

  return {
    ...currentValue,
    followers: result,
    viewerSubscriptionId: subscriptionId || null,
  };
}

export function updateConnectionsOnUnfollow(
  currentValue: LegacyCommunityConnections,
  viewer: Viewer
) {
  const result = fillFollowers(currentValue.followers);

  result.count = result.count > 0 ? result.count - 1 : 0;

  result.connections = result.connections!.filter(({ id }) => id !== viewer.id);

  return {
    ...currentValue,
    viewerSubscriptionId: null,
    followers: result,
  };
}

function fillFollowers(
  followers: CommunityConnectionCollection | null
): CommunityConnectionCollection {
  return {
    __typename: "CommunityConnectionCollection",
    count: 0,
    connections: [],
    url: sharedUrls.tbcUserConnections({ tab: "followers" }),
    ...filterNull(followers || {}),
  };
}

function filterNull(input: object) {
  const result: any = {};

  for (let [key, value] of Object.entries(input)) {
    if (
      input[key as keyof typeof input] !== null &&
      input[key as keyof typeof input] !== undefined
    ) {
      result[key] = value;
    }
  }

  return result;
}
