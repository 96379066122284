import { LightningElement, api } from "lwc";
import { sharedUrls } from "../../tbme/localState/urls";

export const mediaQueryMobile = window.matchMedia("(max-width: 760px)");

export type LayoutType = "Mobile" | "Desktop" | "AllSlots" | "Responsive";

export default class extends LightningElement {
  @api backgroundImageUrl: string = undefined!;
  @api layout: LayoutType = "AllSlots";

  private isMobile: boolean = mediaQueryMobile.matches;

  private get bannerStyle() {
    // The value is coming in as the string 'undefined' if not defined
    if (this.backgroundImageUrl && this.backgroundImageUrl !== "undefined") {
      return `background-image: url(${this.backgroundImageUrl})`;
    }
    return `background-image: url(${sharedUrls.bannerBackgroundImage()})`;
  }

  private get showMobile() {
    return (
      this.layout === "Mobile" ||
      (this.layout === "Responsive" && this.isMobile) ||
      this.layout === "AllSlots"
    );
  }

  private get showDesktop() {
    return (
      this.layout === "Desktop" ||
      (this.layout === "Responsive" && !this.isMobile) ||
      this.layout === "AllSlots"
    );
  }

  private onMediaQueryChange = (e: MediaQueryListEvent) => {
    this.isMobile = e.matches;
  };

  connectedCallback() {
    if (this.layout === "Responsive") {
      mediaQueryMobile.addEventListener("change", this.onMediaQueryChange);
    }
  }

  disconnectedCallback() {
    if (this.layout === "Responsive") {
      mediaQueryMobile.removeEventListener("change", this.onMediaQueryChange);
    }
  }
}
