import { gql } from "@apollo/client";

export const CONNECTIONS_QUERY = gql`
  query GetTbcCommunityConnections(
    $userSlug: ID!
    $count: Int = 5
    $queryConnections: Boolean!
  ) {
    profileData(userSlug: $userSlug) @include(if: $queryConnections) {
      communityConnections {
        followers(first: $count) {
          edges {
            node {
              id
              avatar
              url
              name
            }
          }
          totalCount
        }
        following(first: $count) {
          edges {
            node {
              id
              avatar
              url
              name
            }
          }
          totalCount
        }
        groups(first: $count) {
          edges {
            node {
              id
              avatar {
                large
              }
              url
              name
            }
          }
          totalCount
        }
      }
      communityUserUrls {
        followersUrl
        followingUrl
        groupsUrl
      }
    }
  }
`;

export const USER_CONTEXT_QUERY = gql`
  query GetUserConnectionsContext {
    chassis @client {
      profile {
        isPublicProfile
        username
      }
      isSelf
    }
    siteInfo @client {
      urls {
        tbcDefaultGroupImage
      }
    }
  }
`;
