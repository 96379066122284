import { gql } from "@apollo/client";

export const AVATAR_QUERY = gql`
  query GetAvatar {
    chassis @client {
      profile {
        firstName
        lastName
        username
        photoUrl
      }
      isSelf
    }

    siteInfo @client {
      urls {
        defaultAstroImage
      }
    }
  }
`;
