import { LightningElement, wire } from "lwc";
import { ApolloQueryResult } from "@apollo/client";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import {
  GetEarnedSkills,
  GetEarnedSkillsVariables,
} from "./gql/GetEarnedSkills";
import { EarnedSkill } from "./gql/EarnedSkill";
import {EARNED_SKILLS_QUERY} from "./query";
import { CardState } from "../../tbui/card/types";
import {chassis} from "tbme/localState";

// The number of skills to show when collapsed.
const initialVisible: number = 10;

export default class extends LightningElement {
  private isSelf: boolean = false;
  private skills: EarnedSkill[] = [];
  private displayAll: boolean = false;
  private cardState: CardState = "loading";
  private hasHeader: boolean = true;

  private variables: GetEarnedSkillsVariables = {
    slug: undefined,
    hasSlug: false
  };

  @wire(MultiLabelAdapter, {
    labels: [
      "skillsTitle",
      "skillsDescription",
      "skillsDescriptionThirdPerson",
      "showMore",
      "showLess",
    ],
  })
  private labels: LabelTranslations = {};
  
  connectedCallback() {
    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    this.isSelf = chassisData?.isSelf || false;
    this.variables = {
      ...this.variables,
      slug: username,
      hasSlug: !!username
    };
  }
  
  @wire(QueryAdapter, {
    query: EARNED_SKILLS_QUERY,
    variables: "$variables",
  })
  private handleResult(result: ApolloQueryResult<GetEarnedSkills>) {
    const { data, loading, error, errors } = result;

    if (loading) {
      this.cardState = "loading";
      return;
    }

    if (error || errors) {
      this.cardState = "loadFailed";
      return;
    }
    
    if (
      !data.profile ||
      data.profile.__typename !== "PublicProfile"
    ) {
      this.cardState = "loadFailed";
      return;
    }

    const { profile } = data;
    if (
      !profile.earnedSkills ||
        profile.earnedSkills.length < 1
    ) {
      this.cardState = "default";
      return;
    }

    this.skills = profile.earnedSkills;
    this.cardState = "default";
  }

  private get hasFooter(): boolean {
    return this.skills!.length > initialVisible;
  }

  private get subtitle(): string {
    return this.isSelf
      ? this.labels.skillsDescription
      : this.labels.skillsDescriptionThirdPerson;
  }

  private get shouldDisplayCard(): boolean {
    return !(this.cardState === "default" && this.skills.length < 1);
  }

  private get showMoreOrShowLess(): string {
    return this.displayAll ? this.labels.showLess : this.labels.showMore;
  }

  handleToggleShowMoreOrShowLess(): void {
    this.displayAll = !this.displayAll;
  }

  private mergeVariables(updates: Partial<GetEarnedSkillsVariables>) {
    return {
      ...this.variables,
      ...updates,
    };
  }
}
