import classNames from "classnames";
import { VariableDefinitionNode } from "graphql";
import { api, LightningElement, track } from "lwc";
import Button from "tds/button";
import { Variant, createVariantClassName } from "tds/button";

export type VariantConfig = {
  active: Variant;
  inactive: Variant;
};
export type ButtonState = {
  active: boolean;
  focused?: boolean;
  variant: Variant;
  config: VariantConfig;
};

const defaultVariant = "neutral";
const defaultState = {
  active: false,
  variant: defaultVariant,
  focused: false,
  config: {
    active: defaultVariant,
    inactive: defaultVariant,
  },
};

export default class extends Button {
  private state: ButtonState = undefined!;

  @api
  get active() {
    return this.state.active;
  }
  set active(value: boolean) {
    this.updateState({ active: attrToBool(value) });
  }

  @api
  get activeVariant() {
    return this.config.active;
  }
  set activeVariant(value) {
    this.updateConfig({ active: value });
  }

  @api
  get focused() {
    return this.state.focused;
  }
  set focused(value) {
    this.updateState({ focused: attrToBool(value) });
  }

  private get classes() {
    return classNames({
      "state-active": this.state.active && !this.state.focused,
      "state-active-focus": this.state.active && this.state.focused,
      "state-inactive": !this.state.active,
    });
  }

  private get config(): VariantConfig {
    if (!this.state || !this.state.config) {
      this.updateConfig({});
    }

    return this.state.config;
  }

  connectedCallback() {
    this.updateState({});
  }

  private updateConfig(updates: Partial<VariantConfig>) {
    this.updateState({
      config: {
        ...this.config,
        ...updates,
      },
    });
  }

  private updateState(updates: Partial<ButtonState>) {
    this.state = { ...defaultState, ...this.state, ...updates };

    this.updateVariant();
  }

  private updateVariant() {
    if (![this.config.active, this.config.inactive].includes(this.variant)) {
      this.updateConfig({
        inactive: this.variant,
      });
    }

    this.variant = this.state.active
      ? this.config.active
      : this.config.inactive;
  }

  private handleEvent(event: MouseEvent) {
    if (attrToBool(this.disabled)) return;

    switch (event.type) {
      case "mouseout": {
        this.updateState({
          focused: false,
        });
        break;
      }
      case "mouseover": {
        this.updateState({
          focused: true,
        });
        break;
      }
      case "focus": {
        this.updateState({
          focused: true,
        });
        break;
      }
      case "blur": {
        this.updateState({
          focused: false,
        });
        break;
      }
    }
  }
}

function attrToBool<T>(attr: T): boolean {
  if (typeof attr === "boolean") {
    return attr;
  }
  if (typeof attr === "string") {
    return attr === "true" || attr === "";
  }
  return !!attr;
}
