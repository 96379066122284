import { LightningElement, wire } from "lwc";

import {
  LabelTranslations,
  MultiLabelAdapter,
  t,
  TRANSLATION_MISSING_MESSAGE,
} from "tbme/localization";
import { CardState } from "elements/tbui/card/types";

import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import { USER_DETAILS_QUERY } from "./query";
import {
  GetUserDetails,
  GetUserDetails_chassis_profile,
} from "./gql/GetUserDetails";
import { verifyCountry, verifyState } from "@sfdc-www/digital-i18n";

export type UserDetails = Omit<GetUserDetails_chassis_profile, "__typename">;

export default class extends LightningElement {
  private isSelf: boolean = false;
  private user: UserDetails = undefined!;

  private cardState: CardState = "loading";
  private displayProfileEditModal: boolean = false;

  private labels: LabelTranslations = undefined!;

  handleEditDetails() {
    this.displayProfileEditModal = true;
  }

  handleProfileEditRequestClose() {
    this.displayProfileEditModal = false;
  }

  get displayField(): {
    pronoun: boolean;
    companyName: boolean;
    companyWebsite: boolean;
    state: boolean;
    role: boolean;
    companySize: boolean;
    country: boolean;
    relationshipToSalesforce: boolean;
  } {
    return {
      pronoun: !!(this.isSelf || this.pronoun),
      companyName: !!(this.isSelf || this.user.company?.name),
      companyWebsite: !!(this.isSelf || this.user.company?.website),
      state: !!(this.isSelf || this.state),
      role: !!(this.isSelf || this.role),
      companySize: !!(this.isSelf || this.companySize),
      country: !!(this.isSelf || this.country),
      relationshipToSalesforce: !!(
        this.isSelf || this.relationshipToSalesforce
      ),
    };
  }

  get role() {
    switch (this.user.role) {
      case "Developer":
        return t("profileEditModal.roleOptions.developer");
      case "Administrator":
        return t("profileEditModal.roleOptions.administrator");
      case "Architect":
        return t("profileEditModal.roleOptions.architect");
      case "Sales Representative / Manager":
        return t("profileEditModal.roleOptions.salesRep");
      case "Marketer":
        return t("profileEditModal.roleOptions.marketer");
      case "Service Agent / Supervisor":
        return t("profileEditModal.roleOptions.supervisor");
      case "Data Analyst":
        return t("profileEditModal.roleOptions.dataAnalyst");
      case "Consultant":
        return t("profileEditModal.roleOptions.consultant");
      case "Business Operations Manager":
        return t("profileEditModal.roleOptions.businessOperationsManager");
      case "Designer":
        return t("profileEditModal.roleOptions.designer");
      case "Educator":
        return t("profileEditModal.roleOptions.educator");
      case "Student":
        return t("profileEditModal.roleOptions.student");
      case "Executive":
        return t("profileEditModal.roleOptions.executive");
      case "Product Manager":
        return t("profileEditModal.roleOptions.productManager");
      default:
        return "";
    }
  }

  get pronoun() {
    switch (this.user.pronoun) {
      case "he/him/his":
        return t("profileEditModal.pronounOptions.heHimHis");
      case "other/ask me":
        return t("profileEditModal.pronounOptions.otherAskMe");
      case "she/her/hers":
        return t("profileEditModal.pronounOptions.sheHerHers");
      case "they/them/theirs":
        return t("profileEditModal.pronounOptions.theyThemTheirs");
      default:
        return "";
    }
  }

  get state() {
    const countryCode = this.user?.address?.country;
    const stateCode = this.user?.address?.state;

    return countryCode && stateCode ? verifyState(countryCode, stateCode) : "";
  }

  get country() {
    const countryCode = this.user?.address?.country;
    return countryCode ? verifyCountry(countryCode) : "";
  }

  get relationshipToSalesforce() {
    switch (this.user.relationshipToSalesforce) {
      case "Customer":
        return t("profileEditModal.relationshipOptions.customer");
      case "Partner":
        return t("profileEditModal.relationshipOptions.partner");
      case "Non-Customer / Prospect":
        return t("profileEditModal.relationshipOptions.nonCustomerProspect");
      case "Salesforce Employee":
        return t("profileEditModal.relationshipOptions.salesforceEmployee");
      default:
        return "";
    }
  }

  get companyName() {
    return this.user?.company?.name || "";
  }

  get companySize() {
    switch (this.user?.company?.size?.toLowerCase()) {
      case "1-20 employees":
        return t("profileEditModal.companySizeOptions.oneToTwenty");
      case "21-100 employees":
        return t("profileEditModal.companySizeOptions.twentyOneToOneHundred");
      case "101-500 employees":
        return t("profileEditModal.companySizeOptions.oneHundredToFiveHundred");
      case "501-3500 employees":
        return t(
          "profileEditModal.companySizeOptions.fiveHundredToThreeThousand"
        );
      case "3500+ employees":
      case "3501+ employees":
        return t("profileEditModal.companySizeOptions.threeThousandPlus");
      default:
        return "";
    }
  }

  get companyWebsite() {
    return this.user?.company?.website || "";
  }

  @wire(QueryAdapter, { query: USER_DETAILS_QUERY })
  private handleResult(result: QueryAdapterValue<GetUserDetails>) {
    const { data, loading, error, errors } = result;

    if (loading && !data?.chassis?.profile) {
      this.cardState = "loading";
      return;
    }

    if (error || errors) {
      this.cardState = "loadFailed";
      return;
    }

    if (!data?.chassis?.profile) {
      return;
    }

    this.cardState = "default";
    this.isSelf = data.chassis.isSelf;
    this.user = { ...data.chassis.profile } as UserDetails;
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "userDetails.edit",
      "userDetails.title",
      "userDetails.subtitle",
      "userDetails.fieldRole",
      "profileEditModal.fieldPronoun",
      "profileEditModal.fieldCompanyName",
      "profileEditModal.fieldCompanySize",
      "profileEditModal.fieldCompanyWebsite",
      "profileEditModal.fieldCountryCode",
      "profileEditModal.fieldStateCode",
      "profileEditModal.fieldRelationshipToSalesforce",
    ],
  })
  private handleLabels({
    userDetails,
    profileEditModal,
  }: {
    userDetails: LabelTranslations;
    profileEditModal: LabelTranslations;
  }) {
    this.labels = {
      ...profileEditModal,
      ...userDetails,
    };
  }
}
