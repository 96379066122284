import i18n from "i18n-js";
import * as defaultLocales from "./locales";
import { WireAdapter } from "lwc";
import { locale } from "tbme/localState";
import isEqual from "lodash/isEqual";
import set from "lodash/set";
import { subscribeToVar } from "../reactiveVarAdapter/reactiveVarAdapter";
import { setLocale } from "@devforce/trailhead-client-locale";

export type Locale = string;
export interface LabelAdapterConfig extends i18n.TranslateOptions {
  labelKey: string;
}

// The translate function, t, doesn't return an empty string
// if it can't translate the provided key. Instead, it returns
// a string indicating the translation is missing in the
// following format: [missing "$LANG.$MODULE.$KEY" translation]
export const TRANSLATION_MISSING_MESSAGE = "[missing";

export interface LegacyLabelAdaperConfig {
  labelKey: string;
  scope?: string | string[];
  [k: number]: any;
}

export interface MultiLabelConfigItem
  extends LabelAdapterConfig,
    LegacyLabelAdaperConfig {
  formattingType?: "standard" | "legacy";
  aliasKey?: string;
}

export interface MultiLabelAdapterConfig {
  labels: (MultiLabelConfigItem | string)[];
}

export type LabelDefinition = {
  value?: string;
  one?: string;
  other?: string;
  zero?: string;
  _description?: string;
};

export type LabelDefinitions = {
  [key: string]: { [k: string]: LabelDefinition } | LabelDefinition;
};

export type Locales = {
  [locale in Locale]?: LabelDefinitions;
};

export type LabelTranslations = {
  [key: string]: string;
};

export type Labels = {
  [key: string]: string | LabelTranslations;
};

type AdapterConstructor = {
  new (setValue: (value: string) => void): BaseAdapter<any, any>;
};

const adapters = new Set<BaseAdapter<any, any>>();

i18n.defaultLocale = "en";
i18n.fallbacks = true;

subscribeToVar(locale, (value: Locale) => {
  i18n.locale = value;
  setLocale(value);
  updateAdapters();
});

export const DEFAULT_SCOPE = "tbme";

export function setTranslations(
  locales: Locales,
  options?: { scope?: string; merge?: boolean }
) {
  const { scope, merge } = {
    scope: DEFAULT_SCOPE,
    merge: true,
    ...options,
  };
  for (const [locale, labels] of Object.entries(locales)) {
    // Modules can only export identifiers, so we transform the key
    // "esMX" => "es-MX"
    const localeKey = toLocaleKey(locale);

    if (!labels) {
      continue;
    }

    i18n.translations[localeKey] = {
      ...i18n.translations[localeKey],
      [scope]: {
        ...(merge ? (i18n.translations[localeKey] as any) || {} : {})[scope],
        ...collectValues(labels),
      },
    };
  }

  updateAdapters();
}

setTranslations(defaultLocales);

export function updateAdapters() {
  adapters.forEach((adapter) => adapter.updateValue());
}

export function toLocaleKey(value: string) {
  return value.replace(
    /([a-z]{2,3})(?:[_-])?([A-Z]{2})/g,
    (_, lang, country) => `${lang}-${country.toUpperCase()}`
  );
}

export function translate(
  key: string,
  options?: i18n.TranslateOptions
): string {
  return i18n.t(key, { scope: DEFAULT_SCOPE, ...options });
}

export const t = translate;

export function legacyFormat(label: string, ...args: any[]) {
  if (!label) {
    throw new Error("legacyFormat() - label is required");
  }

  if (args.length < 1) return label;
  return args.reduce(
    (sum, arg, i) => sum.replace(new RegExp(`\\{${i}\\}`, "g"), arg),
    label
  );
}

abstract class BaseAdapter<Config = any, Value = string>
  implements WireAdapter<Value, Config, void> {
  config?: Config;

  constructor(public setValue: (value: Value) => void) {}

  abstract updateValue(): void;

  update(config: Config) {
    this.config = config;
    this.updateValue();
  }
  connect() {
    adapters.add(this);
  }
  disconnect() {
    adapters.delete(this);
  }
}

export class LabelAdapter extends BaseAdapter<LabelAdapterConfig, string> {
  updateValue() {
    if (!this.config) {
      return;
    }
    this.setValue(translate(this.config.labelKey, this.config));
  }
}

export class LegacyLabelAdapter extends BaseAdapter<
  LegacyLabelAdaperConfig,
  string
> {
  updateValue() {
    if (!this.config) {
      return;
    }
    const scope = this.config.scope || DEFAULT_SCOPE;

    let args: any[] = [];
    for (const [key, value] of Object.entries(this.config)) {
      if (!isNaN(parseInt(key))) {
        args.push(value);
      }
    }

    this.setValue(legacyFormat(t(this.config.labelKey, { scope }), ...args));
  }
}

export class MultiLabelAdapter extends BaseAdapter<
  MultiLabelAdapterConfig,
  Labels
> {
  private childAdapters = new Set<BaseAdapter<any, any>>();
  private translations: Labels = {};
  private previousTranslations?: Labels;

  setChildValue(key: string, value: string) {
    if (this.translations[key] && this.translations[key] === value) {
      return;
    }
    this.translations = {
      ...this.translations,
      [key]: value,
    };
    this.updateValue();
  }

  updateValue() {
    if (!this.config) {
      return;
    }

    if (!this.config.labels) {
      return;
    }

    if (
      this.translations !== this.previousTranslations &&
      this.childAdapters.size &&
      this.childAdapters.size === this.config.labels.length &&
      this.config.labels.length === Object.keys(this.translations).length
    ) {
      this.setValue(this.unpackTranslations(this.translations));
      this.previousTranslations = this.translations;
    }
  }

  update(config: MultiLabelAdapterConfig) {
    if (!this.config || !isEqual(this.config, config)) {
      const { labels } = config;
      let assignedKeys: string[] = [];

      this.translations = {};
      this.disconnectChildren();
      this.config = config;

      for (let item of labels) {
        let adapterType: AdapterConstructor = LabelAdapter;
        let childConfig: MultiLabelConfigItem;
        let valueKey: string;

        if (typeof item === "string") {
          valueKey = item;
          childConfig = {
            labelKey: item,
          };
        } else {
          if (item.formattingType === "legacy") {
            adapterType = LegacyLabelAdapter;
          }
          valueKey = item.aliasKey || item.labelKey;
          childConfig = item;
        }

        if (assignedKeys.includes(valueKey)) {
          throw `Invalid duplicate labelKey or aliasKey found: ${valueKey}. All keys on the labels object must be unique.`;
        }

        assignedKeys.push(valueKey);

        const adapter: BaseAdapter<any, any> = new adapterType(
          this.setChildValue.bind(this, valueKey)
        );

        this.childAdapters.add(adapter);
        adapter.connect();
        adapter.update(childConfig);
      }
    }
  }

  disconnect() {
    this.disconnectChildren();
    adapters.delete(this);
  }

  disconnectChildren() {
    this.childAdapters.forEach((adapter) => adapter.disconnect());
  }

  private unpackTranslations(labels: Labels, result: Labels = {}) {
    for (let [key, value] of Object.entries(labels)) {
      set(result, key, value);
    }

    return result;
  }
}

function collectValues(labels: LabelDefinitions, result: Labels = {}): Labels {
  const omitKeys = ["_description"];
  for (const [key, label] of Object.entries(labels)) {
    if (typeof label === "string") {
      // Skip _description
      if (!omitKeys.includes(key)) {
        result[key] = label;
      }
    } else if (label.hasOwnProperty("value") && label) {
      result[key] = (label as LabelDefinition).value || "";
    } else {
      result[key] = {
        ...collectValues(label as LabelDefinitions),
      } as LabelTranslations;
    }
  }

  return result;
}
