import { LightningElement, api, wire } from "lwc";
import classNames from "classnames";
import { MultiLabelAdapter, LabelTranslations, t } from "tbme/localization";
import { locale } from "tbme/localState";

/**
 * Represents an earned Badge from Trailhead
 * Always shows an image and the title
 * When hovered or focused, it shows a gray border with a details button
 * When the details button is clicked, more details are shown
 */
export default class Badge extends LightningElement {
  /** The date the badge was awarded */
  private _awardedOn: string | null = undefined!;

  /** The number of points awarded for the badge */
  @api pointsAwarded: number | null = undefined!;

  /** The link to the badge on trailhead */
  @api learningUrl: string = undefined!;

  /** The title of the badge */
  @api title: string = undefined!;

  /** The image url for the badge */
  @api imageUrl: string = undefined!;

  /** If the profileUser === the currentUser */
  @api isSelf = false;

  /** If the award has been archived */
  @api isArchived = false;

  /** The locale-formatted date awarded */
  @api
  get awardedOn() {
    if (this._awardedOn === null) return null;

    const date = new Date(this._awardedOn);
    return date.toLocaleDateString(locale());
  }
  set awardedOn(value: string | null) {
    this._awardedOn = value;
  }

  /** The locale-formatted points awarded */
  private get pointsAwardedString() {
    if (this.pointsAwarded === null) return "";

    return this.pointsAwarded.toLocaleString(locale());
  }

  /** Is the badge being hovered? */
  private isHovering = false;

  /** Is the badge being focused in? */
  private isFocused = false;

  /** Did the user open the details menu? */
  private isDetailsOpen = false;

  /** The static labels in the component */
  @wire(MultiLabelAdapter, {
    labels: [
      "trailheadBadgeDateEarned",
      "trailheadBadgePoints",
      "trailheadBadgeDetails",
    ],
  })
  private labels: LabelTranslations = {};

  /** Is the component being hovered or focused? */
  private get isHighlighted() {
    return this.isHovering || this.isFocused || this.isDetailsOpen;
  }

  /** The container div class */
  private get containerClass() {
    return classNames("badge-container", {
      "badge-container_border": this.isHighlighted,
    });
  }

  /** The class for the button that toggles the details menu */
  private get detailsTriggerClass() {
    return classNames("details__trigger", {
      details__trigger_visible: this.isHighlighted,
    });
  }

  /** Tells the component we're hovering */
  private handleMouseEnter() {
    this.isHovering = true;
  }

  /** Tells the component we're not hovering */
  private handleMouseLeave() {
    this.isHovering = false;
  }

  /** Tells the component we're focusing */
  private handleFocusIn() {
    this.isFocused = true;
  }

  /** Tells the component we're no longer focused in */
  private handleFocusOut() {
    this.isFocused = false;
  }

  /** Toggles the details menu visibility */
  private handleToggleDetails(e: Event) {
    this.isDetailsOpen = !this.isDetailsOpen;
  }

  /** Closes the details menu, used when clicks occur outside of the popover */
  private handleCloseDetails() {
    this.isDetailsOpen = false;
    this.template
      .querySelector(".details__trigger")!
      .shadowRoot!.querySelector("button")!
      .focus();
  }
}
