import { api, LightningElement, wire } from "lwc";
import Croppie, { ResultOptions, Type } from "croppie";
//import exif from "exif-js";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";

export default class extends LightningElement {
  @api uid: string = "";
  @api photoUrl: string = "";
  @api photoTitle: string = "";
  hasCustomPhoto: boolean = false;
  photoHasChanged: boolean = false;
  photoIsSaving: boolean = false;
  acceptedFileTypes: string = "image/jpg, image/jpeg, image/gif, image/png";
  minimumFileSize = 10240; // 10 kb
  maximumFileSize = 15728640; // 15 mb
  contentType: string = "";
  croppieObj!: Croppie | null;
  uploadButtonLabel: string = "";
  displayUploadPhotoModal: boolean = false;
  openRemovePhotoConfirmation: boolean = false;
  croppedImageBlob: Blob | undefined;
  errorText = "";

  private labels: LabelTranslations = undefined!;

  get isChangedCustomPhoto() {
    return this.hasCustomPhoto && !this.photoHasChanged;
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "avatarUpload.uploadTitle",
      "avatarUpload.fileUploadText",
      "avatarUpload.removePhoto",
      "avatarUpload.saveButton",
      "avatarUpload.cancelButton",
      "avatarUpload.removePictureTitle",
      "avatarUpload.removeButton",
      "avatarUpload.editPhotoAssistiveText",
      "avatarUpload.sizeTooBigError",
      "avatarUpload.sizeTooSmallError",
      "fileUploader.buttonText",
      "fileUploader.additionalText",
    ],
  })
  private handleLabels(labels: {
    avatarUpload: LabelTranslations;
    fileUploader: LabelTranslations;
  }) {
    this.labels = {
      ...labels.avatarUpload,
      ...labels.fileUploader,
    };
  }

  private handleHideUploadPhotoModal() {
    this.displayUploadPhotoModal = false;
  }

  private handleDisplayUploadPhotoModal() {
    this.displayUploadPhotoModal = true;
    this.photoHasChanged = false;
    this.photoIsSaving = false;
    // The URL for the default Astro image will be along the lines of
    // "{domain}/resource/1565801268000/astro".  If the URL contains
    // 'resource', that means the photo is the default Astro image.
    this.hasCustomPhoto =
      (this.photoUrl && this.photoUrl.indexOf("/resource/")) === -1;
  }

  private handleFileChanged(event: CustomEvent) {
    const uploadedFiles = event.detail.files;
    if (!uploadedFiles || uploadedFiles.length < 1) {
      return;
    }
    const uploadedFile = uploadedFiles[0];
    const fileName = uploadedFile.name.toLowerCase();
    const fileNameExtension = fileName.split(".").pop();
    const fileSize = uploadedFile.size;
    if (this.acceptedFileTypes.indexOf(fileNameExtension!) === -1) {
      return;
    } else if (fileSize > this.maximumFileSize) {
      this.errorText = this.labels.sizeTooBigError;
      return;
    } else if (fileSize < this.minimumFileSize) {
      this.errorText = this.labels.sizeTooSmallError;
      return;
    }
    this.errorText = "";
    let currentPic = this.template.querySelector(
      '[data-id="current-pic"]'
    ) as HTMLElement;
    currentPic.classList.add("hide");

    let uploadedPic = this.template.querySelector(
      '[data-id="uploaded-pic"]'
    ) as HTMLElement;
    uploadedPic.classList.remove("hide");

    let reader = new FileReader();
    reader.onload = (evnt) => {
      this.createCroppieObj();
      this.bindCroppie(evnt.target?.result);
      this.photoHasChanged = true;
      this.hasCustomPhoto = true;
    };

    reader.readAsDataURL(uploadedFile);
    this.contentType = uploadedFile.type;
  }

  private createCroppieObj() {
    if (this.croppieObj) {
      this.croppieObj.destroy();
      this.croppieObj = null;
    }

    const element = this.template.querySelector(
      '[data-id="pic-container"]'
    ) as HTMLElement;
    if (element) {
      this.croppieObj = new Croppie(element, {
        viewport: {
          width: 160,
          height: 160,
          type: "circle",
        },
      });
    }
  }

  private bindCroppie(photo: any) {
    this.croppieObj?.setZoom(0.5);
    this.croppieObj?.bind({
      url: photo,
    });
    this.hasCustomPhoto = true;
  }

  private handleRemovePhotoModal() {
    this.openRemovePhotoConfirmation = !this.openRemovePhotoConfirmation;
  }

  private handleRemoveButtonClick() {
    this.handleRemovePhotoModal();
    this.dispatchEvent(
      new Event("remove", {
        bubbles: true,
      })
    );
  }

  private handleSave() {
    this.photoIsSaving = true;
    if (this.contentType) {
      this.croppieObj!.result({
        type: "blob",
        size: "original",
      } as ResultOptions)
        // Uses 'any' since the types currently don't work for string results
        .then((croppedImageBlob: any) => {
          this.dispatchEvent(
            new CustomEvent("upload", {
              bubbles: true,
              detail: URL.createObjectURL(croppedImageBlob as Blob),
            })
          );
          this.displayUploadPhotoModal = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.photoIsSaving = false;
        });
    }
  }

  get isSaveButtonDisabled() {
    return !this.photoHasChanged || this.photoIsSaving;
  }

  get uploadPhotoModalClass() {
    return this.openRemovePhotoConfirmation ? "hidden" : "";
  }
}
