import { gql } from "@apollo/client";

export const USER_ACTIONS_QUERY = gql`
  query UserActionsQuery {
    chassis @client {
      isSelf
      profile {
        username
        firstName
        lastName
      }
    }
  }
`;

export const TBC_USER_ACTIONS_QUERY = gql`
  query GetInboxUrl($userSlug: ID!, $queryUserActions: Boolean!) {
    profileData(userSlug: $userSlug) @include(if: $queryUserActions) {
      communityUserUrls {
        inboxUrl
      }
    }
  }
`;
