import { LightningElement, wire, api } from "lwc";
import { ApolloQueryResult } from "@apollo/client";
import classNames from "classnames";
import {
  MultiLabelAdapter,
  LabelTranslations,
  t,
  legacyFormat,
} from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import {
  GetTrailheadRank,
  GetTrailheadRankVariables,
} from "./gql/GetTrailheadRank";
import { PublicProfile_trailheadStats } from "./gql/PublicProfile";
import { TrailheadRank } from "./gql/TrailheadRank";
import { TRAILHEAD_RANKS_QUERY } from "./query";
import { chassis } from "tbme/localState";
import { getSharedUrlValues } from "../localState/urls";

type cardStates = "default" | "loading" | "loadFailed";

export default class extends LightningElement {
  private isSelf: boolean = undefined!;
  private trailheadAppUrl: string = undefined!;
  private progressRingMaxValue: number = 100;
  private variables: GetTrailheadRankVariables = {
    hasSlug: false,
  };

  connectedCallback() {
    const urls = getSharedUrlValues() as any;
    this.trailheadAppUrl = urls.trailhead;
    this.ranksPagePath = urls.trailheadRanks;

    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    this.isSelf = chassisData?.isSelf || false;

    this.variables = {
      ...this.variables,
      slug: username,
      hasSlug: !!username,
    };
  }

  @wire(QueryAdapter, {
    query: TRAILHEAD_RANKS_QUERY,
    variables: "$variables",
  })
  handleResult(result: ApolloQueryResult<GetTrailheadRank>) {
    const { data, loading, error, errors } = result;

    if (loading) {
      this.cardState = "loading";
      return;
    }

    if (error || errors) {
      this.cardState = "loadFailed";
      return;
    }

    // Once is done loading (loading == false), all the client side fields are not accesible (a side effect of using GraphQL aliases in the query?)
    // All client side fields should be inspected above this line.

    if (
      !data.profile ||
      data.profile.__typename !== "PublicProfile" ||
      !data.profile.trailheadStats
    ) {
      this.cardState = "loadFailed";
      return;
    }
    this.updateRank(data.profile.trailheadStats);
  }

  private cardState: cardStates = "loading";
  private rank: TrailheadRank | null = null;
  private nextRank: TrailheadRank | null = null;
  private earnedBadgeTotal = 0;
  private badgeTotalForNextRank = 0;
  private earnedPointTotal = 0;
  private pointTotalForNextRank = 0;
  private completedTrailTotal: number | null = null;
  private ranksPagePath: string = undefined!;

  @wire(MultiLabelAdapter, {
    labels: [
      "rankTitle",
      "failedToUpdate",
      "rankGoToTrailhead",
      "startLearning",
      "startLearningDesc",
      "rankBadges",
      "rankPoints",
      "rankTrails",
      "rankNextImageAlt",
      "nextLevelPointsAndBadges",
      "nextLevelBadges",
      "nextLevelPoints",
    ],
  })
  private labels: LabelTranslations = {};

  private get showOnboardingMessage() {
    return this.hasNoActivity && this.isSelf;
  }
  private get showNextRank() {
    return this.cardState !== "loading" && this.isSelf && this.nextRank;
  }

  private get rankImageClass() {
    return classNames("rank-image", {
      "rank-image_grayscale": this.hasNoActivity,
    });
  }
  private get tallyVariant() {
    return this.hasNoActivity ? null : "success";
  }
  private get hasNoActivity() {
    return this.cardState !== "loading" && this.earnedPointTotal === 0;
  }

  private get ringEarnedPointTotal() {
    return Math.min(
      this.earnedPointTotal,
      this.nextRank ? this.nextRank.requiredPointsSum : this.earnedPointTotal
    );
  }
  private get progressRingCurrValue() {
    const { nextRank, earnedBadgeTotal, earnedPointTotal } = this;
    if (!nextRank) {
      return undefined;
    }
    const percentPoints =
      (Math.min(earnedPointTotal, nextRank.requiredPointsSum) /
        nextRank.requiredPointsSum) *
      100;
    const percentBadges =
      (Math.min(earnedBadgeTotal, nextRank.requiredBadgesCount) /
        nextRank.requiredBadgesCount) *
      100;
    return (percentPoints + percentBadges) / 2;
  }
  private get nextRankImageAlt() {
    if (!this.nextRank) return null;
    const { nextRank } = this;
    return legacyFormat(t("rankNextImageAlt"), nextRank.title);
  }
  private get nextRankText() {
    if (!this.nextRank || this.cardState === "loading") {
      return null;
    }
    const { badgeTotalForNextRank, pointTotalForNextRank, nextRank } = this;
    let message;
    if (badgeTotalForNextRank > 0 && pointTotalForNextRank > 0) {
      message = legacyFormat(
        this.labels.nextLevelPointsAndBadges,
        badgeTotalForNextRank.toLocaleString(),
        pointTotalForNextRank.toLocaleString(),
        this.ranksPagePath,
        nextRank.title
      );
    } else {
      if (badgeTotalForNextRank > 0) {
        message = legacyFormat(
          this.labels.nextLevelBadges,
          badgeTotalForNextRank.toLocaleString(),
          this.ranksPagePath,
          nextRank.title
        );
      } else {
        message = legacyFormat(
          this.labels.nextLevelPoints,
          pointTotalForNextRank.toLocaleString(),
          this.ranksPagePath,
          nextRank.title
        );
      }
    }
    return message;
  }

  updateRank = ({
    rank,
    earnedPointsSum,
    earnedBadgesCount,
    completedTrailCount,
    nextRank,
  }: PublicProfile_trailheadStats) => {
    this.cardState = "default";
    this.rank = rank;
    this.nextRank = nextRank;

    this.earnedBadgeTotal = earnedBadgesCount || 0;
    this.badgeTotalForNextRank = nextRank
      ? nextRank.requiredBadgesCount - (earnedBadgesCount || 0)
      : 0;

    this.earnedPointTotal = earnedPointsSum || 0;
    this.completedTrailTotal = completedTrailCount || 0;
    this.pointTotalForNextRank = nextRank
      ? nextRank.requiredPointsSum - (earnedPointsSum || 0)
      : 0;
  };
}
