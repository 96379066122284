import { LightningElement, api, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { PresentationSkill } from "./types";
import { EarnedSkill } from "../../tbme/skills/gql/EarnedSkill";

// The amount of skills to show when collapsed.
const initialVisible: number = 10;

// The colors to loop through for the skills graph.
// Taken from https://git.soma.salesforce.com/pages/mgeyer/chart-colors/#/themes
// The same thing is done for mobile: https://git.soma.salesforce.com/TrailheadMobile/iOS/blob/master/Trailhead/Classes/Views/Profile/ProfileSkillsCardView.swift#L193
const colors: string[] = [
  "#3296ED",
  "#77B9F2",
  "#9D53F2",
  "#C398F5",
  "#26ABA4",
  "#4ED4CD",
  "#F7A452",
  "#FACA9B",
  "#F2536D",
  "#F598A7",
  "#96D44E",
  "#C7F296",
  "#537BF2",
  "#7796F2",
  "#F253D2",
  "#F598E2",
  "#EDBE32",
  "#F5DE98",
  "#4ED469",
  "#7CEB92",
];

export default class SkillsChart extends LightningElement {
  @wire(MultiLabelAdapter, {
    labels: [
      "skillsDescription",
      "skillsDescriptionThirdPerson",
      "showMore",
      "showLess",
    ],
  })
  private labels: LabelTranslations = {};

  @api skills: EarnedSkill[] = [];
  @api displayAll: boolean = false;

  private ringWidth: number = 300;
  private strokeWidth: number = 30;

  // hoveredSkillId and selectedSkillId are dynamically set/unset, so
  // define as "string | null" and initialize to null.
  private hoveredSkillId: string | null = null;
  private selectedSkillId: string | null = null;

  private get presentationSkills(): PresentationSkill[] {
    const presentationSkills = this.skills.map((skill: any, index: number) => ({
      ...skill,
      color: colors[index % colors.length],
      isHovered: this.hoveredSkillId === skill.id,
      isSelected: this.selectedSkillId === skill.id,
    }));

    return presentationSkills;
  }

  private get visiblePresentationSkills(): PresentationSkill[] {
    const visibleSkills: PresentationSkill[] = this.displayAll
      ? this.presentationSkills
      : this.presentationSkills.slice(0, initialVisible);

    return visibleSkills;
  }

  handleSkillItemMouseEnter(event: any): void {
    this.hoveredSkillId = event.detail.id;
  }

  handleSkillItemMouseLeave(): void {
    this.hoveredSkillId = null;
  }

  handleSkillItemFocus(event: any): void {
    this.selectedSkillId = event.detail.id;
  }

  handleSkillItemBlur(): void {
    this.selectedSkillId = null;
  }

  handleSkillRingSliceEnter(event: any): void {
    this.hoveredSkillId = event.detail.id;
  }

  handleSkillRingSliceLeave(): void {
    this.hoveredSkillId = null;
  }
}
