import { ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache";
import { createContextAdapter } from "common/context";
import localSchema from "../../../gql/localSchema";
import { locale, isRenderProfileChassis } from "tbme/localState";
import { fetch } from "@devforce/trailhead-client-fetch";

export { createCache, cache } from "./cache";

type Nullable<T> = T | null;

export interface ClientAdapterValue {
  client: ApolloClient<any>;
}
export interface ClientAdapterConfig {
  client?: Nullable<ApolloClient<any>>;
}
export interface ClientAdapterContext {
  client?: Nullable<ApolloClient<any>>;
}

export const adapterContextSchema = {
  client: "optional",
};

export interface ClientConfig {
  client?: ApolloClient<any>;
  accessToken: string;
  accessTokenType: "Bearer" | "Visualforce";
  uri: string;
  fetch: typeof fetch;
}

export const profileApiBffUrl: string =
  window?.sfdcBase?.exp?.PROFILE_API_BFF_URL || "";

export const getDefaultConfig = (): ClientConfig => ({
  accessToken: "",
  accessTokenType: "Visualforce",
  uri: profileApiBffUrl || (process.env.PROFILE_API_BFF_URL as string) || "",
  fetch,
});

let config = {
  ...getDefaultConfig(),
  client: createClient(getDefaultConfig()),
};

export const ClientAdapter = createContextAdapter<
  ClientAdapterValue,
  ClientAdapterConfig,
  ClientAdapterContext
>(() => ({ client: config.client }), adapterContextSchema);

export function setGlobalClient(client: ApolloClient<any>) {
  config.client = client;

  ClientAdapter.setGlobalContext({ client });
}

export function setAccessToken(accessToken: string) {
  config.accessToken = accessToken;

  setGlobalClient(createClient(config));
}

export function setAccessTokenType(accessTokenType: "Bearer" | "Visualforce") {
  config.accessTokenType = accessTokenType;

  setGlobalClient(createClient(config));
}

export function setUri(uri: string) {
  config.uri = uri;

  setGlobalClient(createClient(config));
}

export function getClient() {
  return config.client;
}

export function getConfig() {
  return config;
}

export function createClient(config: ClientConfig) {
  const { uri, accessToken, accessTokenType, fetch } = config;

  const httpLink = createHttpLink({
    uri,
    fetch,
  });

  const headersLink = setContext((_, { headers }) => {
    // FIXME: The X-Idp token should be included only if the entry point is renderProfileChassis()
    return {
      headers: {
        ...headers,
        "accept-language": locale(),
        ...(isRenderProfileChassis()
          ? {
              "x-idp": "iis",
            }
          : {}),
        ...(accessToken
          ? {
              authorization: `${accessTokenType} ${accessToken}`,
            }
          : {}),
      },
    };
  });

  return new ApolloClient({
    link: headersLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        returnPartialData: true,
      },
    },
    cache,
    typeDefs: localSchema,
  });
}
