import { gql } from "@apollo/client";

export const FOLLOW_USER_MUTATION = gql`
  mutation followUser($userId: ID!) {
    followUser(input: { userId: $userId }) {
      ... on FollowUserResult {
        user {
          id
          following
        }
      }
      ... on Error {
        code
        message
        statusCode
        argumentName
        clientMutationId
      }
    }
  }
`;

export const UNFOLLOW_USER_MUTATION = gql`
  mutation unfollowUser($userId: ID!) {
    unfollowUser(input: { userId: $userId }) {
      ... on UnfollowUserResult {
        user {
          id
          following
        }
      }
      ... on Error {
        code
        message
        statusCode
        argumentName
        clientMutationId
      }
    }
  }
`;
