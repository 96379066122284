import { LightningElement, api, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { t } from "tbme/localization";
import { CardState } from "../../tbui/card/types";
import { ApolloQueryResult } from "@apollo/client";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import {
  GetTrailheadBadges,
  GetTrailheadBadgesVariables,
  GetTrailheadBadges_profile_PublicProfile_earnedAwards_edges,
} from "../profileBadges/gql/GetTrailheadBadges";
import { EarnedAward } from "../profileBadges/gql/EarnedAward";
import { EarnedAwardSelf } from "../profileBadges/gql/EarnedAwardSelf";
import { TRAILHEAD_BADGES_QUERY } from "../profileBadges/query";
import { AwardTypeFilter } from "../../../gql/types";
import { PageInfoBidirectional } from "gql/fragments/gql/PageInfoBidirectional";
import { chassis } from "tbme/localState";
import { getSharedUrlValues } from "../localState/urls";

interface Superbadge {
  id: string;
  title: string;
  url: string | null;
  imageUrl: string;
  earnedAt: string | null;
}

// The number of superbadges to show when collapsed.
const initialVisible: number = 3;
const initialCount: number = 8;
const perPage = 8;
export default class extends LightningElement {
  private isSelf: boolean = false;
  private cardState: CardState = "loading";
  private hasHeader: boolean = true;
  private superbadges = Array<Superbadge>();
  private fetchingMore = false;
  private viewingLess: boolean = true;
  private superbadgeCount: number | null = 0;

  private fetchMore?: (options: {
    variables: GetTrailheadBadgesVariables;
  }) => Promise<ApolloQueryResult<GetTrailheadBadges>>;

  private refetch?: (
    variables?: Partial<GetTrailheadBadgesVariables>
  ) => Promise<ApolloQueryResult<GetTrailheadBadges>>;

  private pageInfo: PageInfoBidirectional = {
    hasNextPage: false,
    endCursor: null,
  } as PageInfoBidirectional;

  private variables: GetTrailheadBadgesVariables = {
    count: initialCount,
    after: null,
    filter: AwardTypeFilter.SUPERBADGE,
    hasSlug: false,
  };

  private defaultImageUrls: { trailheadUnknownBadge: string } = undefined!;
  private shouldFocusNewSuperbadge: boolean = false;
  private previousSuperbadgeCount: number = initialCount;

  connectedCallback() {
    this.defaultImageUrls = getSharedUrlValues() as any;

    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    this.isSelf = chassisData?.isSelf || false;

    this.variables = {
      ...this.variables,
      slug: username,
      hasSlug: !!username,
    };
  }

  renderedCallback() {
    if (this.shouldFocusNewSuperbadge) {
      this.focusNewSuperbadge();
    }
  }

  focusNewSuperbadge() {
    const badge = this.filteredSuperbadges[this.previousSuperbadgeCount];

    if (badge) {
      const superBadgeComponent = this.template.querySelectorAll(
        "lwc-tbui-superbadge-item"
      )[this.previousSuperbadgeCount];
      const link = superBadgeComponent?.shadowRoot?.querySelector(
        `a[href='${badge.url}']`
      ) as HTMLAnchorElement;

      if (link) {
        requestAnimationFrame(() => {
          link.focus();
        });
        this.shouldFocusNewSuperbadge = false;
      }
    }
  }

  @wire(MultiLabelAdapter, {
    labels: ["showMore", "showLess"],
  })
  private labels: LabelTranslations = {};

  @wire(QueryAdapter, {
    query: TRAILHEAD_BADGES_QUERY,
    variables: "$variables",
  })
  private handleResult(
    result: QueryAdapterValue<GetTrailheadBadges, GetTrailheadBadgesVariables>
  ) {
    const { data, loading, error, errors, fetchMore, refetch } = result;

    this.fetchMore = fetchMore;
    this.refetch = refetch;

    if (loading) {
      this.cardState = "loading";
      return;
    }

    if (error || errors) {
      this.cardState = "loadFailed";
      console.log(error + " " + errors);
      return;
    }

    let profile = data.profile;
    if (!profile) {
      return;
    }

    if (
      profile.__typename !== "PublicProfile" ||
      !profile.earnedAwards ||
      !profile.trailheadStats
    ) {
      this.cardState = "default";
      return;
    }

    const { earnedAwards, trailheadStats } = profile;
    const { edges, pageInfo } = earnedAwards;
    this.superbadges = this.transformBadges(edges);
    this.superbadgeCount = trailheadStats.superbadgeCount;
    this.cardState = "default";
    this.pageInfo = pageInfo;
  }

  private mergeVariables(updates: Partial<GetTrailheadBadgesVariables>) {
    return {
      ...this.variables,
      ...updates,
    };
  }

  private transformBadges(
    edges:
      | (GetTrailheadBadges_profile_PublicProfile_earnedAwards_edges | null)[]
      | null
  ): Superbadge[] {
    if (!edges || edges.length < 1) return [];

    return (edges
      .filter((item) => item !== null)
      .map((item) => item && item.node) as (
      | EarnedAward
      | EarnedAwardSelf
    )[]).map<Superbadge>((earnedAward) => {
      const { __typename, id, award } = earnedAward;

      return {
        id,
        title: award ? award.title : this.labels.unknownBadgeTitle,
        description: award && award.content ? award.content.description : "",
        url: award && award.content ? award.content.webUrl : "",
        imageUrl: award
          ? award.icon
          : this.defaultImageUrls.trailheadUnknownBadge,
        earnedAt:
          __typename === "EarnedAwardSelf"
            ? (earnedAward as EarnedAwardSelf).earnedAt
            : null,
      };
    });
  }

  private get shouldDisplayCard(): boolean {
    if (!this.isSelf && this.superbadges.length < 1) {
      return false;
    }

    return true;
  }

  private get filteredSuperbadges(): Array<Superbadge> {
    return this.viewingLess
      ? this.superbadges.slice(0, initialVisible)
      : this.superbadges;
  }

  private get formattedSuperbadgesCount(): string {
    const count =
      !this.superbadges || this.superbadges.length < 1
        ? 0
        : this.superbadgeCount;

    return t("superbadges.title", { count });
  }

  private get superbadgesAreAvailable(): boolean {
    return this.superbadges && this.superbadges.length > 0;
  }

  private get hasFooter(): boolean {
    return this.superbadges!.length > initialVisible;
  }

  private async handleLoadMore() {
    if (!this.fetchMore) return;

    this.fetchingMore = true;
    this.previousSuperbadgeCount = this.filteredSuperbadges.length;

    await this.fetchMore({
      variables: this.mergeVariables({
        after: this.pageInfo.endCursor,
        count: perPage,
      }),
    });
    this.viewingLess = false;
    this.fetchingMore = false;
    this.shouldFocusNewSuperbadge = true;
  }

  private async handleToggleViewLess() {
    if (!this.refetch) return;

    this.fetchingMore = true;
    await this.refetch(
      this.mergeVariables({
        after: null,
        count: initialCount,
      })
    );
    this.viewingLess = true;
    this.fetchingMore = false;
  }

  get hasManyPages() {
    return (
      this.superbadges.length > initialVisible ||
      (this.pageInfo && this.pageInfo.hasNextPage)
    );
  }

  get showViewLess() {
    return (
      this.hasManyPages &&
      this.pageInfo &&
      !this.pageInfo.hasNextPage &&
      !this.viewingLess
    );
  }

  get showViewMore() {
    return (
      (this.pageInfo && this.pageInfo.hasNextPage) ||
      (this.filteredSuperbadges.length === initialVisible &&
        this.superbadges.length > initialVisible)
    );
  }
}
