import { LightningElement, api, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations, t } from "tbme/localization";
import classNames from "classnames";

export default class extends LightningElement {
  @api progress!: number;
  @api imageUrl!: string;
  @api assistiveText!: string;

  isLong(percentage: number) {
    return percentage > 0.5 ? 1 : 0;
  }

  arcX(percentage: number) {
    return Math.cos(2 * Math.PI * percentage);
  }

  arcY(percentage: number) {
    return 0 - Math.sin(2 * Math.PI * percentage);
  }

  get svgRingPath() {
    const percentage = this.progress / 100;
    return `M 1 0 A 1 1 0 ${this.isLong(percentage)} 0 ${this.arcX(
      percentage
    )} ${this.arcY(percentage)} L 0 0`;
  }
}
