import { baseUrl } from "tbme/localState";
import { ProfileContext } from "../types";

export const setLegacyBaseUrlState = ({ communityUrl }: ProfileContext) => {
  if (!communityUrl) return;

  baseUrl(communityUrl);
};

export const setChassisBaseUrlState = () => {
  if (window?.sfdcBase?.exp?.DIGITAL_PROFILE_BASE_URL) {
    baseUrl(window?.sfdcBase?.exp?.DIGITAL_PROFILE_BASE_URL);
  }
};
