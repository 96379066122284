import { legacyFormat, t } from "tbme/localization";
import { LightningElement, wire } from "lwc";
import {
  LabelTranslations,
  MultiLabelAdapter,
  MultiLabelAdapterConfig,
} from "tbme/localization";
import dotMeSrc from "./images/dotme.svg";
import { ApolloQueryResult, gql } from "@apollo/client";
import { QueryAdapter } from "tbme/queryAdapter";
import {
  GetAboutMe,
  GetAboutMe_siteInfo_urls,
  GetAboutMe_profileUser_PublicProfile,
} from "./gql/GetAboutMe";
import { showToast } from "tds/toaster";
import { logger } from "tbme/logger";
import { sharedUrls } from "../localState/urls";
import { isHireMeEnabled } from "../../../shared/feature";

export const ABOUT_ME_QUERY = gql`
  query GetAboutMe {
    profileUser @client {
      __typename
      ... on PublicProfile {
        isSelf @client
        fullName
        title
        companyName
        state
        country
        bio
        slug
        isExternalUser
        isAvailableForHire @client
        isCommunityUser @client
      }
    }

    siteInfo @client {
      urls {
        profile
      }
    }
  }
`;
export default class extends LightningElement {
  private user: GetAboutMe_profileUser_PublicProfile = undefined!;
  private urls: GetAboutMe_siteInfo_urls = undefined!;
  private visible: boolean = false;
  private displayProfileEditModal: boolean = false;

  @wire(QueryAdapter, {
    query: ABOUT_ME_QUERY,
  })
  private handleResult({ data }: ApolloQueryResult<GetAboutMe>) {
    if (!data) {
      return;
    }

    const { siteInfo, profileUser } = data;

    if (!profileUser || profileUser.__typename !== "PublicProfile") {
      this.visible = false;
      return;
    }

    this.user = profileUser;
    this.urls = siteInfo.urls;
    this.visible = true;
  }

  private get profileUrl() {
    return `${this.urls.profile}/${this.user.slug}`;
  }

  private get showUrlComponent(): boolean {
    return !!(this.user && !this.user.isExternalUser && this.user.slug);
  }

  private get displayUrl() {
    return this.profileUrl && this.profileUrl.replace("https://", "");
  }

  @wire<MultiLabelAdapterConfig>(MultiLabelAdapter, {
    labels: [
      "aboutMe.edit",
      "aboutMe.copy",
      "aboutMe.emptyAddShortBio",
      "aboutMe.hireMeOptIn",
      "aboutMe.hireMeOptOut",
    ],
  })
  private updateLabels({ aboutMe }: { aboutMe: LabelTranslations }) {
    this.labels = {
      ...aboutMe,
    };
  }
  private labels: LabelTranslations = undefined!;

  private get titleAtCompany() {
    if (this.user.title && this.user.companyName) {
      return t("aboutMe.titleAtCompany", {
        title: this.user.title,
        company: this.user.companyName,
      });
    }
    return this.user.companyName;
  }

  private get location(): string {
    if (this.user.state && this.user.country)
      return `${this.user.state}, ${this.user.country}`;
    return `${this.user.country}`;
  }

  private get dotMeSrc() {
    return dotMeSrc;
  }

  private async handleCopyClick() {
    const textarea = this.template.querySelector(
      ".slug-value"
    ) as HTMLTextAreaElement;

    try {
      await navigator.clipboard.writeText(textarea.value);
      showToast({
        message: t("aboutMe.copySuccess"),
        variant: "success",
      });
    } catch (e) {
      logger.error("Error copying text to clipboard", e);
    }
  }

  private get featureNewProfileEditComponent() {
    return process.env.FEATURE_NEW_PROFILE_EDIT_COMPONENT === "true";
  }

  private handleEditClick() {
    if (this.featureNewProfileEditComponent) {
      this.displayProfileEditModal = true;
    } else {
      this.dispatchEvent(
        new CustomEvent("displayeditprofile", {
          bubbles: true,
          composed: true,
        })
      );
    }
  }

  private handleProfileEditRequestClose() {
    this.displayProfileEditModal = false;
  }

  private get displayEmptyState(): boolean {
    return this.user.isSelf && !this.user.bio;
  }

  private get showAvailableForHireLink() {
    return isHireMeEnabled() && this.user.isSelf && this.user.isCommunityUser;
  }

  private get showHireMeButton() {
    return (
      isHireMeEnabled() &&
      this.user.isAvailableForHire &&
      this.user.isCommunityUser
    );
  }

  private get availableForHireLink() {
    const key: string = this.user.isAvailableForHire
      ? "hireMeOptOut"
      : "hireMeOptIn";
    return legacyFormat(this.labels[key], sharedUrls.availableForHire());
  }
}
