import { ApolloQueryResult, gql } from "@apollo/client";
import { LightningElement, api, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { PrivateProfileBannerContext } from "./gql/PrivateProfileBannerContext";
import { chassis } from "tbme/localState";
export default class extends LightningElement {
  @api isSelf: boolean = undefined!;

  @wire(QueryAdapter, {
    query: gql`
      query PrivateProfileBannerContext {
        siteInfo @client {
          urls {
            trailheadHelpProfileOverview
          }
        }
      }
    `,
  })
  private context: ApolloQueryResult<PrivateProfileBannerContext> = undefined!;

  @wire(MultiLabelAdapter, {
    labels: ["privateProfileMe", "privateProfileMeLink", "privateProfileOther"],
  })
  private labels: LabelTranslations = {};

  get learnMoreUrl() {
    return (
      this.context &&
      this.context.data &&
      this.context.data.siteInfo.urls.trailheadHelpProfileOverview
    );
  }

  get isProfileIncomplete(): boolean {
    return (
      !chassis()?.profile?.firstName?.trim() &&
      !chassis()?.profile?.lastName?.trim()
    );
  }

  get incompleteProfileNotice(): string {
    return t("privateProfileMeIncomplete", {
      profileEditModalUrl: "/trailblazer/profile#edit",
    });
  }

  get completeProfileNotice(): string {
    return t("privateProfileMe", {
      learnMoreUrl: this.learnMoreUrl || "",
      settingsUrl: "/trailblazer/settings",
    });
  }
}
