import { api, LightningElement, wire } from "lwc";
import {
  LabelTranslations,
  legacyFormat,
  MultiLabelAdapter,
} from "tbme/localization";
import { sharedUrls } from "../../tbme/localState/urls";

export default class extends LightningElement {
  @api userId = "";

  private isClosed = false;
  private isRendered = false;

  @wire(MultiLabelAdapter, {
    labels: ["hireMeBanner"],
  })
  private labels: LabelTranslations = {};

  renderedCallback() {
    if (!this.isRendered) {
      this.markSeen();
      this.isRendered = true;
    }
  }

  private close() {
    this.isClosed = true;
  }

  private get isVisible() {
    return !(this.isClosed || this.hasBeenSeen());
  }

  private get htmlContent() {
    return legacyFormat(this.labels.hireMeBanner, sharedUrls.settings());
  }

  private hasBeenSeen() {
    return !!localStorage.getItem(this.getStorageKey());
  }

  private markSeen() {
    localStorage.setItem(this.getStorageKey(), "1");
  }

  private getStorageKey() {
    return `tbme__hire_me_banner_seen_${this.userId}`;
  }
}
