/*
  Source code adapted from https://github.com/salesforce/base-components-recipes/tree/master/force-app/main/default/lwc/dualListbox
  Description of changes:
    - The ability to make options required has not been implemented
    - The ability to disable the component has not been implemented
    - Labeling has been altered to receive labels from the parent component
    - The following imports were not implemented:
      InteractingState,
      FieldConstraintApi,
      normalizeVariant,
      VARIANT
    - getRealDOMId has been put directly into the functions that
      used it instead of importing since it was a simple function
    - focus method will not select first element but clear selections instead
*/

import { api, LightningElement, track, wire } from "lwc";
import { handleKeyDownOnOption } from "./keyboard";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";

export default class extends LightningElement {
  private labels: LabelTranslations = undefined!;

  @api options!: any[];
  @api sourceLabel?: undefined;
  @api selectedLabel?: undefined;
  @api label?: undefined;

  @track focusableInSource?: string;
  @track focusableInSelected?: string;

  @track _selectedValues: any[] = [];
  @track highlightedOptions: any[] = [];
  @track optionToFocus?: String | null;
  @track isFocusOnList: boolean = false;
  @track lastSelected?: number;
  @track selectedList?: string;
  @track keyboardInterface: any;
  @track shiftIndex: number = -1;
  @track lastShift: any;

  @wire(MultiLabelAdapter, {
    labels: [
      "duelingPicklist.upButton",
      "duelingPicklist.downButton",
      "duelingPicklist.leftButton",
      "duelingPicklist.rightButton",
    ],
  })
  private handleLabels(labels: { duelingPicklist: LabelTranslations }) {
    this.labels = {
      ...labels.duelingPicklist,
    };
  }

  connectedCallback(): void {
    this.keyboardInterface = this.selectKeyboardInterface();
  }
  renderedCallback(): void {
    if (this.optionToFocus) {
      const option = this.template.querySelector(
        `div[data-value='${this.optionToFocus.replace(/'/g, "\\'")}']`
      ) as HTMLElement;
      if (option) {
        this.isFocusOnList = true;
        option.focus();
      }
    }
  }
  selectKeyboardInterface() {
    const that = this;
    that.shiftIndex = -1;
    that.lastShift = null;
    return {
      getShiftIndex() {
        return that.shiftIndex;
      },
      setShiftIndex(value: any) {
        that.shiftIndex = value;
      },
      getLastShift() {
        return that.lastShift;
      },
      setLastShift(value: any) {
        that.lastShift = value;
      },
      getElementsOfList(listId: any) {
        return that.getElementsOfList(listId);
      },
      selectAllOptions(option: any) {
        that.selectAllFromLastSelectedToOption(option, true);
      },
      updateSelectedOptions(option: any, select: any, isMultiple: any) {
        that.updateSelectedOptions(option, select, isMultiple);
      },
      moveOptionsBetweenLists(addToSelect: any) {
        that.moveOptionsBetweenLists(addToSelect, true);
      },
    };
  }
  @api
  focus() {
    this.isFocusOnList = false;
    this.highlightedOptions = [];
    this.optionToFocus = null;
  }
  @api get value() {
    return this._selectedValues;
  }
  set value(newValue: string[]) {
    this._selectedValues = newValue || [];
  }
  get computedSourceListId() {
    return this.template
      .querySelector("[data-source-list]")
      ?.getAttribute("id");
  }
  get computedSelectedListId() {
    return this.template
      .querySelector("[data-selected-list]")
      ?.getAttribute("id");
  }
  get computedSourceList() {
    let sourceListOptions: any[] = [];
    if (this.options) {
      const values: any[] = this.value;
      sourceListOptions = this.options.filter(
        (option: any) => values.indexOf(option.value) === -1
      );
    }
    return this.computeListOptions(sourceListOptions, this.focusableInSource);
  }
  get computedSelectedList() {
    const selectedListOptions: any[] = [];
    if (this.options) {
      const optionsMap: any = {};
      this.options.forEach((option: any) => {
        optionsMap[option.value] = { ...option };
      });
      this.value.forEach((optionValue: any) => {
        const option = optionsMap[optionValue];
        if (option) {
          option.isSelected = true;
        }
      });
      this.value.forEach((optionValue: any) => {
        const option: any = optionsMap[optionValue];
        if (option) {
          selectedListOptions.push(option);
        }
      });
    }
    return this.computeListOptions(
      selectedListOptions,
      this.focusableInSelected
    );
  }
  private getOptionIndex(optionElement: any) {
    return parseInt(optionElement.getAttribute("data-index"), 10);
  }
  private getListId(optionElement: any): string {
    return optionElement.parentElement.parentElement.getAttribute("id");
  }

  computeListOptions(options: any[], focusableOptionValue: any) {
    if (options.length > 0) {
      const focusableOption = options.find((option: any) => {
        return option.value === focusableOptionValue;
      });
      const focusableValue = focusableOption
        ? focusableOption.value
        : options[0].value;
      return options.map((option: any) => {
        return this.computeOptionProperties(option, focusableValue);
      });
    }
    return [];
  }
  computeOptionProperties(option: any, focusableValue: any) {
    const isSelected = this.highlightedOptions.indexOf(option.value) > -1;
    const classList = isSelected
      ? "listbox__option listbox__option_plain media media_small media_inline is-selected"
      : "listbox__option listbox__option_plain media media_small media_inline";
    return {
      ...option,
      tabIndex: option.value === focusableValue ? "0" : "-1",
      selected: isSelected ? "true" : "false",
      classList,
    };
  }
  handleOptionClick(event: any) {
    const selectMultiple = event.metaKey || event.ctrlKey || event.shiftKey;
    const option = event.currentTarget as HTMLElement;
    if (event.shiftKey) {
      this.selectAllFromLastSelectedToOption(option, false);
      return;
    }
    const selected =
      selectMultiple && option.getAttribute("aria-selected") === "true";
    this.updateSelectedOptions(option, !selected, selectMultiple);
    this.shiftIndex = -1;
  }
  handleOptionKeyDown(event: Event) {
    handleKeyDownOnOption(event, this.keyboardInterface);
  }
  updateSelectedOptions(
    option: HTMLElement,
    select: boolean,
    isMultiple = false
  ) {
    const value = option.getAttribute("data-value")!;
    const listId = this.getListId(option);
    const optionIndex = this.getOptionIndex(option);
    this.updateCurrentSelectedList(listId, isMultiple);
    if (select) {
      if (this.highlightedOptions.indexOf(value) === -1) {
        this.highlightedOptions.push(value);
      }
    } else {
      this.highlightedOptions.splice(this.highlightedOptions.indexOf(value), 1);
    }
    this.updateFocusableOption(listId, value);
    this.lastSelected = optionIndex;
  }
  updateCurrentSelectedList(currentList: string, isMultiple: boolean) {
    if (this.selectedList !== currentList || !isMultiple) {
      if (this.selectedList) {
        this.highlightedOptions = [];
        this.lastSelected = -1;
      }
      this.selectedList = currentList;
    }
  }
  updateFocusableOption(listId: string, value: string) {
    if (listId === this.computedSourceListId) {
      this.focusableInSource = value;
    } else if (listId === this.computedSelectedListId) {
      this.focusableInSelected = value;
    }
    this.optionToFocus = value;
  }
  handleFocus(event: Event) {
    const element = event.target as HTMLElement;
    if (element.getAttribute("role") === "option") {
      if (!this.isFocusOnList) {
        this.isFocusOnList = true;
        this.updateSelectedOptions(element, true, false);
      }
    }
  }
  handleBlur(event: Event) {
    const element = event.target as HTMLElement;
    if (element.getAttribute("role") !== "option") {
      this.isFocusOnList = false;
    }
  }
  moveOptionsBetweenLists(addToSelect: any, retainFocus: boolean = true) {
    const isValidList = addToSelect
      ? this.selectedList === this.computedSourceListId
      : this.selectedList === this.computedSelectedListId;
    if (!isValidList) {
      return;
    }
    const toMove = this.highlightedOptions;
    const values = this.computedSelectedList.map((option: any) => option.value);
    let newValues: any[] = [];
    if (addToSelect) {
      newValues = values.concat(toMove);
    } else {
      newValues = values.filter((value) => toMove.indexOf(value) === -1);
    }
    const oldSelectedValues: any[] = this._selectedValues;
    this._selectedValues = newValues;
    if (toMove.length === 0) {
      this._selectedValues = oldSelectedValues;
      return;
    }
    if (retainFocus) {
      const listId = addToSelect
        ? this.computedSelectedListId
        : this.computedSourceListId;
      this.selectedList = listId!;
      this.updateFocusableOption(listId!, toMove[0]);
    } else {
      this.isFocusOnList = false;
      this.highlightedOptions = [];
      this.optionToFocus = null;
    }

    this.dispatchChangeEvent(newValues);
  }
  handleRightButtonClick() {
    this.moveOptionsBetweenLists(true);
  }
  handleLeftButtonClick() {
    this.moveOptionsBetweenLists(false);
  }
  handleUpButtonClick() {
    this.changeOrderOfOptionsInList(true);
  }
  handleDownButtonClick() {
    this.changeOrderOfOptionsInList(false);
  }
  dispatchChangeEvent(values: any) {
    this.dispatchEvent(
      new CustomEvent("change", {
        composed: true,
        bubbles: true,
        detail: { value: values },
      })
    );
  }
  changeOrderOfOptionsInList(moveUp: any) {
    const elementList = this.getElementsOfList(this.selectedList!);
    const values = this.computedSelectedList.map((option) => option.value);
    const toMove = values.filter(
      (option: any) => this.highlightedOptions.indexOf(option) > -1
    );
    const validSelection =
      toMove.length === 0 || this.selectedList !== this.computedSelectedListId;
    if (validSelection) {
      return;
    }
    let start = moveUp ? 0 : toMove.length - 1;
    let index = values.indexOf(toMove[start]);
    const validMove =
      (moveUp && index === 0) || (!moveUp && index === values.length - 1);
    if (validMove) {
      return;
    }
    if (moveUp) {
      while (start < toMove.length) {
        index = values.indexOf(toMove[start]);
        //elementList
        this.swapOptions(index, index - 1, values);
        start++;
      }
    } else {
      while (start > -1) {
        index = values.indexOf(toMove[start]);
        //elementList
        this.swapOptions(index, index + 1, values);
        start--;
      }
    }
    this._selectedValues = values;
    this.updateFocusableOption(this.selectedList!, toMove[0]);
    this.optionToFocus = null;
    this.dispatchChangeEvent(values);
  }
  swapOptions(i: any, j: any, array: any) {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  getElementsOfList(listId: string) {
    const elements = this.template.querySelectorAll(
      `div[data-type='${listId}']`
    );

    return elements ? elements : [];
  }

  selectAllFromLastSelectedToOption(option: any, all: any) {
    const listId = option.getAttribute("data-type");
    this.updateCurrentSelectedList(listId, true);
    const options = this.getElementsOfList(listId);
    const end = all ? 0 : this.getOptionIndex(option);
    this.lastSelected = this.lastSelected! < 0 ? end : this.lastSelected;
    const start = all ? options.length : this.lastSelected;
    let val, select;
    this.highlightedOptions = [];
    for (let i = 0; i < options.length; i++) {
      select = (i - start!) * (i - end) <= 0;
      if (select) {
        val = options[i].getAttribute("data-value");
        this.highlightedOptions.push(val);
      }
    }
  }
}
