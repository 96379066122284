import { ApolloQueryResult, gql } from "@apollo/client";
import classNames from "classnames";
import { CardState } from "elements/tbui/card/types";
import { LightningElement, track, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { fetchCommunityProfile } from "tbtbc/communityData";
import { getError, getIsLoading, getUserId } from "../communityData/queryUtils";
import {
  CommunityConnection,
  CommunityConnectionCollection,
  LegacyCommunityConnections,
} from "../communityData/types";
import {
  GetCommunityConnections,
  GetCommunityConnections_siteInfo_urls,
} from "./gql/GetCommunityConnections";

type Placeholder = {
  __typename: "Placeholder";
  id: string;
  className?: string;
};

interface DisplayConnection extends CommunityConnection {
  className?: string;
}

type ConnectionsForDisplay = {
  count: number;
  className: string;
  href: string | null;
  items: (DisplayConnection | Placeholder)[] | null;
};

const imageErrors: WeakMap<HTMLImageElement, boolean> = new WeakMap();

export function handleImageError(event: Event, defaultImage: string) {
  const target = event.target as HTMLImageElement;

  if (!target) return;

  if (imageErrors.get(target)) return;

  const isGroup = target.classList.contains("group");

  if (isGroup) {
    target.src = defaultImage;

    imageErrors.set(target, true);
  }
}

export const CONNECTIONS_QUERY = gql`
  fragment LocalConnections on CommunityConnectionCollection {
    __typename
    count
    url
    connections {
      __typename
      id
      url
      imageUrl
      name
    }
  }

  query GetCommunityConnections {
    profileUser @client {
      __typename
      ... on PublicProfile {
        isSelf

        communityUser @client {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
          }
          ... on CommunityUser {
            id
          }
        }

        legacyCommunityConnections @client {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
            error
          }
          ... on LegacyCommunityConnections {
            groups {
              ...LocalConnections
            }
            followers {
              ...LocalConnections
            }
            following {
              ...LocalConnections
            }
          }
        }
      }
    }

    siteInfo @client {
      urls {
        tbcDefaultGroupImage
      }
    }
  }
`;

export default class extends LightningElement {
  private visible: boolean = false;
  private cardState: CardState = "default";
  private labels: LabelTranslations = undefined!;
  private urls: GetCommunityConnections_siteInfo_urls = undefined!;
  private isSelf: boolean = false;

  @track
  private connections?: ConnectionsForDisplay[];

  @wire(MultiLabelAdapter, {
    labels: [
      "connections.title",
      "connections.followers",
      "connections.follower",
      "connections.following",
      "connections.followingSingular",
      "connections.groups",
      "connections.group",
      "connections.bannerTitle",
      "connections.bannerText",
    ],
  })
  private handleLabels(labels: { connections: LabelTranslations }) {
    this.labels = {
      ...labels.connections,
    };
  }
  private get showCommunityBanner() {
    return (
      this.connections &&
      this.isSelf &&
      !this.connections.reduce<number>(
        (acc: number, connection) => acc + connection.count,
        0
      )
    );
  }

  @wire(QueryAdapter, {
    query: CONNECTIONS_QUERY,
    returnPartialData: true,
  })
  private handleResult({
    data,
    error,
  }: ApolloQueryResult<GetCommunityConnections>) {
    if (!data) {
      return;
    }

    this.urls = data.siteInfo.urls;

    if (data.profileUser.__typename !== "PublicProfile") {
      return;
    }

    const profileUser = data.profileUser;
    const { isSelf, communityUser, legacyCommunityConnections } = profileUser;
    const isTbcUser = !!getUserId(communityUser);

    if ((isSelf || isTbcUser) && getIsLoading(legacyCommunityConnections)) {
      this.cardState = "loading";
      this.visible = true;
      return;
    } else if (getIsLoading(legacyCommunityConnections)) {
      this.visible = false;
      return;
    }

    if (isTbcUser && getError(legacyCommunityConnections)) {
      this.cardState = "loadFailed";
      this.visible = true;
      return;
    } else if (getError(legacyCommunityConnections)) {
      this.visible = false;
      return;
    }

    if (!isSelf && !legacyCommunityConnections) {
      this.visible = false;
      return;
    }

    this.setDisplayItems(
      legacyCommunityConnections as LegacyCommunityConnections | null
    );

    this.visible = true;
    this.isSelf = isSelf;
    this.cardState = "default";
  }

  private filterNull(input: object) {
    const result: any = {};

    for (let [key, value] of Object.entries(input)) {
      if (
        input[key as keyof typeof input] !== null &&
        input[key as keyof typeof input] !== undefined
      ) {
        result[key] = value;
      }
    }

    return result;
  }

  connectedCallback() {
    fetchCommunityProfile();
  }

  private handleImageError(event: Event) {
    handleImageError(event, this.urls.tbcDefaultGroupImage);
  }

  private setDisplayItems(queryResult: LegacyCommunityConnections | null) {
    this.connections = ["followers", "following", "groups"].map<
      ConnectionsForDisplay
    >((key) => {
      const defaultConnection: CommunityConnectionCollection = {
        __typename: "CommunityConnectionCollection",
        count: 0,
        url: "",
        connections: [],
      };
      const state: LegacyCommunityConnections = {
        __typename: "LegacyCommunityConnections",
        viewerEntitySubscriptionId: null,
        groups: {
          ...defaultConnection,
        },
        followers: {
          ...defaultConnection,
        },
        following: {
          ...defaultConnection,
        },
        ...(queryResult ? this.filterNull(queryResult) : {}),
      };
      const { count, connections, url } = state[
        key as keyof LegacyCommunityConnections
      ] as CommunityConnectionCollection;

      // W-9489334: need to ensure that a max of 5 items are displayed.
      const maxItems = 5;
      const items: (Placeholder | CommunityConnection)[] = connections
        ? Array.from(connections).splice(0, maxItems)
        : [];

      while (items.length < 5) {
        items.push({
          __typename: "Placeholder",
          id: `placeholder-${items.length}`,
        });
      }
      const result = {
        count,
        href: count > 0 ? url : null,
        className: count > 0 ? "items has-data" : "items",
        items: items.map((item) => ({
          ...item,
          className: classNames(
            {
              group: key === "groups",
              user: key !== "groups",
            },
            item.__typename
          ),
        })),
      };

      const getLabel = this.labelForConnection.bind(this);

      Object.defineProperty(result, "label", {
        get() {
          return getLabel(key, count) || "";
        },
      });

      return result;
    });
  }

  private labelForConnection(type: string, count: number) {
    switch (type) {
      case "following": {
        // TODO: Switch to correct pluralization format after they are translated
        // e.g. the ternery can be removed and `t` can determine the label to use
        return count === 1
          ? this.labels.followingSingular
          : t("connections.following", { count });
      }
      case "followers": {
        // TODO: Switch to correct pluralization format after they are translated
        // e.g. the ternery can be removed and `t` can determine the label to use
        return count === 1
          ? this.labels.follower
          : t("connections.followers", { count });
      }
      case "groups": {
        // TODO: Switch to correct pluralization format after they are translated
        // e.g. the ternery can be removed and `t` can determine the label to use
        return count === 1
          ? this.labels.group
          : t("connections.groups", { count });
      }
    }
  }
}
