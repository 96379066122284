import { api, LightningElement } from "lwc";
import { sharedUrls } from "../../tbme/localState/urls";

export default class extends LightningElement {
  @api avatarUrl?: string;
  @api fullName?: string;

  get tdsAvatarUrl() {
    return this.avatarUrl || sharedUrls.defaultAstroImage();
  }
}
