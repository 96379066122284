import { LightningElement, track } from "lwc";
import { t } from "tbme/localization";
import {
  DigitalResume,
  getDigitalResume,
  DigitalResumeResponse,
} from "../../../shared/experienceApi";
import { getConfig } from "tbme/clientAdapter";
import {
  sendAnalyticEvent,
  AnalyticElementTypes,
} from "../../../shared/analytics";
import { trailheadUrl } from "tbme/localState";
export default class extends LightningElement {
  @track
  digitalResume?: DigitalResume;
  state: "loading" | "error" | "success" = "loading";

  get isEnrolled(): boolean {
    return this.digitalResume ? true : false;
  }

  get isCareerModeEnabled(): boolean {
    return this.digitalResume?.showInSearchResults === true;
  }

  get hasErrors(): boolean {
    return this.state === "error";
  }

  get isLoading(): boolean {
    return this.state === "loading";
  }

  get isSuccessful(): boolean {
    return this.state === "success";
  }

  get buttonLink(): string {
    return this.isEnrolled
      ? this.isCareerModeEnabled
        ? `${trailheadUrl()}/careers/resume`
        : `${trailheadUrl()}/careers/resume`
      : `${trailheadUrl()}/careers/resume?source=profile`;
  }
  get buttonText(): string {
    return this.isEnrolled
      ? this.isCareerModeEnabled
        ? t("careerMarketplace.buttonEnrolledCareerModeOn")
        : t("careerMarketplace.buttonEnrolledCareerModeOff")
      : t("careerMarketplace.buttonUnenrolled");
  }

  get title(): string {
    return t("careerMarketplace.title");
  }

  get containerClass(): string {
    return this.isEnrolled
      ? "cm-container cm-top-border"
      : "cm-container cm-bordered";
  }

  get careerModeStatusText(): string {
    return this.isCareerModeEnabled
      ? t("careerMarketplace.statusOn")
      : t("careerMarketplace.statusOff");
  }

  get bodyText(): string {
    if (this.hasErrors) {
      return t("careerMarketplace.bodyError");
    } else {
      return this.isEnrolled
        ? this.isCareerModeEnabled
          ? t("careerMarketplace.bodyEnrolledCareerModeOn", {
              careerMarketplaceLink: `${trailheadUrl()}/careers`,
              settingsLink: "/trailblazer/settings",
            })
          : t("careerMarketplace.bodyEnrolledCareerModeOff", {
              careerMarketplaceLink: `${trailheadUrl()}/careers`,
              settingsLink: "/trailblazer/settings",
            })
        : t("careerMarketplace.bodyUnenrolled", {
            careerMarketplaceLink: `${trailheadUrl()}/careers`,
          });
    }
  }

  handleAnalytics(e: MouseEvent): void {
    const target = ((e.target as HTMLElement).tagName === "A"
      ? e.target
      : e.currentTarget) as HTMLAnchorElement;
    if (target.href) {
      sendAnalyticEvent("sf/career-mode", {
        name: target.textContent || "",
        link: {
          text: target.textContent || "",
          url: target.href,
          elementType:
            target.tagName === "A"
              ? "text-button"
              : ("button" as AnalyticElementTypes),
        },
      });
    }
  }

  async connectedCallback() {
    const accessToken = getConfig().accessToken;
    this.state = "loading";
    getDigitalResume(accessToken)
      .then((response: DigitalResumeResponse) => {
        if (
          response.data?.digitalResume ||
          (response.errors &&
            response.errors.length === 1 &&
            response.errors[0].status === 404)
        ) {
          this.digitalResume = response.data.digitalResume;
          this.state = "success";
        } else {
          this.state = "error";
        }
      })
      .catch(() => {
        this.state = "error";
      });
  }
}
