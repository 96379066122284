import { api, LightningElement, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";

export default class extends LightningElement {
  @api isSelf: boolean = false;
  @api label: string | null = null;
  @api value: string | null = null;

  private labels: LabelTranslations = undefined!;

  get isVisible() {
    return this.isSelf || this.value;
  }

  get showAddButton() {
    return !this.value && this.isSelf;
  }

  addDetail() {
    this.dispatchEvent(new CustomEvent("add"));
  }

  @wire(MultiLabelAdapter, { labels: ["userDetails.add"] })
  private handleLabels(labels: { userDetails: LabelTranslations }) {
    this.labels = { ...labels.userDetails };
  }
}
