import { gql } from "@apollo/client";

export const ABOUT_MYSELF_QUERY = gql`
  query GetAboutMyself {
    chassis @client {
      profile {
        id
        isPublicProfile
        firstName
        lastName
        title
        role
        company {
          name
        }
        address {
          state
          country
        }
        bio
        username
        profileUrl
        isAvailableForHire
        twitterHandle
        linkedinHandle
        facebookHandle
        websiteUrl
      }
      isSelf
    }
  }
`;
