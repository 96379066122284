import { LightningElement, wire } from "lwc";
import { ApolloQueryResult, gql } from "@apollo/client";
import { QueryAdapter } from "tbme/queryAdapter";
import { GetChassisProfileMetadata } from "./gql/GetChassisProfileMetadata";
import { t } from "tbme/localization";
import { PROFILE_METADATA_QUERY } from "./query";
import { getFullName } from "../profilePage/contexts/chassis";

export default class extends LightningElement {
  documentTitle?: string;
  documentDescription?: string;

  @wire(QueryAdapter, {
    query: PROFILE_METADATA_QUERY,
  })
  private handleResult({ data }: ApolloQueryResult<GetChassisProfileMetadata>) {
    this.setTitle(data);
    this.setDescription(data);
    this.setCanonicalLink(data);
    this.setRobotsTag(data);
  }

  private setTitle(data: GetChassisProfileMetadata) {
    const defaultTitle = t("profileMetadata.defaultPageTitle");

    if (!data || JSON.stringify(data) === "{}") {
      if (!this.documentTitle) {
        document.title = defaultTitle;
        this.documentTitle = defaultTitle;
      }
      return;
    }

    const { chassis } = data;
    if (!chassis?.profile) {
      if (!this.documentTitle) {
        document.title = defaultTitle;
        this.documentTitle = defaultTitle;
      }
      return;
    }
    const { profile } = chassis;
    const fullName = getFullName(profile);

    if (!profile.isPublicProfile) {
      document.title = t("profileMetadata.pageTitle", {
        fullName,
      });
      return;
    }

    if (profile.title && profile.company?.name) {
      document.title = t("profileMetadata.pageTitleWithTitleAndCompany", {
        fullName,
        title: profile.title,
        company: profile.company.name,
      });
    } else if (profile.title) {
      document.title = t("profileMetadata.pageTitleWithTitle", {
        fullName,
        title: profile.title,
      });
    } else if (profile.company?.name) {
      document.title = t("profileMetadata.pageTitleWithCompany", {
        fullName,
        company: profile.company.name,
      });
    } else {
      document.title = t("profileMetadata.pageTitle", {
        fullName,
      });
    }
  }

  private setDescription(data: GetChassisProfileMetadata) {
    const defaultDescription = t("profileMetadata.defaultPageDescription");

    // Guard against the description meta tag not being present in the page.
    let descriptionMetaElement = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    if (!descriptionMetaElement) {
      descriptionMetaElement = document.createElement("meta");
      descriptionMetaElement.setAttribute("name", "description");
      descriptionMetaElement.content = defaultDescription;
      document
        .getElementsByTagName("head")[0]
        .appendChild(descriptionMetaElement);
    }

    if (!data || JSON.stringify(data) === "{}") {
      if (!this.documentDescription) {
        descriptionMetaElement.setAttribute("content", defaultDescription);
        this.documentDescription = defaultDescription;
      }
      return;
    }

    let description = defaultDescription;
    const { chassis } = data;
    if (!chassis?.profile) {
      if (!this.documentDescription) {
        descriptionMetaElement.setAttribute("content", defaultDescription);
        this.documentDescription = defaultDescription;
      }
      return;
    }
    const { profile } = chassis;

    if (profile.isPublicProfile) {
      if (profile.bio) {
        const maxBioLength = 160;
        description =
          profile.bio.length > maxBioLength
            ? profile.bio.slice(0, maxBioLength)
            : profile.bio;
      } else {
        description = profile.title
          ? t("profileMetadata.titleAtCompany", {
              title: profile.title,
              company: profile.company?.name,
            })
          : t("profileMetadata.trailblazerAtCompany", {
              company: profile.company?.name,
            });
      }
    }

    descriptionMetaElement.setAttribute("content", description);
  }

  private setCanonicalLink(data: GetChassisProfileMetadata) {
    if (!data) {
      return;
    }

    const { chassis, siteInfo } = data;
    if (!chassis?.profile) {
      return;
    }
    const { profile } = chassis;

    if (profile.isPublicProfile) {
      if (profile.username) {
        // Guard against the canonical link not being present in the page.
        let canonicalLinkElement = document.querySelector(
          'link[rel="canonical"]'
        ) as HTMLLinkElement;
        if (!canonicalLinkElement) {
          canonicalLinkElement = document.createElement("link");
          canonicalLinkElement.setAttribute("rel", "canonical");
          document
            .getElementsByTagName("head")[0]
            .appendChild(canonicalLinkElement);
        }

        canonicalLinkElement.setAttribute(
          "href",
          `${siteInfo.urls.profile.substring(
            0,
            siteInfo.urls.profile.lastIndexOf("/")
          )}/${profile.username}`
        );
      }
    }
  }

  private setRobotsTag(data: GetChassisProfileMetadata) {
    if (!data) {
      return;
    }
    const { chassis } = data;
    if (!chassis?.profile) {
      return;
    }
    const { profile } = chassis;
    if (!profile.isPublicProfile) {
      let robotsMetaElement = document.querySelector(
        'meta[name="robots"]'
      ) as HTMLMetaElement;
      if (!robotsMetaElement) {
        robotsMetaElement = document.createElement("meta");
        robotsMetaElement.setAttribute("name", "robots");
        robotsMetaElement.content = "noindex";
        document.getElementsByTagName("head")[0].appendChild(robotsMetaElement);
      } else {
        robotsMetaElement.setAttribute("content", "noindex");
      }
    }
  }
}
