import { getErrorMessage, useApexMethod } from "tbme/legacyApexBridge";
import {
  communityStats,
  getProfileCommunityUserId,
  pendingValue,
  profileCommunityUser,
} from "./state";
import { ApexCommunityStatsResponse } from "./types";

interface ApexCommunityStatsArgs {
  communityUserId: string;
}

const getCommunityStats = useApexMethod<
  ApexCommunityStatsArgs,
  ApexCommunityStatsResponse
>("TrailblazerActivityController.getCommunityStats");

export const fetchCommunityStats = async (
  communityUserId: string | null = getProfileCommunityUserId()
) => {
  // If there is no user, set the community stats state to null and exit
  if (!communityUserId) {
    communityStats(null);

    return communityStats();
  }

  communityStats(pendingValue);

  try {
    const response = await getCommunityStats({
      communityUserId,
    });

    const { status, data } = response;

    communityStats({
      __typename: "CommunityStats",
      questionsCount: 0,
      answersCount: 0,
      bestAnswersCount: 0,
      communityUserRank: null,
      ...data,
    });
  } catch (error) {
    communityStats({
      __typename: "CommunityAsyncResponse",
      isLoading: false,
      error: getErrorMessage(error),
    });
  }

  return communityStats();
};
