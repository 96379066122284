import { LightningElement, api, wire } from "lwc";
import {
  MultiLabelAdapter,
  LabelTranslations,
  legacyFormat,
} from "tbme/localization";
import { PresentationSkill } from "../skillsChart/types";

// Use base10 when parsing strings for an integer.
const base10: number = 10;

export default class SkillsRing extends LightningElement {
  @wire(MultiLabelAdapter, {
    labels: [
      "skillsRingSkillsNone",
      "skillsRingSkillsSingular",
      "skillsRingSkills",
      "percentDisplay",
    ],
  })
  private labels: LabelTranslations = {};

  @api skills: PresentationSkill[] = undefined!;

  // hoveredSkillId and selectedSkillId are dynamically set/unset, so
  // define as "string | null" and initialize to null.
  @api hoveredSkillId: string | null = null;
  @api selectedSkillId: string | null = null;

  private get activeSkillId(): string | null {
    if (this.hoveredSkillId !== null) {
      return this.hoveredSkillId;
    } else if (this.selectedSkillId !== null) {
      return this.selectedSkillId;
    } else {
      return null;
    }
  }

  _ringWidth: number = 0;
  @api
  get ringWidth(): number {
    return this._ringWidth;
  }
  set ringWidth(value: number) {
    this._ringWidth = parseInt(String(value), base10);
  }

  _strokeWidth: number = 0;
  @api
  get strokeWidth(): number {
    return this._strokeWidth;
  }
  set strokeWidth(value: number) {
    this._strokeWidth = parseInt(String(value), base10);
  }

  private get activeSkill(): PresentationSkill | null {
    if (this.activeSkillId === null) {
      return null;
    }

    const result = this.skills.find((skill) => skill.id === this.activeSkillId);

    return result ? result : null;
  }

  private get boldText(): string {
    if (this.activeSkill) {
      return this.percentDisplay!;
    }

    const count: number = this.skills.length;
    if (count > 1) {
      return legacyFormat(this.labels.skillsRingSkills, count);
    } else if (count === 1) {
      return this.labels.skillsRingSkillsSingular;
    } else {
      return this.labels.skillsRingSkillsNone;
    }
  }

  private get weakText(): string | null {
    return this.activeSkill ? this.activeSkill.skill.name : null;
  }

  private get percentDisplay(): string {
    const fraction = this.skillPercent(this.activeSkill!);
    const percent = Math.max(Math.round(fraction * 100), 1);

    return legacyFormat(this.labels.percentDisplay, percent);
  }

  private get totalUnitTotalPerSkill(): number {
    return this.skills.reduce(
      (sum, skill) => sum + skill.itemProgressEntryCount,
      0
    );
  }

  private get presentationSkills(): PresentationSkill[] {
    return this.skills.map((skill, i) => {
      const percent = this.skillPercent(skill);
      const degrees = this.skillDegrees(skill);
      const start = this.skillStartAngle(i);
      const isHovered =
        this.activeSkillId && this.activeSkillId === skill.id ? true : false;

      return {
        ...skill,
        degrees,
        percent,
        arc: this.skillArc(start, degrees),
        opacity: isHovered || !this.activeSkill ? 1 : 0.5,
        strokeWidth: isHovered ? this.strokeWidthActive : this.strokeWidth,
      };
    });
  }

  private get strokeWidthActive(): number {
    return this.strokeWidth * 1.5;
  }

  private get viewBox(): string {
    return `0 0 ${this.ringWidth} ${this.ringWidth}`;
  }

  private get radius(): number {
    return this.center - this.strokeWidthActive / 2;
  }

  private get center(): number {
    return this.ringWidth / 2;
  }

  skillStartAngle(index: number): number {
    return this.skills
      .slice(0, index)
      .reduce((sum, skill) => sum + this.skillDegrees(skill), 0);
  }

  skillPercent(skill: PresentationSkill): number {
    return skill.itemProgressEntryCount / this.totalUnitTotalPerSkill;
  }

  skillDegrees(skill: PresentationSkill): number {
    return this.skillPercent(skill) * 360;
  }

  skillArc(startAngle: number, degrees: number): string {
    const { center, radius } = this;
    const endAngle = startAngle + degrees;

    const start = polarToCartesian(center, center, radius, endAngle);
    const end = polarToCartesian(center, center, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    // Workaround for a bug where if there's one skill, no arc shows up.
    const endX = this.skills.length === 1 ? end.x + 0.001 : end.x;

    return [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      endX,
      end.y,
    ].join(" ");
  }

  handleEnterSkill(event: MouseEvent): void {
    this.hoveredSkillId = (event.target as HTMLButtonElement).dataset.id!;
    this.dispatchEvent(
      new CustomEvent("ringsliceenter", {
        detail: {
          id: this.hoveredSkillId,
        },
      })
    );
  }

  handleLeaveSkill(): void {
    this.hoveredSkillId = null;
    this.dispatchEvent(new CustomEvent("ringsliceleave"));
  }
}

/**
 * Converts polar coordinates to cartesian coordinates
 * @param {number} centerX - the x-coordinate of the circle in the DOM
 * @param {number} centerY - the y-coordinate of the circle in the DOM
 * @param {number} radius - the radius of the circle in the DOM
 * @param {number} angleInDegrees - the angle of the circle in the DOM
 * @return {Object} - the cartesian coordinates of the angle
 */
function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
