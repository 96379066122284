import { gql } from "@apollo/client";

export const HEADER_QUERY = gql`
  query GetHeaderContext {
    appLauncherItems @client {
      id
      label
      link {
        href
      }
      imageUrl
    }
    currentUser @client {
      id
      avatarUrl
      fullName
      backgroundImageUrl
    }
    locale @client
    siteInfo @client {
      name
      urls {
        home
        logo
        profile
        settings
        assets
        signup
        login
        logout
      }
    }
  }
`;
