import { gql } from "@apollo/client";

export const COMMUNITY_TAGS_QUERY = gql`
  query GetCommunityTags(
    $slug: String
    $hasSlug: Boolean!
    $queryCommunityTags: Boolean!
  ) {
    profile(slug: $slug) @include(if: $hasSlug) {
      __typename
      id
      ... on PublicProfile {
        communityTags {
          id
          title
          imageUrl
        }
      }
    }

    communityTags @include(if: $queryCommunityTags) {
      id
      title
      imageUrl
    }
  }
`;

