import { LightningElement, api } from "lwc";

export default class LwcCardFooterLink extends LightningElement {
  @api href?: string;
  @api title!: string;
  @api target?: string;
  @api isLoading?: boolean = false;

  labels = { loadingText: "" };

  get isButton() {
    return !this.href;
  }

  handleClick() {
    if (this.isLoading) return;

    this.dispatchEvent(new CustomEvent("clickfooterlink"));
  }
}
