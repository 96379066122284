import { currentUser, profileUser } from "tbme/localState";
import { showToast } from "tds/toaster";
import { getContext, setContext } from "./context";
import { ProfileContext } from "./types";

type ToastVariant = "info" | "error" | "success";
export interface AuraEvent<T> {
  getParams(): T;
}

export type AuraToastEvent = AuraEvent<{
  variant: ToastVariant;
  message: string;
}>;

export interface LegacyToastEvent extends Event {
  detail: {
    state: ToastVariant;
    message: string;
  };
}

export type AuraAvatarUploadEvent = AuraEvent<{
  photoUrl: string;
}>;

export type AuraContextChangeEvent = AuraEvent<{
  data: Partial<ProfileContext>;
}>;

/**
 * Handle Aura c:ToastEvent and create a toast
 */
export function handleAuraToast(event: AuraToastEvent) {
  const { variant, message } = event.getParams();
  showToast({
    variant,
    unsafeMessage: message,
  });
}

/**
 * Legacy on-core LWC components dispatch the "toast" CustomEvent
 */
export function handleOnCoreLwcToast(event: Event) {
  const { state, message } = (event as LegacyToastEvent).detail;
  showToast({
    variant: state,
    unsafeMessage: message,
  });
}

/**
 * The legacy Aura component AvatarUploader dispatches an Application event
 * after a new avatar has been uploaded. The state should only be changed if
 * the user is logged in and viewing their own profile.
 */
export function handleAuraAvatarUpload(event: AuraAvatarUploadEvent) {
  const { photoUrl } = event.getParams();
  const user = currentUser();
  const profile = profileUser();

  if (profile && user && user.id === profile.id) {
    const updates = {
      ...getContext(),
      identity: {
        ...getContext().identity,
        ProfilePictureUrl: photoUrl,
      },
      profilePhotoUrl: photoUrl,
    };

    setContext(updates as ProfileContext);
  }
}

export function handleAuraContextChange(event: AuraContextChangeEvent) {
  const { data } = event.getParams();

  if (!data) return;

  setContext({
    ...getContext(),
    ...data,
  });
}
