import { makeVar } from "@apollo/client";
import { api, LightningElement } from "lwc";
import { Locale } from "tbme/localization";
import { FooterLink } from "../../tbui/pageFooter/types";
import {
  AppLauncherItem,
  User,
  LocalProfile,
  SiteInfo,
  LocaleOption,
  MenuOption,
} from "./types";

import { ChassisContext } from "../profilePage/types";

export const appLauncherItems = makeVar<AppLauncherItem[]>([]);
export const currentUser = makeVar<User | null>(null);
export const profileUser = makeVar<LocalProfile | null>(null);

export const isSelf = (): boolean => {
  return !!(currentUser() && profileUser() && currentUser()?.id === profileUser()?.id);
}
export const isPublic = (): boolean => {
  return !!(isSelf() || (profileUser() && profileUser()?.isPublicProfile));
}

/** Shared URL Variables */
export const baseUrl = makeVar<string>(
  (window.sfdcBase?.exp || process.env.USE_DIGITAL_PROFILE_URLS === "true"
    ? process.env.DIGITAL_PROFILE_BASE_URL
    : process.env.TBME_BASE_URL) || ""
);
export const assetsUrl = makeVar<string>(process.env.ASSETS_URL || "");

export const defaultTrailheadUrl = "https://trailhead.salesforce.com";

export const trailheadUrl = makeVar<string>(
  process.env.TRAILHEAD_URL || defaultTrailheadUrl
);

export const locale = makeVar<Locale>("en");

export const siteInfo = makeVar<Partial<SiteInfo>>({
  name: process.env.TBME_SITE_NAME!,
  logoUrl: process.env.TBME_LOGO_URL!,
});

export const tbcCurrentUserId = makeVar<string | null>(null);
export const tbcProfileUserId = makeVar<string | null>(null);

export const localeOptions = makeVar<LocaleOption[]>([
  { label: "English", value: "en" },
  { label: "Français", value: "fr" },
  { label: "Deutsch", value: "de" },
  { label: "日本語", value: "ja" },
  { label: "Português (Brasil)", value: "pt-BR" },
  { label: "Español (LATAM)", value: "es-MX" },
  { label: "한국어", value: "ko" },
  { label: "[中文（简体）]'介引]", value: "zh-CN" },
]);

export const footerLinks = makeVar<FooterLink[]>([
  {
    label: "Privacy Statement",
    href: "https://salesforce.com/company/privacy",
    image: null,
  },
  {
    label: "Terms of Use",
    href: "https://trailblazer.me/terms?lang=en",
    image: null,
  },
  {
    label: "Use of Cookies",
    href: "https://www.salesforce.com/company/privacy/full_privacy/#nav_info",
    image: null,
  },
  {
    label: "Trust",
    href: "https://trust.salesforce.com/",
    image: null,
  },
  {
    label: "Accessibility",
    href:
      "https://trailhead.salesforce.com/help?article=Trailhead-Accessibility",
    image: null,
  },
  {
    label: "Help",
    href: "https://trailhead.salesforce.com/help",
    image: null,
  },
  {
    label: "Your Privacy Choices",
    href: "https://www.salesforce.com/form/other/privacy-request",
    image: "privacychoices.svg",
  },
]);

export const chassis = makeVar<ChassisContext | null>(null);
export const isRenderProfileChassis = makeVar<boolean>(false);

/**
 * This "element" provides an interface for setting localState variables from markup. This has utility in stories and
 * other scenarios such as a standalone footer component with data arbitrarily set by the template.
 */
export default class LocalState extends LightningElement {
  @api
  get profileUser() {
    return profileUser();
  }
  set profileUser(value: LocalProfile | null) {
    profileUser(value);
  }

  @api
  get currentUser() {
    return currentUser();
  }
  set currentUser(value: User | null) {
    currentUser(value);
  }

  @api
  get baseUrl() {
    return baseUrl();
  }
  set baseUrl(value: string) {
    baseUrl(value);
  }

  @api
  get assetsUrl() {
    return assetsUrl();
  }
  set assetsUrl(value: string) {
    assetsUrl(value);
  }

  @api
  get trailheadUrl() {
    return trailheadUrl();
  }
  set trailheadUrl(value: string) {
    trailheadUrl(value);
  }

  @api
  get locale() {
    return locale();
  }
  set locale(value: Locale) {
    locale(value);
  }

  @api
  get siteInfo() {
    return siteInfo();
  }
  set siteInfo(value: Partial<SiteInfo>) {
    siteInfo(value);
  }

  @api
  get tbcCurrentUserId() {
    return tbcCurrentUserId();
  }
  set tbcCurrentUserId(value: string | null) {
    tbcCurrentUserId(value);
  }

  @api
  get tbcProfileUserId() {
    return tbcProfileUserId();
  }
  set tbcProfileUserId(value: string | null) {
    tbcProfileUserId(value);
  }

  @api
  get localeOptions() {
    return localeOptions();
  }
  set localeOptions(value: LocaleOption[]) {
    localeOptions(value);
  }

  @api
  get footerLinks() {
    return footerLinks();
  }
  set footerLinks(value: FooterLink[]) {
    footerLinks(value);
  }

  @api
  get chassis() {
    return chassis();
  }
  set chassis(value: ChassisContext | null) {
    if (!value) {
      chassis(null);
    } else {
      // We are doing this spread dance to avoid mutating state.
      // ChassisContext is a complex type with nested objects and some not nullable fields at all levels ...
      chassis({
        ...value,
        profile: {
          ...value.profile,
          company: { ...value.profile?.company },
          ...(value.profile?.address && { ...value.profile.address }),
        },
      });
    }
  }

  @api
  get isRenderProfileChassis() {
    return isRenderProfileChassis();
  }
  set isRenderProfileChassis(value: boolean) {
    isRenderProfileChassis(value);
  }
}
