import { LightningElement, wire } from "lwc";
import { ApolloQueryResult, gql } from "@apollo/client";
import { QueryAdapter } from "tbme/queryAdapter";
import { GetProfileUser } from "./gql/GetProfileUser";
import { t } from "tbme/localization";

export const PROFILE_USER_QUERY = gql`
  query GetProfileUser {
    profileUser @client {
      __typename
      fullName
      ... on PublicProfile {
        bio
        title
        companyName
        slug
      }
      ... on PrivateProfile {
        __typename
      }
    }

    siteInfo @client {
      urls {
        profile
      }
    }
  }
`;

export default class extends LightningElement {
  @wire(QueryAdapter, {
    query: PROFILE_USER_QUERY,
  })
  private handleResult({ data }: ApolloQueryResult<GetProfileUser>) {
    this.setTitle(data);
    this.setDescription(data);
    this.setCanonicalLink(data);
  }

  private setTitle(data: GetProfileUser) {
    const defaultTitle = t("profileMetadata.defaultPageTitle");

    if (!data) {
      document.title = defaultTitle;
      return;
    }

    const { profileUser } = data;
    if (!profileUser) {
      document.title = defaultTitle;
      return;
    }

    if (profileUser.__typename !== "PublicProfile") {
      document.title = t("profileMetadata.pageTitle", {
        fullName: profileUser.fullName,
      });
      return;
    }

    if (profileUser.title && profileUser.companyName) {
      document.title = t("profileMetadata.pageTitleWithTitleAndCompany", {
        fullName: profileUser.fullName,
        title: profileUser.title,
        company: profileUser.companyName,
      });
    } else if (profileUser.title) {
      document.title = t("profileMetadata.pageTitleWithTitle", {
        fullName: profileUser.fullName,
        title: profileUser.title,
      });
    } else if (profileUser.companyName) {
      document.title = t("profileMetadata.pageTitleWithCompany", {
        fullName: profileUser.fullName,
        company: profileUser.companyName,
      });
    } else {
      document.title = t("profileMetadata.pageTitle", {
        fullName: profileUser.fullName,
      });
    }
  }

  private setDescription(data: GetProfileUser) {
    const defaultDescription = t("profileMetadata.defaultPageDescription");

    // Guard against the description meta tag not being present in the page.
    let descriptionMetaElement = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    if (!descriptionMetaElement) {
      descriptionMetaElement = document.createElement("meta");
      descriptionMetaElement.setAttribute("name", "description");
      descriptionMetaElement.content = defaultDescription;
      document
        .getElementsByTagName("head")[0]
        .appendChild(descriptionMetaElement);
    }

    if (!data) {
      descriptionMetaElement.setAttribute("content", defaultDescription);
      return;
    }

    let description = defaultDescription;
    const { profileUser } = data;
    if (!profileUser) {
      descriptionMetaElement.setAttribute("content", defaultDescription);
      return;
    }

    if (profileUser.__typename === "PublicProfile") {
      if (profileUser.bio) {
        const maxBioLength = 160;
        description =
          profileUser.bio.length > maxBioLength
            ? profileUser.bio.slice(0, maxBioLength)
            : profileUser.bio;
      } else {
        description = profileUser.title
          ? t("profileMetadata.titleAtCompany", {
              title: profileUser.title,
              company: profileUser.companyName,
            })
          : t("profileMetadata.trailblazerAtCompany", {
              company: profileUser.companyName,
            });
      }
    }

    descriptionMetaElement.setAttribute("content", description);
  }

  private setCanonicalLink(data: GetProfileUser) {
    if (!data) {
      return;
    }

    const { profileUser, siteInfo } = data;
    if (!profileUser) {
      return;
    }

    if (profileUser.__typename === "PublicProfile") {
      if (profileUser.slug) {
        // Guard against the canonical link not being present in the page.
        let canonicalLinkElement = document.querySelector(
          'link[rel="canonical"]'
        ) as HTMLLinkElement;
        if (!canonicalLinkElement) {
          canonicalLinkElement = document.createElement("link");
          canonicalLinkElement.setAttribute("rel", "canonical");
          document
            .getElementsByTagName("head")[0]
            .appendChild(canonicalLinkElement);
        }

        canonicalLinkElement.setAttribute(
          "href",
          `${siteInfo.urls.profile}/${profileUser.slug}`
        );
      }
    }
  }
}
