import { api } from "lwc";
import ButtonIconText from "tbui/buttonIconText";
import { Sprite } from "tds/icon";

export default class extends ButtonIconText {
  @api href: string | null = null;
  @api rel: string | null = null;
  @api target: "_self" | "_blank" | "_parent" | "_top" | null = null;
  @api iconSymbol: string = undefined!;
  @api iconSprite: Sprite = "utility";
  @api iconSize: string = "override";
}
