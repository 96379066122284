import { ApolloQueryResult } from "@apollo/client";
import { LightningElement, track, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import { QueryAdapter as TBCQueryAdapter } from "tbtbc/queryAdapter";
import trophyIcon from "./assets/trophy.svg";
import { CardState } from "../../tbui/card/types";
import { Q_AND_A_QUERY, USER_CONTEXT_QUERY } from "./query";

import {
  GetQuestionsAndAnswersStats,
  GetQuestionsAndAnswersStats_profileData_communityUserUrls,
  GetQuestionsAndAnswersStats_profileData_questionAndAnswersStats,
  GetQuestionsAndAnswersStatsVariables,
} from "./gql/GetQuestionsAndAnswersStats";
import { GetQAndAUserContext } from "./gql/GetQAndAUserContext";
import { isAcceptedAnswersLabelEnabled } from "../../../shared/feature";

export default class extends LightningElement {
  @track
  private variables: GetQuestionsAndAnswersStatsVariables = {
    userSlug: "",
    queryQuestionsAndAnswers: false,
  };

  private visible: boolean = false;
  private footerUrl: string = "";
  private isSelf: boolean = false;
  private isPublicProfile: boolean = false;
  private noActivity: boolean = false;
  private labels: LabelTranslations = undefined!;
  private cardState: CardState = "default";

  private get showNoActivity() {
    return !this.visible && this.noActivity && this.cardState === "default";
  }

  private get assets() {
    return {
      trophy: trophyIcon,
    };
  }
  private stats: GetQuestionsAndAnswersStats_profileData_questionAndAnswersStats | null = null;

  @wire(MultiLabelAdapter, {
    labels: [
      "questionsAndAnswers.title",
      "questionsAndAnswers.goToCommunityFeed",
    ],
  })
  private handleLabels(labels: { questionsAndAnswers: LabelTranslations }) {
    this.labels = {
      ...labels.questionsAndAnswers,
    };
  }

  private get answersLabel() {
    return t("questionsAndAnswers.answers", {
      count: this.stats ? this.stats.answersCount : 0,
    });
  }

  private get bestAnswersLabel() {
    let labelName = "questionsAndAnswers.bestAnswers";
    if (isAcceptedAnswersLabelEnabled()) {
      labelName = "questionsAndAnswers.acceptedAnswers";
    }
    return t(labelName, {
      count: this.stats ? this.stats.bestAnswersCount : 0,
    });
  }

  private get questionsLabel() {
    return t("questionsAndAnswers.questions", {
      count: this.stats ? this.stats.questionsCount : 0,
    });
  }

  private get rankLabel() {
    return t("questionsAndAnswers.rank", {
      rank: (this.stats && this.stats.communityUserRank) || "",
    });
  }

  private get answersLink() {
    return this.stats && this.stats.answersCount && this.urls?.answersUrl;
  }

  private get bestAnswersLink() {
    return (
      this.stats && this.stats.bestAnswersCount && this.urls?.bestAnswersUrl
    );
  }

  private get questionsLink() {
    return this.stats && this.stats.questionsCount && this.urls?.questionsUrl;
  }

  private get activityCount() {
    if (!this.stats) return 0;

    return [
      this.stats.questionsCount,
      this.stats.answersCount,
      this.stats.bestAnswersCount,
    ].reduce<number>((acc, value) => {
      if (value === null) return acc;

      return acc + value;
    }, 0);
  }

  private get communityFeedLink() {
    return this.urls?.feedUrl;
  }

  private urls: GetQuestionsAndAnswersStats_profileData_communityUserUrls | null = null;

  @wire(QueryAdapter, { query: USER_CONTEXT_QUERY })
  handleResult({ data, error }: QueryAdapterValue<GetQAndAUserContext>) {
    if (!data) {
      return;
    }

    if (data.chassis && data.chassis.profile && data.chassis.profile.username) {
      this.isSelf = data.chassis.isSelf;
      this.isPublicProfile = data.chassis.profile.isPublicProfile;
      this.variables.userSlug = data.chassis.profile.username;
      this.variables.queryQuestionsAndAnswers = true;
    }
  }

  @wire(TBCQueryAdapter, {
    query: Q_AND_A_QUERY,
    variables: "$variables",
  })
  handleResultTbc({
    data,
    error,
    errors,
  }: ApolloQueryResult<GetQuestionsAndAnswersStats>) {
    const hasErrors = !!error || !!errors;
    if (this.isSelf && hasErrors) {
      this.cardState = "loadFailed";
      this.visible = true;
      this.noActivity = false;
      return;
    } else if (hasErrors) {
      this.visible = false;
      this.noActivity = false;
      return;
    }

    if (
      !data ||
      (!this.isPublicProfile && !this.isSelf) ||
      !this.variables.queryQuestionsAndAnswers
    ) {
      return;
    }
    this.urls = data?.profileData?.communityUserUrls;

    const profileData = data.profileData;
    if (!profileData) {
      return;
    }

    if (!this.isSelf && !profileData.communityUserId) {
      this.noActivity = true;
      this.visible = false;
      return;
    }

    if (profileData.questionAndAnswersStats) {
      this.stats = profileData.questionAndAnswersStats;
    } else if (this.isSelf && !profileData.questionAndAnswersStats) {
      this.stats = {
        questionsCount: 0,
        answersCount: 0,
        bestAnswersCount: 0,
        communityUserRank: null,
      } as GetQuestionsAndAnswersStats_profileData_questionAndAnswersStats;
    }

    this.cardState = "default";
    this.visible = !!this.stats;
  }
}
