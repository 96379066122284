import { Locale } from "tbme/localization";

const TRAILHEAD_LOCALES: Locale[] = [
  "en",
  "fr",
  "de",
  "ja",
  "pt-BR",
  "es-MX",
  "ko",
  "zh-CN",
  ...(process.env.FEATURE_NEW_TRAILHEAD_LOCALES_ENABLED === "true"
    ? ["es", "it"]
    : []),
];
const TRAILHEAD_DEFAULT: Locale = "en";

export const trailheadLocaleFor = (locale: Locale) =>
  TRAILHEAD_LOCALES.includes(locale) ? locale : TRAILHEAD_DEFAULT;
