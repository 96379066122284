import { LightningElement, api } from "lwc";
import { LabelTranslations } from "tbme/localization";
import { t } from "tbme/localization";
import { validateWebsite, validateSocialHandle } from "../profileEdit/form";
interface SocialLink {
  name: string;
  href: string;
  class: string;
  assistiveText: string;
}

export default class extends LightningElement {
  _twitterHandle?: SocialLink;
  _linkedinHandle?: SocialLink;
  _facebookHandle?: SocialLink;
  _websiteUrl?: SocialLink;

  private labels: LabelTranslations = undefined!;

  @api name?: string;

  @api
  get twitterHandle(): SocialLink | undefined | string {
    return this._twitterHandle;
  }
  set twitterHandle(value: SocialLink | undefined | string) {
    value = validateSocialHandle("https://www.twitter.com", value as string)
      ? value
      : undefined;
    this._twitterHandle = value
      ? ({
          name: "twitter",
          href: `https://www.twitter.com/${value}`,
          class: "social twitter",
          assistiveText: t("socialLinks.twitterAssistiveText", {
            name: this.name,
          }),
        } as SocialLink)
      : undefined;
  }

  @api
  get linkedinHandle(): SocialLink | undefined | string {
    return this._linkedinHandle;
  }
  set linkedinHandle(value: SocialLink | undefined | string) {
    value = validateSocialHandle("https://www.linkedin.com/in", value as string)
      ? value
      : undefined;
    this._linkedinHandle = value
      ? ({
          name: "linkedin",
          href: `https://www.linkedin.com/in/${value}`,
          class: "social linkedin",
          assistiveText: t("socialLinks.linkedInAssistiveText", {
            name: this.name,
          }),
        } as SocialLink)
      : undefined;
  }

  @api
  get facebookHandle(): SocialLink | undefined | string {
    return this._facebookHandle;
  }
  set facebookHandle(value: SocialLink | undefined | string) {
    value = validateSocialHandle("https://www.facebook.com", value as string)
      ? value
      : undefined;
    this._facebookHandle = value
      ? ({
          name: "facebook",
          href: `https://www.facebook.com/${value}`,
          class: "social facebook",
          assistiveText: t("socialLinks.facebookAssistiveText", {
            name: this.name,
          }),
        } as SocialLink)
      : undefined;
  }

  @api
  get websiteUrl(): SocialLink | undefined | string {
    return this._websiteUrl;
  }
  set websiteUrl(value: SocialLink | undefined | string) {
    let website = validateWebsite(value as string);
    this._websiteUrl = website
      ? ({
          name: "link",
          href: `${website}`,
          class: "social link",
          assistiveText: t("socialLinks.websiteAssistiveText", {
            name: this.name,
          }),
        } as SocialLink)
      : undefined;
  }

  get socialLinks() {
    return [
      this.linkedinHandle,
      this.twitterHandle,
      this.facebookHandle,
      this.websiteUrl,
    ].filter((link) => link);
  }

  get isVisible() {
    return this.socialLinks.length > 0;
  }
}
