import { setAccessToken, setAccessTokenType } from "tbme/clientAdapter";
import {
  setAccessToken as setAccessTokenTbtbc,
  setAccessTokenType as setAccessTokenTypeTbtbc,
} from "tbtbc/clientAdapter";
import { currentUser } from "tbme/localState";
import { ProfileContext } from "../types";

export class CurrentUserContextError extends Error {}

/**
 * Update currentUser variable from identity object set by visualforce page.
 */
export const setLegacyCurrentUserState = ({
  cuid,
  identity,
}: ProfileContext) => {
  if (!cuid || !identity) {
    currentUser(null);
    return;
  }

  const { name, ProfilePictureUrl, backgroundImageUrl } = identity;

  if (!name || !ProfilePictureUrl || !backgroundImageUrl) {
    throw new CurrentUserContextError(
      `Invalid context provided for currentUser.`
    );
  }

  setAccessToken(identity.access_token || "");

  currentUser({
    id: cuid,
    fullName: name,
    avatarUrl: ProfilePictureUrl,
    backgroundImageUrl: backgroundImageUrl,
  });
};

export const setChassisCurrentUserState = ({ chassis }: ProfileContext) => {
  const accessTokenType = "Bearer";
  setAccessTokenType(accessTokenType);
  setAccessTokenTypeTbtbc(accessTokenType);

  // Set access tokens for both TBTBC and TBME Clients
  setAccessToken(chassis?.token || "");
  setAccessTokenTbtbc(chassis?.token || "");
};
