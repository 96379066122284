import { memoizeOnce } from "../../../shared/memoize";
import { getErrorMessage, useApexMethod } from "tbme/legacyApexBridge";
import {
  currentCommunityUser,
  pendingValue,
  profileCommunityUser,
  setUserStateFromApexResponse,
} from "./state";
import { ApexCommunityUsers } from "./types";
import { getContext } from "../../tbme/profilePage/context";
import { ProfileContext } from "elements/tbme/profilePage/types";

type CommunitySource = "tz" | "tbc2";
interface ApexCommunityUsersArgs {
  source: CommunitySource;
  profileUserId: string;
  isExternalUser: boolean;
}

let shouldFetchUsers: boolean = true;
let localUserRecords: { [k: string]: ApexCommunityUsers | undefined } = {
  tz: undefined,
  tbc2: undefined,
};

export const setShouldFetchUsers = (value: boolean) => {
  shouldFetchUsers = value;
};

export const setLocalUserRecords = (
  key: CommunitySource,
  value: ApexCommunityUsers | undefined
) => {
  localUserRecords = {
    ...localUserRecords,
    [key]: value,
  };
};

const getLocalUserRecords = (key: CommunitySource) => {
  if (!shouldFetchUsers) {
    return localUserRecords[key];
  }

  return undefined;
};

const getCommunityUsers = useApexMethod<
  ApexCommunityUsersArgs,
  ApexCommunityUsers
>("TrailblazerActivityController.getCommunityUsers");

const fetchUsersFromApexOrContext = (
  source: CommunitySource,
  profileUserId: string,
  isExternalUser: boolean = false
) => {
  const recordsFromContext = getLocalUserRecords(source);

  if (recordsFromContext) {
    return Promise.resolve(recordsFromContext);
  }

  return getCommunityUsers({ source, profileUserId, isExternalUser });
};

export const fetchTbcUsers = memoizeOnce(
  (profileUserId: string, isExternalUser: boolean = false) =>
    fetchUsersFromApexOrContext("tbc2", profileUserId, isExternalUser)
);

export const fetchTzUsers = memoizeOnce(
  (profileUserId: string, isExternalUser: boolean = false) =>
    fetchUsersFromApexOrContext("tz", profileUserId, isExternalUser)
);

export const fetchUsers = async (
  profileUserId: string,
  isExternalUser: boolean = false
) => {
  // Set pending states
  [profileCommunityUser, currentCommunityUser].map((fn) => fn(pendingValue));

  let response;

  try {
    response = await fetchTbcUsers(profileUserId, isExternalUser);

    setUserStateFromApexResponse(response);
  } catch (error) {
    [profileCommunityUser, currentCommunityUser].map((varFn) =>
      varFn({
        __typename: "CommunityAsyncResponse",
        error: getErrorMessage(error),
        isLoading: false,
      })
    );
  }

  return response;
};
