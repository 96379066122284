import { ProfileContext } from "./types";
import { trackedComponents } from "./componentTracker";
import {
  setChassisCurrentUserState,
  setLegacyCurrentUserState,
} from "./contexts/currentUser";
import { setAppLauncherState } from "./contexts/appLauncherItems";
import { setProfileUserState } from "./contexts/profileUser";
import { setLocaleState } from "./contexts/locale";
import { setLocaleOptionsState } from "./contexts/localeOptions";
import { setFooterLinksState } from "./contexts/footerLinks";
import { setAssetsState } from "./contexts/assets";
import {
  setLegacyBaseUrlState,
  setChassisBaseUrlState,
} from "./contexts/baseUrl";
import { makeVar } from "@apollo/client";
import { subscribeToVar } from "../reactiveVarAdapter/reactiveVarAdapter";
import { ReactiveListener } from "@apollo/client/cache/inmemory/reactiveVars";
import {
  setLegacyTrailheadUrlState,
  setChassisTrailheadUrlState,
} from "./contexts/trailheadUrlState";
import { setChassisState } from "./contexts/chassis";
import { isRenderProfileChassis } from "tbme/localState";

export { getFullName, getProfileUserId } from "./contexts/profileUser";

export const legacyContextVar = makeVar<ProfileContext>({});

export const subscribeToContextChange = (
  listener: ReactiveListener<ProfileContext>
) => subscribeToVar<ProfileContext>(legacyContextVar, listener);

Object.values(trackedComponents).map((tracker) =>
  subscribeToContextChange(tracker.onContextChange)
);
subscribeToContextChange(setStateFromContext);

export const setContext = (context: ProfileContext) => {
  return legacyContextVar(context);
};

export const getContext = () => {
  const value = legacyContextVar();

  if (Object.keys(value).length < 1) {
    throw new Error(
      "Legacy Page Context is not set. It must be set before it can be retrieved."
    );
  }

  return value;
};

/**
 * Primary entry point for one-time update of local state variables from the TBID id.page context.
 */
export function setStateFromContext(context: ProfileContext) {
  setAssetsState(context);
  setAppLauncherState(context);
  setLocaleState(context);
  setProfileUserState(context);
  setLocaleOptionsState(context);
  setFooterLinksState(context);

  if (isRenderProfileChassis()) {
    setChassisBaseUrlState();
    setChassisTrailheadUrlState();
    setChassisCurrentUserState(context);
    setChassisState(context);
  } else {
    setLegacyBaseUrlState(context);
    setLegacyTrailheadUrlState(context);
    setLegacyCurrentUserState(context);
  }
}
