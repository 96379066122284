import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
} from "@apollo/client";
import { MockedResponse, MockLink } from "@apollo/client/testing";

export const createMockClient = (
  mocks: MockedResponse[],
  addTypename: boolean = false
) => {
  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache({ addTypename: false }),
    link: new MockLink(mocks, addTypename),
  });
};
