import { html, render, TemplateResult } from "lit-html";
import { ChassisContext, ProfileContext } from "./types";
import { setContext, getFullName as getFullNameLegacy } from "./context";
import { getFullName } from "./contexts/chassis";
import { setupAura } from "../../../shared/aura";
import { auraComponent } from "../../../shared/lit/auraComponent";
import { getBridgeComponent, setBridgeComponent } from "tbme/legacyApexBridge";

import { set } from "@devforce/trailhead-client-env";
import {
  renderAboutMe,
  renderAppExchange,
  renderCertifications as renderLegacyCertifications,
  renderMVPComponent,
  renderTrackedComponent,
  renderUserDetails as renderLegacyUserDetails,
} from "./legacyComponents";
import { trackedComponents } from "./componentTracker";
import {
  handleAuraAvatarUpload,
  handleAuraContextChange,
  handleAuraToast,
  handleOnCoreLwcToast,
} from "./legacyEvents";
import { trailheadUrl, isRenderProfileChassis } from "tbme/localState";
import { isTbmeSkillsComponentEnabled } from "../../../shared/feature";

const experienceApiBffUrl =
  window?.sfdcBase?.exp?.EXPERIENCE_API_BFF_URL ||
  "https://mobile.api.trailhead.com/graphql";

set("GRAPHQL_API_HOST", experienceApiBffUrl);

export const mediaQueryMobile = window.matchMedia("(max-width: 760px)");

/**
 * The primary profile render function. Accepts the `attrs` object from the Visualforce
 * page (ProfileContext) and renders the responsive layout.
 * @param context attrs object from VF
 * @param element element to render into
 */
export function renderProfile(context: ProfileContext, element: HTMLElement) {
  setContext(context);

  if (!context.$lightning) {
    throw new Error("$lightning is required to render profile.");
  }

  setupAura(context.$lightning, "c:ProfileApp")
    .ready()
    .then(({ $A }) => {
      // Aura components emit the c:ToastEvent "Application Event"
      $A.eventService.addHandler({
        event: "c:ToastEvent",
        handler: handleAuraToast,
      });

      // Update avatarUrl when user changes avatar
      $A.eventService.addHandler({
        event: "c:AvatarUploadEvent",
        handler: handleAuraAvatarUpload,
      });

      $A.eventService.addHandler({
        event: "c:ProfileEditEvent",
        handler: (event: any) => {
          trackedComponents.profileEdit.open();
        },
      });

      $A.eventService.addHandler({
        event: "c:ContextChangeEvent",
        handler: handleAuraContextChange,
      });
    });

  // On-core LWC components emit this event for toasts
  element.addEventListener("toast", handleOnCoreLwcToast);

  // Allow on-core LWC components to open profile edit modal
  element.addEventListener("displayeditprofile", () =>
    trackedComponents.profileEdit.open()
  );

  render(renderContainer(context), element);

  const profile = renderProfileSections(
    element,
    context,
    mediaQueryMobile.matches
  );

  mediaQueryMobile.addEventListener("change", (e: MediaQueryListEvent) =>
    renderProfileSections(element, context, e.matches)
  );
}

/**
 * The primary profile render function when running on Digital App Chassis.
 * Accepts context (ProfileContext) from the /id page and renders the responsive layout.
 * @param context context object from /id page
 * @param element element to render into
 */
export function renderProfileChassis(
  context: ProfileContext,
  element: HTMLElement
) {
  isRenderProfileChassis(true);
  setContext(context);

  render(renderContainerChassis(context), element);

  const profile = renderProfileSectionsChassis(
    element,
    context,
    mediaQueryMobile.matches
  );

  mediaQueryMobile.addEventListener("change", (e: MediaQueryListEvent) =>
    renderProfileSectionsChassis(element, context, e.matches)
  );
}

/**
 * This is the primary function handling the responsive behavior. This inserts
 * content into a named div rendered by `renderContainer` with the id
 * `profile-sections-container`. This means that the LightningOut components
 * are entirely rerendered.
 */
export const renderProfileSections = (
  element: HTMLElement,
  context: ProfileContext,
  isMobile: boolean
) => {
  const container = element.querySelector("#profile-sections-container");

  if (!container) {
    throw new Error("Sections container not present.");
  }

  const renderContent =
    context.profileUser!.Is_Public_Profile__c || context.isSelf
      ? renderPublicProfile.bind(null, context, isMobile)
      : renderPrivateProfile.bind(null, context);

  return render(renderContent(), container);
};

/**
 * This is the primary function handling the responsive behavior. This inserts
 * content into a named div rendered by `renderContainer` with the id
 * `profile-sections-container`. This method is to be used when rendering
 * in the Digital App Chassis
 */
export const renderProfileSectionsChassis = (
  element: HTMLElement,
  context: ProfileContext,
  isMobile: boolean
) => {
  const container = element.querySelector("#profile-sections-container");

  if (!container) {
    throw new Error("Sections container not present.");
  }

  const renderContent =
    context?.chassis?.profile?.isPublicProfile || context?.chassis?.isSelf
      ? renderPublicProfileChassis.bind(null, context, isMobile)
      : renderPrivateProfileChassis.bind(null, context);

  return render(renderContent(), container);
};

/**
 * Render the primary layout container for the profile.
 */
export const renderContainer = (context: ProfileContext) =>
  html`
    <style>
      #auraErrorMessage {
        display: none;
      }
    </style>

    <tds-theme-provider>
      <tbme-client-provider>
        <tbui-page-layout>
          <tbme-profile-metadata> </tbme-profile-metadata>

          <tbme-header slot="header"></tbme-header>

          ${auraComponent("c:trailheadUrlService", {
            trailheadUrl: trailheadUrl(),
            locale: context.thLanguage,
          })}
          ${auraComponent(
            "c:thApexBridge",
            {},
            {
              onRender: (component) => {
                !getBridgeComponent() && setBridgeComponent(component);
              },
            }
          )}

          <!-- Profile Edit -->
          ${context.isSelf
            ? renderTrackedComponent(
                trackedComponents.profileEdit,
                "c:ProfileEdit",
                ({
                  profileUser,
                  profilePhotoUrl,
                  profileUserBgImageUrl,
                  pickLists,
                }: ProfileContext) => ({
                  profileUser: profileUser,
                  profilePhotoUrl: profilePhotoUrl,
                  profileUserBgImageUrl: profileUserBgImageUrl,
                  pickLists: pickLists,
                })
              )
            : ""}
          ${context.isSelf
            ? renderTrackedComponent(
                trackedComponents.profileUpdater,
                "c:ProfileUpdater",
                ({ cuid }: ProfileContext) => ({
                  cuid,
                })
              )
            : ""}

          <tbui-profile-layout
            id="profile-sections-container"
            layout="AllSlots"
            background-image-url=${context.profileUserBgImageUrl}
          >
          </tbui-profile-layout>

          <tbme-footer slot="footer"> </tbme-footer>
        </tbui-page-layout>
      </tbme-client-provider>
    </tds-theme-provider>
  `;

/**
 * Render the primary layout container for the profile (when rendered in the Digital App Chassis)
 */
export const renderContainerChassis = (context: ProfileContext) =>
  html`
    <tds-theme-provider>
      <tbme-client-provider>
        <tbtbc-client-provider>
          <tbui-page-layout>
            <tbme-chassis-profile-metadata> </tbme-chassis-profile-metadata>

            <!-- TODO: Uncomment header -->
            <!-- <tbme-header slot="header"></tbme-header> -->

            <tbui-profile-layout
              id="profile-sections-container"
              layout="AllSlots"
              background-image-url=${context.chassis?.profile
                ?.backgroundImageUrl}
            >
            </tbui-profile-layout>

            <wes-footer
              slot="footer"
              locale-page-reload="true"
              selected-language=${context.languageParam}
            >
            </wes-footer>
          </tbui-page-layout>
        </tbtbc-client-provider>
      </tbme-client-provider>
    </tds-theme-provider>
  `;

/**
 * Utility for rendering a list of render functions that accept `context`
 */
export const renderList = (list: Array<() => TemplateResult | string>) =>
  list.map((fn) => html`${fn()}`);

interface PageSections {
  mobile: (() => TemplateResult)[];
  desktopLeft: (() => TemplateResult)[];
  desktopRight: (() => TemplateResult)[];
}

const renderSections = (
  { mobile, desktopLeft, desktopRight }: PageSections,
  isMobile: boolean
) => {
  return isMobile
    ? html`<div slot="mobile">${mobile.map((fn) => html`${fn()}`)}</div>`
    : html`
        <div slot="desktop-left">${desktopLeft.map((fn) => html`${fn()}`)}</div>
        <div slot="desktop-right">
          ${desktopRight.map((fn) => html`${fn()}`)}
        </div>
      `;
};

/**
 * Render notification for public profiles.
 */
function renderPublicProfileNotification(context: ProfileContext) {
  if (!context.profileUser!.Is_Public_Profile__c) {
    return html`<tbui-private-profile-banner
      slot="notification"
      .isSelf=${context.isSelf}
    ></tbui-private-profile-banner>`;
  }

  return process.env.FEATURE_HIRE_ME === "true" &&
    context.isSelf &&
    !context.isAvailableForHire &&
    context.isCommunityUser
    ? html`<tbui-hire-me-banner
        slot="notification"
        .userId=${context.profileUser!.Id}
      ></tbui-hire-me-banner>`
    : html``;
}

/**
 * Render notification for public profiles.
 */
function renderPublicProfileNotificationChassis(context?: ChassisContext) {
  if (!context?.profile?.isPublicProfile) {
    return html`<tbui-private-profile-banner
      slot="notification"
      .isSelf=${context?.isSelf}
    ></tbui-private-profile-banner>`;
  }

  return process.env.FEATURE_HIRE_ME === "true" &&
    context.isSelf &&
    !context.profile.isAvailableForHire
    ? html`<tbui-hire-me-banner
        slot="notification"
        .userId=${context.profile.id}
      ></tbui-hire-me-banner>`
    : html``;
}

/**
 * Fills the slots in `tbui-profile-layout`
 */
export const renderPublicProfile = (
  context: ProfileContext,
  isMobile: boolean
) => {
  const notification = renderPublicProfileNotification(context);
  const { isExtProfileUser } = context;

  const renderRank = () => html`<tbme-rank></tbme-rank>`;

  const renderCommunityTags = () =>
    process.env.FEATURE_COMMUNITY_TAGS === "true"
      ? html`<tbme-community-tags></tbme-community-tags>`
      : html``;

  const renderBadges = () => html`<tbme-profile-badges></tbme-profile-badges>`;

  const renderSkills = () =>
    isTbmeSkillsComponentEnabled() ? html`<tbme-skills></tbme-skills>` : html``;

  const renderCertifications = () =>
    process.env.FEATURE_NEW_CERTIFICATIONS_COMPONENT === "true"
      ? html`<tbme-certifications></tbme-certifications>`
      : html`${renderLegacyCertifications()}`;

  const renderSuperbadges = () => html`<tbme-superbadges></tbme-superbadges>`;

  const renderQuestions = () =>
    html`<tbtbc-questions-and-answers></tbtbc-questions-and-answers>`;

  const renderConnections = () => html`<tbtbc-connections></tbtbc-connections>`;

  const renderFiles = () => html`<tbtbc-files></tbtbc-files>`;

  const renderUserDetails = () =>
    process.env.FEATURE_NEW_USER_DETAILS_COMPONENT === "true"
      ? html`<tbme-user-details></tbme-user-details>`
      : html`${renderLegacyUserDetails()}`;

  const externalUserProfileSections: PageSections = {
    mobile: [renderAboutMe, renderQuestions, renderConnections, renderFiles],
    desktopLeft: [renderAboutMe],
    desktopRight: [renderQuestions, renderConnections, renderFiles],
  };

  const publicProfileSections: PageSections = {
    mobile: [
      renderAboutMe,
      renderMVPComponent,
      renderCertifications,
      renderSuperbadges,
      renderRank,
      renderCommunityTags,
      renderQuestions,
      renderConnections,
      renderAppExchange,
      renderSkills,
      renderBadges,
      renderUserDetails,
      renderFiles,
    ],
    desktopLeft: [
      renderAboutMe,
      renderCertifications,
      renderSuperbadges,
      renderSkills,
      renderBadges,
      renderUserDetails,
    ],
    desktopRight: [
      renderMVPComponent,
      renderRank,
      renderCommunityTags,
      renderQuestions,
      renderConnections,
      renderAppExchange,
      renderFiles,
    ],
  };

  return html`${notification}${renderSections(
    isExtProfileUser ? externalUserProfileSections : publicProfileSections,
    isMobile
  )}`;
};

/**
 * Fills the slots in `tbui-profile-layout`
 * To be used when rendering profile in the Digital App Chassis
 * Should not include any components that are hosted in the TBID
 * (./legacyComponents) or use the Apex data bridge to get data from TBID
 */
export const renderPublicProfileChassis = (
  context: ProfileContext,
  isMobile: boolean
) => {
  const notification = renderPublicProfileNotificationChassis(context.chassis);

  const useChassisQuery = true;
  const renderUserDetails = () => html`<tbme-user-details></tbme-user-details>`;
  const renderAboutMyself = () => html`<tbme-about-myself></tbme-about-myself>`;
  const renderSuperbadges = () => html`<tbme-superbadges></tbme-superbadges>`;
  const renderRank = () => html`<tbme-rank></tbme-rank>`;
  const renderSkills = () =>
    isTbmeSkillsComponentEnabled() ? html`<tbme-skills></tbme-skills>` : html``;
  const renderBadges = () => html`<tbme-profile-badges></tbme-profile-badges>`;
  const renderCommunityTags = () =>
    html`<tbme-community-tags></tbme-community-tags>`;
  const renderCertifications = () =>
    html`<tbme-certifications></tbme-certifications>`;
  const renderMVPComponent = () => html`<tbme-mvp></tbme-mvp>`;
  const renderFiles = () => html`<tbme-files></tbme-files>`;
  const renderQuestions = () =>
    context.chassis?.profile.lastName
      ? html`<tbme-questions-and-answers></tbme-questions-and-answers>`
      : html``;
  const renderConnections = () =>
    context.chassis?.profile.lastName
      ? html`<tbme-connections></tbme-connections>`
      : html``;
  const renderStamps = () =>
    window?.sfdcBase?.exp?.TRAILHEAD_COMPONENTS_BASE_URL &&
    window?.sfdcBase?.exp?.FEATURE_STAMPS === "true"
      ? html`<th-stamp-collection
          slug="${context.chassis?.profile.username}"
        ></th-stamp-collection>`
      : html``;

  const publicProfileSections: PageSections = {
    mobile: [
      renderAboutMyself,
      renderMVPComponent,
      renderCertifications,
      renderSuperbadges,
      renderRank,
      renderCommunityTags,
      renderQuestions,
      renderConnections,
      renderSkills,
      renderBadges,
      renderStamps,
      renderUserDetails,
      renderFiles,
    ],
    desktopLeft: [
      renderAboutMyself,
      renderCertifications,
      renderSuperbadges,
      renderSkills,
      renderBadges,
      renderStamps,
      renderUserDetails,
    ],
    desktopRight: [
      renderMVPComponent,
      renderRank,
      renderCommunityTags,
      renderQuestions,
      renderConnections,
      renderFiles,
    ],
  };
  return html`${notification}${renderSections(publicProfileSections, isMobile)}`;
};

/**
 * Used when viewing someone else's private profile.
 */
function renderPrivateProfile({
  profileUser,
  profilePhotoUrl,
}: ProfileContext) {
  return html` <tbui-private-profile-banner
      .isSelf=${false}
      slot="notification"
    ></tbui-private-profile-banner>
    <tbui-private-profile
      full-name="${getFullNameLegacy({ profileUser })}"
      avatar-url="${profilePhotoUrl}"
    ></tbui-private-profile>`;
}

//TODO: adapt getFullName() to be used in renderPrivateProfileChassis()

/**
 * Used when viewing someone else's private profile.
 * In the context of digital-profile chassis
 */
function renderPrivateProfileChassis({ chassis }: ProfileContext) {
  return html` <tbui-private-profile-banner
      .isSelf=${false}
      slot="notification"
    ></tbui-private-profile-banner>
    <tbui-private-profile
      full-name="${getFullName(chassis?.profile)}"
      avatar-url="${chassis?.profile?.photoUrl || ""}"
    ></tbui-private-profile>`;
}
