import {
  getContext,
  subscribeToContextChange,
} from "../../elements/tbme/profilePage/context";
import { directive, html, Part, TemplateResult } from "lit-html";
import { ProfileContext } from "../../elements/tbme/profilePage/types";

export const withContext = directive(
  (fn: (c: ProfileContext) => TemplateResult) => (part: Part) => {
    let previous: TemplateResult;

    const update = (context: ProfileContext) => {
      let newValue = fn(context);

      if (previous === newValue) {
        return;
      }

      previous = newValue;
      part.setValue(newValue);
      part.commit();
    };
    subscribeToContextChange((context: ProfileContext) => {
      update(context);
    });

    update(getContext());
  }
);
