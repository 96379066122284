import deepEqual from "fast-deep-equal";

export type EqualityFn = (newArgs: any[], lastArgs: any[]) => boolean;

export interface CachedFunction<R extends () => void> {
  resetCache: () => void;
  (this: any, ...newArgs: any[]): ReturnType<R>;
}

/**
 * Based on memoize-one. Caches a function call once, resets on new args. This is useful for most simple caching needs.
 * The cache is reset each time the args used are changed.
 *
 * Example:
 * ```ts
 * let count = 0;
 * const expensiveMethod = memoizeOnce((name: string) => {
 *   count++;
 *   return `called: ${count}`
 * })
 *
 * expensiveMethod("Frank"); // "called: 1"
 * expensiveMethod("Frank"); // "called: 1"
 * expensiveMethod("George"); // "called: 2"
 * expensiveMethod("George"); "called: 2"
 *
 * expensiveMethod("Frank"); // "called: 3"
 * expensiveMethod.resetCache();
 * expensiveMethod("Frank"); // "called: 4"
 * ```
 */
export function memoizeOnce<
  ResultFn extends (this: any, ...newArgs: any[]) => ReturnType<ResultFn>
>(
  resultFn: ResultFn,
  isEqual: EqualityFn = deepEqual
): CachedFunction<ResultFn> {
  let lastThis: unknown;
  let lastArgs: unknown[] = [];
  let lastResult: ReturnType<ResultFn>;
  let calledOnce: boolean = false;

  function reset() {
    calledOnce = false;
  }

  function memoized(
    this: unknown,
    ...newArgs: unknown[]
  ): ReturnType<ResultFn> {
    if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
      return lastResult;
    }

    lastResult = resultFn.apply(this, newArgs);
    calledOnce = true;
    lastThis = this;
    lastArgs = newArgs;
    return lastResult;
  }

  memoized.resetCache = reset;

  return memoized;
}
