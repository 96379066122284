import { LightningElement, api } from "lwc";
import { t } from "tbme/localization";
import { locale } from "tbme/localState";

export default class extends LightningElement {
  @api imageUrl: string = "";
  @api itemUrl: string = "";
  @api completedDate: string | number | Date = "";
  @api linkContent: string = "";
  @api itemDescription: string = "";

  get completedDateString() {
    const date = new Date(this.completedDate);
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    const localeDateString = date.toLocaleDateString(
      locale().replace("_", "-"),
      dateFormatOptions
    );

    return t("superbadges.completedDate", {
      localeDateString,
    });
  }
}
