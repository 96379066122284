import { Part, directive, html } from "lit-html";
import {
  AuraComponent,
  AuraComponentAttrs,
  AuraDirectiveOptions,
  getAura,
} from "../aura";

interface AuraDirectiveStatus {
  component?: AuraComponent;
  pending?: boolean;
  id: string;
  container?: HTMLElement;
}

const componentIds: string[] = [];
const directiveCache = new WeakMap<Part, AuraDirectiveStatus>();

/**
 * Lit-html directive for rendering component from LightningOut.
 *
 * @param componentRef the name of the component to render
 * @param attrs component attributes
 * @param placeholder lit-html template to use a placeholder until the component is loaded
 */
export const auraComponent = directive(
  (
    componentRef: string,
    attrs: AuraComponentAttrs,
    options?: AuraDirectiveOptions
  ) => (part: Part) => {
    const getState = () => directiveCache.get(part);
    const setState = (newState: AuraDirectiveStatus) =>
      directiveCache.set(part, newState);

    const { onRender, placeholder } = { ...options };

    if (!getState()) {
      const newId = `aura-directive-id-${componentIds.length}`;
      componentIds.push(newId);

      setState({
        id: newId,
      });

      part.setValue(
        html`<div id="${newId}">
          ${placeholder
            ? html`<div id="${newId}-placeholder">${placeholder}</div>`
            : ""}
        </div>`
      );
      part.commit();
    }

    if (getState()!.pending) {
      return;
    }

    directiveCache.set(part, {
      ...getState()!,
      pending: true,
    });

    // Ensure createComponent is executed after an in-progress render
    Promise.resolve().then(() => {
      const state = getState();

      if (!state) {
        return;
      }

      const container = document!.getElementById(state.id);

      if (!container) {
        return;
      }

      directiveCache.set(part, {
        ...state,
        container,
      });

      getAura()
        .createComponent(componentRef, attrs, state.id)
        .then((component) => {
          const state = getState();

          if (!state) {
            return;
          }

          directiveCache.set(part, {
            ...state,
            pending: false,
          });

          // Disable the default SLDS that is loaded in app.css
          // by removing the "slds-scope" class
          if (container) {
            container.className = "";

            let placeholderEl = container.querySelector(
              `#${state.id}-placeholder`
            );

            if (placeholderEl) {
              container.removeChild(placeholderEl);
            }
          }

          if (onRender) {
            onRender(component);
          }
        });
    });
  }
);
