import { gql } from "@apollo/client";

export const Q_AND_A_QUERY = gql`
  query GetQuestionsAndAnswers {
    profileUser @client {
      __typename
      ... on PublicProfile {
        isSelf

        communityUser @client {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
          }
          ... on CommunityUser {
            id
          }
        }

        communityStats {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
            error
          }
          ... on CommunityStats {
            answersCount
            bestAnswersCount
            questionsCount
            communityUserRank
          }
        }
      }
    }

    siteInfo @client {
      urls {
        tbcCommunityFeed
        tbcUserFeed
        tbcUserFeedQuestions
        tbcUserFeedAnswers
        tbcUserFeedBestAnswers
      }
    }
  }
`;
