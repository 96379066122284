import { gql } from "@apollo/client";

export const USER_DETAILS_QUERY = gql`
  query GetUserDetails {
    chassis @client {
      profile {
        pronoun
        role
        relationshipToSalesforce
        company {
          name
          size
          website
        }
        address {
          country
          state
        }
      }
      isSelf
    }
  }
`;
