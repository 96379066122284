import { directive, html, Part, TemplateResult } from "lit-html";

export interface AuraComponent {
  helper?: any;
  set(attrRef: string, value: any): void;
  get(attrRef: string): any;
}

export interface LightningOutUtility {
  use: (appRef: string, readyCallback: () => void) => void;
  $A?: any;
  createComponent: <T = any>(
    componentRef: string,
    attrs: T,
    elementId: string,
    callback: (component: AuraComponent) => void
  ) => void;
}

export interface AuraDirectiveOptions {
  placeholder?: TemplateResult;
  onRender?: (component: AuraComponent) => void;
}

export interface AuraComponentAttrs {
  [key: string]: any;
}

let auraService: AuraService;

export const setupAura = (lightning: LightningOutUtility, appRef: string) => {
  auraService = new AuraService(lightning, appRef);

  return auraService;
};

export const getAura = () => {
  return auraService;
};
export class AuraService {
  public $A: any;

  private lightningOut: LightningOutUtility;
  private lightningIsReady = false;
  private pendingCallbacks: (() => void)[] = [];

  constructor(lightningOut: LightningOutUtility, appRef: string) {
    this.lightningOut = lightningOut;
    this.loadAura(appRef);
  }

  private loadAura(appRef: string) {
    this.lightningOut.use(appRef, this.lightningReadyCallback.bind(this));
  }

  private patch() {
    /**
     * When aura loads a custom property, `$shadowResolver$`, is added to the Node prototype
     * with a custom setter that expects to receive a callable with a custom attribute
     * `nodeType`. However, the LWC engine also writes to the same property but sometimes
     * writes `undefined`. This creates an error when LWC attempts to render a new element
     * after Aura has loaded on the page. This patch prevents the Aura setter from being
     * called if the value is undefined.
     */
    const oldDef = Object.getOwnPropertyDescriptor(
      Node.prototype,
      "$shadowResolver$"
    );

    if (oldDef) {
      Object.defineProperty(Node.prototype, "$shadowResolver$", {
        ...oldDef,
        set(v: any) {
          if (v) {
            // @ts-ignore
            oldDef.set.call(this, v);
          }
        },
      });
    }
  }

  private lightningReadyCallback() {
    this.patch();

    // @ts-ignore
    if (window.$A) {
      // @ts-ignore
      this.$A = window.$A;
    }

    this.resolvePendingCallbacks();
  }

  private resolvePendingCallbacks() {
    while (this.pendingCallbacks.length > 0) {
      let cb = this.pendingCallbacks.shift();
      cb && cb();
    }

    this.lightningIsReady = true;
  }

  /**
   * Promise resolves after LightningOut is ready. Useful for access `$A` after it is
   * available.
   */
  async ready() {
    let resolver: (value?: AuraService) => void;

    const promise = new Promise<AuraService>((resolve) => {
      resolver = resolve;
    });

    const callback = () => {
      resolver(this);
    };

    if (!this.lightningIsReady) {
      this.pendingCallbacks.push(callback);
    } else {
      callback();
    }

    return promise;
  }

  async createComponent<T>(
    componentRef: string,
    options: T,
    elementId: string
  ): Promise<AuraComponent> {
    let resolver: (value?: AuraComponent) => void;

    const promise = new Promise<AuraComponent>((resolve) => {
      resolver = resolve;
    });

    const callback = () => {
      this.lightningOut.createComponent<T>(
        componentRef,
        options,
        elementId,
        (component: AuraComponent) => {
          resolver(component);
        }
      );
    };

    if (!this.lightningIsReady) {
      this.pendingCallbacks.push(callback);
    } else {
      callback();
    }

    return promise;
  }
}
