import { LightningElement, api } from "lwc";
import { locale } from "tbme/localState";

type variants = "brand" | "success" | "neutral";

export default class LwcTally extends LightningElement {
  @api variant: variants = "neutral";

  @api href: string = undefined!;
  @api target: string = "_self";

  @api count!: number;
  @api label!: string;

  get classnamesForCount() {
    return `tally__count ${this.variant ? `tally__count_${this.variant}` : ""}`;
  }

  private get localizedCount() {
    return Number(this.count).toLocaleString(locale());
  }
}
