import { ApolloQueryResult, gql } from "@apollo/client";
import { LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { fetchCommunityProfile } from "../communityData/communityData";
import { getError, getIsLoading, getUserId } from "../communityData/queryUtils";
import {
  GetFilesContext,
  GetFilesContext_profileUser_PublicProfile,
} from "./gql/GetFilesContext";

export const FILES_QUERY = gql`
  query GetFilesContext {
    profileUser @client {
      __typename
      ... on PublicProfile {
        __typename
        communityUser {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
            error
          }
          ... on CommunityUser {
            id
          }
        }
      }
    }

    siteInfo @client {
      urls {
        tbcUserFiles
      }
    }
  }
`;

export default class extends LightningElement {
  private visible: boolean = false;
  private filesUrl: string = "";

  @wire(MultiLabelAdapter, {
    labels: ["filesTitle", "viewFilesButton"],
  })
  private labels: LabelTranslations = undefined!;

  connectedCallback() {
    fetchCommunityProfile();
  }

  @wire(QueryAdapter, {
    query: FILES_QUERY,
  })
  private handleChange({ data }: ApolloQueryResult<GetFilesContext>) {
    if (!data) {
      return;
    }

    if (data.profileUser && data.profileUser.__typename === "PrivateProfile") {
      return;
    }

    const profileUser = data.profileUser as GetFilesContext_profileUser_PublicProfile;
    const { communityUser } = profileUser;

    if (getIsLoading(communityUser) || getError(communityUser)) {
      this.visible = false;
      return;
    }

    this.visible = !!getUserId(communityUser);
    this.filesUrl = data.siteInfo.urls.tbcUserFiles;
  }
}
