import {
  WatchQueryOptions,
  ApolloQueryResult,
  ObservableQuery,
  ApolloClient,
  NetworkStatus,
  OperationVariables,
  FetchMoreQueryOptions,
} from "@apollo/client";
import { WireAdapter } from "lwc";
import isEqual from "lodash/isEqual";

import {
  adapterContextSchema,
  ClientAdapterContext,
} from "tbtbc/clientAdapter";
import { logger } from "tbme/logger";
import {Subscription} from "zen-observable-ts";

export interface QueryAdapterValue<TData, TVariables = OperationVariables>
  extends ApolloQueryResult<TData> {
  fetchMore<TFetchData = TData, TFetchVars extends OperationVariables = TVariables>(fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
    updateQuery?: (previousQueryResult: TData, options: {
      fetchMoreResult: TFetchData;
      variables: TFetchVars;
    }) => TData;
  }): Promise<ApolloQueryResult<TFetchData>>;
  refetch(variables?: Partial<TVariables>): Promise<ApolloQueryResult<TData>>;
}
export class QueryAdapter<TData, TVariables>
  implements
    WireAdapter<
      QueryAdapterValue<TData, TVariables>,
      WatchQueryOptions<TVariables>,
      ClientAdapterContext
    > {
  static contextSchema = adapterContextSchema;
  observable?: ObservableQuery<TData, TVariables>;
  subscription?: Subscription;
  client?: ApolloClient<any>;
  private defaultData: ApolloQueryResult<TData> = {
    loading: true,
    networkStatus: NetworkStatus.loading,
  } as QueryAdapterValue<TData, TVariables>;
  private previousData: ApolloQueryResult<TData> = { ...this.defaultData };

  constructor(
    public setValue: (value: QueryAdapterValue<TData, TVariables>) => void
  ) {}

  makeQuery(config: WatchQueryOptions<TVariables>) {
    if (!this.observable) {
      this.observable = this.client!.watchQuery<TData, TVariables>(config);

      this.setValue(this.getValue());

      this.subscription = this.observable.subscribe(
        (result) => {
          this.previousData = result;
          this.setValue(this.getValue());
        },
        (error) => {
          this.previousData = {
            data: (this.previousData.data || {}) as TData,
            loading: false,
            networkStatus: NetworkStatus.error,
            error,
          };

          this.setValue(this.getValue());
        }
      );
    } else if (
      this.observable &&
      config.variables &&
      !isEqual(config.variables, this.observable.variables)
    ) {
      this.resetData();
      this.disconnect();
      this.makeQuery(config);
    }
  }

  private resetData() {
    this.previousData = { ...this.defaultData };
  }

  private reset() {
    this.disconnect();
    this.client = undefined;
  }

  private getValue(): QueryAdapterValue<TData, TVariables> {
    return {
      ...this.previousData,
      fetchMore: this.observable!.fetchMore.bind(this.observable),
      refetch: this.observable!.refetch.bind(this.observable),
    };
  }

  update(
    config: WatchQueryOptions<TVariables>,
    context?: ClientAdapterContext
  ) {
    if (context && context.client) {
      if (this.client !== context.client) {
        this.reset();
      }

      this.client = context.client;

      this.makeQuery(config);
    } else {
      return;
    }
  }
  connect() {
    if (!this.client) {
      logger.error(
        `Did you forget to wrap your component in a provider such as <tbtbc-client-provider>`
      );
    }
  }
  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.observable = undefined;
    }
  }
}
