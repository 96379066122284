import { AuraComponent, AuraComponentAttrs } from "shared/aura";
import { ProfileContext } from "./types";

/**
 * Creates a service for collecting Aura component changes before LightningOut has loaded,
 * then applying the changes after the component has loaded. After LightningOut is available
 * the set method directly applies changes to the component.
 */
export class ComponentTracker {
  private component?: AuraComponent;
  private trackedChanges = new Map<string, any>();
  private contextSelector?: (context: ProfileContext) => AuraComponentAttrs;

  set(key: string, value: any) {
    this.updateValue(key, value);
  }

  get<T>(key: string): T | undefined {
    return this.component ? this.component.get(key) : undefined;
  }

  apply(attrs: AuraComponentAttrs) {
    for (let [key, value] of Object.entries(attrs)) {
      key = key.startsWith("v.") ? key : `v.${key}`;
      this.set(key, value);
    }
  }

  setComponent(value: AuraComponent) {
    this.component = value;

    this.commitPendingChanges();
  }

  getComponent() {
    return this.component;
  }

  setSelector(selector: (context: ProfileContext) => AuraComponentAttrs) {
    this.contextSelector = selector;
  }

  updateValue(key: string, value: any) {
    this.trackedChanges.set(key, value);
    this.component && this.component.set(key, value);
  }

  onContextChange = (context: ProfileContext) => {
    if (!this.contextSelector) return;

    this.apply(this.contextSelector(context));
  };

  private commitPendingChanges() {
    if (!this.component) {
      throw new Error("Component is not set.");
    }

    for (let [key, value] of this.trackedChanges.entries()) {
      this.component.set(key, value);
    }
  }
}

export class ProfileEditModal extends ComponentTracker {
  open() {
    const component = this.getComponent();

    if (
      component &&
      component.helper &&
      component.helper.initializeProfileEdit
    ) {
      component.helper.initializeProfileEdit(component);
    }

    this.updateValue("v.showProfileEditModal", true);
  }
  close() {
    this.updateValue("v.showProfileEditModal", false);
  }
}

/**
 * Named aura component tracker instances used for applying state changes
 * after the initial load.
 */
export const trackedComponents = {
  userDetails: new ComponentTracker(),
  aboutMeSocialLinks: new ComponentTracker(),
  aboutMeAvatar: new ComponentTracker(),
  profileEdit: new ProfileEditModal(),
  achievements: new ComponentTracker(),
  skills: new ComponentTracker(),
  badges: new ComponentTracker(),
  recognitions: new ComponentTracker(),
  appExchange: new ComponentTracker(),
  developerMarketplace: new ComponentTracker(),
  mvp: new ComponentTracker(),
  rank: new ComponentTracker(),
  profileUpdater: new ComponentTracker(),
  certifications: new ComponentTracker(),
};
