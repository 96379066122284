import { localeOptions } from "tbme/localState";
import { ProfileContext } from "../types";
import { convertLocale } from "./locale";

/**
 * Set localeOptions var from VF context.
 */
export const setLocaleOptionsState = ({ languageOptions }: ProfileContext) => {
  if (!languageOptions) return;

  if (Array.isArray(languageOptions)) {
    localeOptions(
      languageOptions.map((languageOption) => ({
        ...languageOption,
        value: convertLocale(languageOption.value),
      }))
    );
  }
};
