import { LightningElement, api } from "lwc";

export default class extends LightningElement {
  @api isSelf: boolean = false;
  @api imageUrl: string = undefined!;
  @api itemUrl: string = undefined!;
  @api linkContent: string = undefined!;
  @api itemDescription: string = undefined!;

  handleClick() {
    this.dispatchEvent(
      new CustomEvent("linkclick", {
        bubbles: true,
        composed: true,
      })
    );
  }
}
