import { gql } from "@apollo/client";

export const USER_CONTEXT_QUERY = gql`
  query GetQAndAUserContext {
    chassis @client {
      profile {
        isPublicProfile
        username
      }
      isSelf
    }
  }
`;

export const Q_AND_A_QUERY = gql`
  query GetQuestionsAndAnswersStats(
    $userSlug: ID!
    $queryQuestionsAndAnswers: Boolean!
  ) {
    profileData(userSlug: $userSlug) @include(if: $queryQuestionsAndAnswers) {
      communityUserId
      questionAndAnswersStats {
        answersCount
        bestAnswersCount
        communityUserRank
        questionsCount
      }
      communityUserUrls {
        answersUrl
        bestAnswersUrl
        feedUrl
        questionsUrl
      }
    }
  }
`;
