import { FieldFunctionOptions, FieldPolicy } from "@apollo/client";
import {
  getCurrentCommunityUserId,
  getProfileCommunityUserId,
} from "../../tbtbc/communityData/state";
import {
  assetsUrl,
  baseUrl,
  currentUser,
  locale,
  profileUser,
  trailheadUrl,
} from "./localState";
import { trailheadLocaleFor } from "./trailheadLocale";

interface UrlParamValues {
  [k: string]: string | number | boolean | null;
}

interface UrlPatternParam {
  key: string;
  placeholder: string;
}

export interface UrlFormatter {
  (args?: UrlParamValues): string;
  pattern: string;
}

export const sharedUrls = {
  base: parametizedUrl(process.env.TBME_BASE_URL!),
  home: parametizedUrl(process.env.TBME_HOME_URL!),
  profile: parametizedUrl(process.env.TBME_PROFILE_URL!),
  settings: parametizedUrl(process.env.TBME_SETTINGS_URL!),
  availableForHire: parametizedUrl(process.env.TBME_AVAILABLE_FOR_HIRE_URL!),
  ...(window.sfdcBase?.exp || process.env.USE_DIGITAL_PROFILE_URLS === "true"
    ? {
        base: parametizedUrl(process.env.DIGITAL_PROFILE_BASE_URL!),
        home: parametizedUrl(process.env.DIGITAL_PROFILE_HOME_URL!),
        profile: parametizedUrl(process.env.DIGITAL_PROFILE_PROFILE_URL!),
        settings: parametizedUrl(process.env.DIGITAL_PROFILE_SETTINGS_URL!),
        availableForHire: parametizedUrl(
          process.env.DIGITAL_PROFILE_AVAILABLE_FOR_HIRE_URL!
        ),
      }
    : {}),
  assets: parametizedUrl("{{assetsUrl}}"),
  logo: parametizedUrl(process.env.TBME_LOGO_URL!),
  bannerBackgroundImage: parametizedUrl(process.env.BANNER_BACKGROUND_URL!),
  astroImage: parametizedUrl(process.env.ASTRO_IMAGE_URL!),
  defaultAstroImage: parametizedUrl(process.env.DEFAULT_ASTRO_IMAGE_URL!),
  mvpImage: parametizedUrl(process.env.MVP_IMAGE_URL!),
  noneMessageSuperbadgeItem: parametizedUrl(
    process.env.TH_NONE_MESSAGE_SUPERBADGE_ITEM_URL!
  ),
  noneMessageModuleItem: parametizedUrl(
    process.env.TH_NONE_MESSAGE_MODULE_ITEM_URL!
  ),
  noneMessageProjectItem: parametizedUrl(
    process.env.TH_NONE_MESSAGE_PROJECT_ITEM_URL!
  ),
  noneMessageEventItem: parametizedUrl(
    process.env.TH_NONE_MESSAGE_EVENT_ITEM_URL!
  ),
  superbadgeImage: parametizedUrl(process.env.TH_SUPERBADGE_IMAGE_URL!),
  moduleImage: parametizedUrl(process.env.TH_MODULE_IMAGE_URL!),
  projectImage: parametizedUrl(process.env.TH_PROJECT_IMAGE_URL!),
  eventImage: parametizedUrl(process.env.TH_EVENT_IMAGE_URL!),
  trailheadUnknownBadge: parametizedUrl(
    process.env.TH_UNKNOWN_BADGE_IMAGE_URL!
  ),
  trailheadFirstModule: parametizedUrl(process.env.TH_FIRST_MODULE_URL!),
  trailheadFirstModuleImage: parametizedUrl(
    process.env.TH_FIRST_MODULE_IMAGE_URL!
  ),
  trailhead: parametizedUrl("{{trailheadUrl}}"),
  trailheadHelpProfileOverview: parametizedUrl(
    process.env.TH_HELP_PROFILE_OVERVIEW_URL!
  ),
  trailheadRanks: parametizedUrl(process.env.TH_RANKS_URL!),
  signup: parametizedUrl(process.env.SIGNUP_URL!),
  login: parametizedUrl(process.env.LOGIN_URL!),
  logout: parametizedUrl(process.env.LOGOUT_URL!),
  defaultSuperbadgeImage: parametizedUrl(
    process.env.DEFAULT_SUPERBADGE_IMAGE_URL!
  ),
  defaultSuperbadge: parametizedUrl(process.env.DEFAULT_SUPERBADGE_URL!),

  // TBC Urls
  tbcDefaultGroupImage: parametizedUrl(
    process.env.TBC_DEFAULT_GROUP_IMAGE_URL!
  ),
  tbcUserFiles: parametizedUrl(process.env.TBC_USER_FILES_URL!),
  tbcSearchFiles: parametizedUrl(process.env.TBC_SEARCH_FILES_URL!),
  tbcCommunityFeed: parametizedUrl(process.env.TBC_COMMUNITY_FEED!),
  tbcUserFeedQuestions: parametizedUrl(
    process.env.TBC_USER_FEED_QUESTIONS_URL!
  ),
  tbcUserFeedAnswers: parametizedUrl(process.env.TBC_USER_FEED_ANSWERS_URL!),
  tbcUserFeedBestAnswers: parametizedUrl(
    process.env.TBC_USER_FEED_BEST_ANSWERS_URL!
  ),
  tbcUserGroups: parametizedUrl(process.env.TBC_USER_GROUPS_URL!),
  tbcUserFeed: parametizedUrl(process.env.TBC_USER_FEED_URL!),
  tbcUserConnections: parametizedUrl(process.env.TBC_USER_CONNECTIONS_URL!, {
    tab: "",
  }),
  tbcGroupDetail: parametizedUrl(process.env.TBC_GROUP_DETAIL_URL!),
  tbcHireMe: parametizedUrl(process.env.TBC_MESSAGE_HIRE_ME_URL!),
  tbcMessageUser: parametizedUrl(process.env.TBC_MESSAGE_USER_URL!),
};

/** Field Policies for Apollo Cache */
export const urlFieldPolicies: {
  [fieldName: string]: FieldPolicy<string | UrlFormatter, string, string>;
} = {};

for (let [field, value] of Object.entries(sharedUrls)) {
  urlFieldPolicies[field] = {
    read(cv = value, { args }: FieldFunctionOptions) {
      if (typeof cv === "string") {
        return cv;
      }

      const getUrl = cv as UrlFormatter;

      if (args !== null) {
        return getUrl(args);
      }

      return getUrl();
    },
  };
}

// Method to retrieve the url values directly instead of using apollo
export const getSharedUrlValues = () => {
  const urls: { [key: string]: string } = {};
  for (let [field, value] of Object.entries(sharedUrls)) {
    // @ts-ignore
    urls[field] = urlFieldPolicies?.[field]?.read(value, {} as FieldFunctionOptions) || "";
  }
  return urls;
};

function paramsFor(pattern: string): UrlPatternParam[] {
  return (pattern.match(/\{\{[\w]+\}\}/g) || []).map((param: string) => ({
    key: param.replace(/\{|\}/g, ""),
    placeholder: param,
  }));
}

export function parametizedUrl(pattern: string, defaults?: UrlParamValues) {
  if (pattern === undefined) {
    throw new Error("Pattern is required, is an env var missing or invalid?");
  }

  const params = paramsFor(pattern) || [];
  const defaultValues = defaults;

  const formatter = (args?: UrlParamValues) => {
    const values: UrlParamValues = {
      ...{
        locale: locale(),
        baseUrl: baseUrl(),
        trailheadLocale: trailheadLocaleFor(locale()),
        trailheadUrl: trailheadUrl(),
        profileUserId: profileUser() ? profileUser()!.id : null,
        currentUserId: currentUser() ? currentUser()!.id : null,
        tbcProfileUserId: getProfileCommunityUserId(),
        tbcCurrentUserId: getCurrentCommunityUserId(),
        assetsUrl: assetsUrl(),
      },
      ...defaults,
      ...args,
    };

    return params.reduce<string>((acc: string, { key, placeholder }) => {
      const value = values[key];

      if (value === undefined || value === null) {
        return acc;
      }

      return acc.replace(placeholder, String(value));
    }, pattern);
  };

  formatter.pattern = pattern;

  return formatter;
}
