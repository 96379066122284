import { api, wire, LightningElement } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { GetCommunityTags_profile_PublicProfile_communityTags as CommunityTags } from "../../tbme/communityTags/gql/GetCommunityTags";

export default class extends LightningElement {
  private labels: LabelTranslations = undefined!;

  @api open: boolean = false;
  @api communityTags: CommunityTags[] = [];

  @wire(MultiLabelAdapter, {
    labels: [
      "communityTags.title",
      "communityTags.viewTagsTitle",
      "communityTags.close",
    ],
  })
  private handleLabels(labels: { communityTags: LabelTranslations }) {
    this.labels = {
      ...labels.communityTags,
    };
  }

  handleHide() {
    this.dispatchEvent(new CustomEvent("hide"));
  }
}
