import {
  legacyFormat,
  t,
  TRANSLATION_MISSING_MESSAGE,
} from "tbme/localization";
import { LightningElement, wire } from "lwc";
import {
  LabelTranslations,
  MultiLabelAdapter,
  MultiLabelAdapterConfig,
} from "tbme/localization";
import salesforceIconSrc from "./images/salesforce-icon.svg";
import { ApolloQueryResult, gql } from "@apollo/client";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import {
  GetAboutMyself,
  GetAboutMyself_chassis_profile,
} from "./gql/GetAboutMyself";
import { showToast } from "tds/toaster";
import { logger } from "tbme/logger";
import { sharedUrls } from "../localState/urls";
import { ABOUT_MYSELF_QUERY } from "./query";
import { verifyCountry, verifyState } from "@sfdc-www/digital-i18n";
import {
  isHireMeEnabled,
  isCareerMarketplaceEnabled,
} from "../../../shared/feature";
import { mediaQueryMobile } from "tbme/profilePage";

export default class extends LightningElement {
  private user: GetAboutMyself_chassis_profile = undefined!;
  private visible: boolean = false;
  private displayProfileEditModal: boolean = false;
  private isSelf?: boolean;

  @wire(QueryAdapter, {
    query: ABOUT_MYSELF_QUERY,
  })
  private handleResult(result: QueryAdapterValue<GetAboutMyself>) {
    if (!result) {
      return;
    }
    const { data, loading, error, errors } = result;
    const chassis = data?.chassis;
    if (chassis) {
      const { profile, isSelf } = chassis;
      this.isSelf = isSelf;
      if (!profile || (!profile.isPublicProfile && !isSelf)) {
        this.visible = false;
        return;
      }

      this.user = profile;
      this.visible = true;
    }
  }

  private get profileUrl() {
    return this.user?.profileUrl;
  }

  private get showUrlComponent(): boolean {
    return !!this.user?.profileUrl;
  }

  /**
   * Sanitizes from profileUrl by removing 'https://www.'. If displaying in mobile, just returns the slug
   */
  private get displayUrl() {
    if (mediaQueryMobile.matches && !!this.user?.username) {
      return this.user?.username;
    } else {
      return this.user?.profileUrl?.replace(
        /^https:\/\/www(?:(?:-(?!\.))(?:.*?))?\./,
        ""
      );
    }
  }

  @wire<MultiLabelAdapterConfig>(MultiLabelAdapter, {
    labels: [
      "aboutMe.addName",
      "aboutMe.edit",
      "aboutMe.copy",
      "aboutMe.emptyAddShortBio",
      "aboutMe.hireMeOptIn",
      "aboutMe.hireMeOptOut",
    ],
  })
  private updateLabels({ aboutMe }: { aboutMe: LabelTranslations }) {
    this.labels = {
      ...aboutMe,
    };
  }
  private labels: LabelTranslations = undefined!;

  private get titleAtCompany() {
    const roles = t("profileEditModal.roleOptions") as any;
    // We are currently saving these with the english translated version and then keying off of that
    // These should really be saved to the backend with the translation keys instead
    const roleMap: Record<string, string> = {
      Developer: roles.developer,
      Administrator: roles.administrator,
      Architect: roles.architect,
      "Sales Representative / Manager": roles.salesRep,
      Marketer: roles.marketer,
      "Service Agent / Supervisor": roles.supervisor,
      "Data Analyst": roles.dataAnalyst,
      Consultant: roles.consultant,
      "Business Operations Manager": roles.businessOperationsManager,
      Designer: roles.designer,
      Educator: roles.educator,
      Student: roles.student,
      Executive: roles.executive,
      "Product Manager": roles.productManager,
    };
    if (this.user?.title && this.user?.company?.name) {
      return t("aboutMe.titleAtCompany", {
        title: this.user.title,
        company: this.user.company?.name,
      });
    } else if (this.user?.role && this.user.company?.name) {
      let userRole;
      userRole = roleMap[this.user.role] || this.user.role;

      return t("aboutMe.titleAtCompany", {
        title: userRole,
        company: this.user.company?.name,
      });
    }

    return this.user?.company?.name;
  }

  private get fullName() {
    const firstName = this.user?.firstName.trim() || "";
    const lastName = this.user?.lastName?.trim() || "";
    return `${firstName} ${lastName}`.trim();
  }

  private get country() {
    const countryCode = this.user?.address?.country;
    return countryCode ? verifyCountry(countryCode) : "";
  }

  private get state() {
    const countryCode = this.user?.address?.country;
    const stateCode = this.user?.address?.state;

    return countryCode && stateCode ? verifyState(countryCode, stateCode) : "";
  }

  private get location(): string {
    if (this.state) {
      return `${this.state}, ${this.country}`;
    }

    return this.country || "";
  }

  private get salesforceIconSrc() {
    return salesforceIconSrc;
  }

  private async handleCopyClick() {
    const textarea = this.template.querySelector(
      ".slug-value"
    ) as HTMLTextAreaElement;

    try {
      await navigator.clipboard.writeText(textarea.value);
      showToast({
        message: t("aboutMe.copySuccess"),
        variant: "success",
      });
    } catch (e) {
      logger.error("Error copying text to clipboard", e);
    }
  }

  private handleAddNameClick() {
    this.displayProfileEditModal = true;
  }

  private handleEditClick() {
    this.displayProfileEditModal = true;
  }

  private handleProfileEditRequestClose() {
    this.displayProfileEditModal = false;
  }

  private get displayEmptyState(): boolean {
    return !!this.isSelf && !this.user.bio;
  }

  private get showAddName(): boolean {
    return !this.user?.firstName?.trim() && !this.user?.lastName?.trim();
  }

  private get showHireMeButton() {
    return (this.user.isAvailableForHire && isHireMeEnabled()) ?? false;
  }

  private get showAvailableForHireLink() {
    return this.isSelf && isHireMeEnabled();
  }

  private get showCareerMarketplace() {
    return this.isSelf && this.user.lastName && isCareerMarketplaceEnabled();
  }

  private get availableForHireLink() {
    const key: string =
      this.user.isAvailableForHire ?? false ? "hireMeOptOut" : "hireMeOptIn";
    return legacyFormat(this.labels[key], sharedUrls.availableForHire());
  }

  connectedCallback() {
    // Display the Profile Edit modal on page load if I'm looking at my own profile and the #edit hash is there. E.g. https://www.salesforce.com/trailblazer/profile#edit
    // Most likely this is coming from a redirect from the Settings page (Privacy section) or somewhere else that requires to complete the profile.
    if (
      window.location.pathname.endsWith("/profile") &&
      window.location.hash === "#edit"
    ) {
      this.displayProfileEditModal = true;
      // this will remove the hash (#edit) from the url so page refreshes don't trigger the open modal again
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }
}
