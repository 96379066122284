import { ApolloQueryResult, gql } from "@apollo/client";
import { LightningElement, api, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { GetEmptySuperbadgeItemUrls } from "./gql/GetEmptySuperbadgeItemUrls";

export default class extends LightningElement {
  @api isSelf: boolean = false;

  @wire(MultiLabelAdapter, {
    labels: [
      "superbadges.emptySuperbadgeContent",
      "superbadges.emptySuperbadgeDescription",
    ],
  })
  private labels: LabelTranslations = {};

  @wire(QueryAdapter, {
    query: gql`
      query GetEmptySuperbadgeItemUrls {
        siteInfo @client {
          urls @client {
            defaultSuperbadgeImage
            defaultSuperbadge
          }
        }
      }
    `,
  })
  private context: ApolloQueryResult<GetEmptySuperbadgeItemUrls> = undefined!;

  private get visible() {
    return this.context && this.context.data && this.context.data.siteInfo;
  }

  private get urls() {
    return this.visible
      ? this.context.data.siteInfo.urls
      : {
          defaultSuperbadgeImage: "",
          defaultSuperbadge: "",
        };
  }
}
