import { LightningElement, api } from "lwc";
import classNames from "classnames";

const sizes = ["small", "large"];
const variants = ["borderless"];

type POPOVER_SIZES = "small" | "large" | "";
type POPOVER_VARIANTS = "borderless" | "";

export function closest(element: HTMLElement | Node, selector: string) {
  if (element instanceof HTMLElement) return element.closest(selector);
  let el: HTMLElement | Node | null = element;

  do {
    if (el instanceof HTMLElement && el.matches(selector)) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);

  return null;
}

function shadowedTarget(event: MouseEvent) {
  const path = event.composedPath();
  if (Array.isArray(path) && path[0]) return path[0];
  return event.target;
}

function closestElement(
  element: HTMLElement | Node | Document | Window | null,
  selector: string
) {
  function __closestFrom(
    el: HTMLElement | Node | Document | Window | null
  ): Element | null {
    if (!el || el === document || el === window) return null;
    const found = closest(el as HTMLElement, selector);

    return found
      ? found
      : __closestFrom(((el as Node).getRootNode() as ShadowRoot).host);
  }

  return __closestFrom(element);
}

export default class Popover extends LightningElement {
  @api assistiveText: string = undefined!;
  @api size: POPOVER_SIZES = undefined!;
  @api variant: POPOVER_VARIANTS = "";
  @api focusTrapSelector: string = undefined!;

  private _bodyId: string = undefined!;
  private _isOpen = false;

  @api
  set isOpen(value: boolean) {
    this._isOpen = value;
    value ? this.activate() : this.deactivate();
  }

  get isOpen() {
    return this._isOpen;
  }

  private get popoverClass() {
    return classNames("popover", this.variantClass, {
      hidden: !this.isOpen,
    });
  }

  private get variantClass() {
    return variants.includes(this.variant) ? `popover_${this.variant}` : "";
  }

  private get bodyId() {
    if (this._bodyId) return this._bodyId;
    this._bodyId = Math.random().toString();
    return this._bodyId;
  }

  private get isFocusTrapInactive() {
    return !this.isOpen;
  }

  private activate() {
    window.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("click", this.handleClick, true);
    document.addEventListener("focusout", this.handleFocusOut, true);
  }

  private deactivate() {
    window.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleClick, true);
    document.removeEventListener("focusout", this.handleFocusOut, true);
  }

  private handleKeyDown = (e: KeyboardEventInit) => {
    if (e.key === "Esc" || e.key === "Escape") {
      this.handleClose();
    }
  };

  handleClose() {
    this.dispatchEvent(new CustomEvent("close"));
  }

  handleFocusOut = (e: Event) => {
    if (!e.isTrusted) return;
    if (!document.hasFocus()) this.handleClose();
  };

  handleClick = (e: MouseEvent) => {
    if (!e.isTrusted) return;
    const target = shadowedTarget(e) as HTMLElement;
    const clickedOutside =
      closestElement(target, this.focusTrapSelector) !==
      closestElement(this.template.host, this.focusTrapSelector);
    if (clickedOutside) this.handleClose();
  };
}
