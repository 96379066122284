import { makeVar } from "@apollo/client";
import { ProfileContext } from "../../tbme/profilePage/types";
import {
  ApexCommunityUsers,
  LegacyCommunityConnections,
  CommunityAsyncResponse,
  CommunityStats,
  CommunityUser,
} from "./types";

export const pendingValue: CommunityAsyncResponse = {
  __typename: "CommunityAsyncResponse",
  isLoading: true,
  error: null,
};

export const currentCommunityUser = makeVar<
  CommunityUser | CommunityAsyncResponse | null
>(null);

export const profileCommunityUser = makeVar<
  CommunityUser | CommunityAsyncResponse | null
>(null);

export const communityStats = makeVar<
  CommunityStats | CommunityAsyncResponse | null
>(pendingValue);

export const legacyCommunityConnections = makeVar<
  LegacyCommunityConnections | CommunityAsyncResponse | null
>(pendingValue);

export const resetState = () =>
  [currentCommunityUser, profileCommunityUser].map((fn) => fn(null)) &&
  [communityStats, legacyCommunityConnections].map((fn) => fn(pendingValue));

/**
 * Utils for setting user states
 */
export const setUserStateFromApexResponse = (response: ApexCommunityUsers) => {
  const { currentUser, contextUser, contextProfileTbc2User } = response;

  currentCommunityUser(
    currentUser && currentUser.ExternalId
      ? {
          __typename: "CommunityUser",
          id: currentUser.ExternalId,
          secondaryIds: null,
        }
      : null
  );

  profileCommunityUser(
    contextUser && contextUser.ExternalId
      ? {
          __typename: "CommunityUser",
          id: contextUser.ExternalId,
          secondaryIds:
            contextProfileTbc2User &&
            contextProfileTbc2User.aggregatedTbc2UserIds
              ? contextProfileTbc2User.aggregatedTbc2UserIds
              : [],
        }
      : null
  );
};

export const setUserStateFromProfileContext = ({
  tbc2UserRecords,
}: ProfileContext) => {
  if (!tbc2UserRecords) {
    return;
  }

  return setUserStateFromApexResponse(tbc2UserRecords);
};

/** Convenience selectors */

export const getCurrentCommunityUserId = (): null | string => {
  const user = currentCommunityUser();

  if (user && user.__typename === "CommunityUser") {
    return (user && user.id) || null;
  }

  return null;
};

export const getProfileCommunityUserId = (): null | string => {
  const user = profileCommunityUser();

  if (user && user.__typename === "CommunityUser") {
    return (user && user.id) || null;
  }

  return null;
};
