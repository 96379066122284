export function getInputValue(
  input: HTMLInputElement | HTMLSelectElement | null | undefined
): string {
  return input ? input.value : "";
}

export function isInputRequired(
  input: HTMLInputElement | HTMLSelectElement | null | undefined
): boolean {
  return input ? input.required : false;
}

export function isInputValid(
  input: HTMLInputElement | null | undefined
): boolean | undefined {
  return input ? input.hasAttribute("aria-invalid") : true;
}
