import { ApolloQueryResult, gql } from "@apollo/client";
import { LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { GetNoActivityUrls } from "./gql/GetNoActivityUrls";

export default class extends LightningElement {
  @wire(MultiLabelAdapter, {
    labels: [
      "trailblazerCommunity",
      "tbcNoActivity.heading",
      "tbcNoActivity.body",
      "tbcNoActivity.goToCommunityFeed",
    ],
  })
  private labels: LabelTranslations = undefined!;

  @wire(QueryAdapter, {
    query: gql`
      query GetNoActivityUrls {
        siteInfo @client {
          urls @client {
            tbcCommunityFeed
            astroImage
          }
        }
      }
    `,
  })
  private context: ApolloQueryResult<GetNoActivityUrls> = undefined!;
  private get visible() {
    return this.context && this.context.data && this.context.data.siteInfo;
  }
  private get urls() {
    return this.visible ? this.context.data.siteInfo.urls : undefined!;
  }
}
