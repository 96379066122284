import { gql } from "@apollo/client";

export default gql`
  fragment PageInfoBidirectional on PageInfo {
    __typename
    endCursor
    hasNextPage
    startCursor
    hasPreviousPage
  }
`;
