import {
  LightningElement,
  createContextProvider,
  ContextConsumer,
  wire,
  api,
} from "lwc";
import { EffectAdapter } from "common/effectAdapter";
import {
  ClientAdapter,
  ClientAdapterContext,
  ClientAdapterValue,
} from "tbme/clientAdapter";
import { QueryAdapter } from "tbme/queryAdapter";
import { ApolloClient } from "@apollo/client";

const contexts = [
  createContextProvider(ClientAdapter),
  createContextProvider(QueryAdapter),
];

type EffectContext = {
  localContext: ClientAdapterContext;
};

/**
 * This provider deviates from the base utility implementation provided by `common/context` by
 * providing "context" to instances of each adapter that require client from ClientAdapter.
 * This allows for declarative control over the client used by QueryAdapter without needing
 * to alter the implementation of the consumer of QueryAdapter.
 */
export default class extends LightningElement {
  private consumers = new Set<ContextConsumer<ClientAdapterContext>>();

  @api client: ApolloClient<any> = undefined!;

  @wire(EffectAdapter, {
    localContext: "$localContext",
  })
  private updateConsumers({ localContext }: EffectContext) {
    for (let consumer of this.consumers) {
      consumer.provide(localContext);
    }
  }

  @wire(ClientAdapter, {
    client: "$client",
  })
  private context: ClientAdapterValue = undefined!;

  get localContext(): ClientAdapterContext {
    return { client: this.client || this.context.client };
  }

  connectedCallback() {
    contexts.forEach((contextualizer) =>
      contextualizer(this, {
        consumerConnectedCallback: (
          consumer: ContextConsumer<ClientAdapterContext>
        ) => {
          this.consumers.add(consumer);
          consumer.provide(this.localContext);
        },
        consumerDisconnectedCallback: (
          consumer: ContextConsumer<ClientAdapterContext>
        ) => {
          this.consumers.delete(consumer);
        },
      })
    );
  }
}
