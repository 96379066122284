export interface DigitalResume {
  id?: string;
  openToRemoteWork?: boolean;
  openToRelocation?: boolean;
  showFullName?: boolean;
  showInSearchResults?: boolean;
  agreeToTerms?: boolean;
  showProfilePhoto?: boolean;
  location?: string;
  highlight?: string | null;
  careerPath?: string | null;
  motivators?: string | null;
  aboutMeVideoUrl?: string | null;
  workExperiences?: [];
  educationExperiences?: [];
  projects?: [];
}

export interface ExperienceError {
  message: string;
  path: string[];
  status: number;
}

export type DigitalResumeResponse = {
  data: { digitalResume?: DigitalResume };
  errors?: ExperienceError[];
};

export type UpdateDigitalResumeResponse = {
  data: {
    updateDigitalResume?: {
      digitalResume?: DigitalResume;
    };
  };
  errors?: ExperienceError[];
};

const DIGITAL_RESUME_QUERY = `
query FindDigitalResume {
  digitalResume {
    showInSearchResults
  }
}`;

const requestBody = {
  query: DIGITAL_RESUME_QUERY,
  operationName: "FindDigitalResume",
  variables: "{}",
};

const BASE_URL =
  window.sfdcBase?.exp?.EXPERIENCE_API_BFF_URL ||
  process.env.EXPERIENCE_API_BFF_URL;

export const getDigitalResume: (
  iisToken: string
) => Promise<DigitalResumeResponse> = async (iisToken: string) => {
  const options = {
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${iisToken}`,
    },
    method: "POST",
  } as RequestInit;
  try {
    const result = await fetch(BASE_URL, options);
    if (!result.ok) {
      const text = await result.text();
      throw new Error(text);
    }
    return (await result.json()) as Promise<DigitalResumeResponse>;
  } catch (e) {
    throw new Error(JSON.stringify(e));
  }
};
