import { footerLinks } from "tbme/localState";
import { ProfileContext } from "../types";

/**
 * Set footerLinks var from VF context.
 */
export const setFooterLinksState = (context: ProfileContext) => {
  const fromContext = context.footerLinks;
  if (!fromContext) return;

  if (Array.isArray(fromContext)) {
    footerLinks(fromContext);
  }
};
