import { gql, ApolloQueryResult } from "@apollo/client";
import { LightningElement, wire, track } from "lwc";
import {
  GetHeaderContext,
  GetHeaderContext_appLauncherItems,
} from "./gql/GetHeaderContext";
import { QueryAdapter } from "tbme/queryAdapter";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";
import { Config } from "tds/header";
import { HEADER_QUERY } from "./query";
export default class extends LightningElement {
  private apps: GetHeaderContext_appLauncherItems[] = undefined!;

  @wire(MultiLabelAdapter, {
    labels: [
      "headerDropdownMyProfile",
      "headerDropdownMySettings",
      {
        labelKey: "settingsWelcomeLogout",
        aliasKey: "logOut",
      },
    ],
  })
  private labels: LabelTranslations = {};
  private locale: string = "";
  private config: Config = null!;

  @wire(QueryAdapter, {
    query: HEADER_QUERY,
  })
  handleResult({ data, error }: ApolloQueryResult<GetHeaderContext>) {
    if (!data) return;

    this.locale = data.locale;

    this.config = {
      logo: {
        assistiveText: data.siteInfo.name,
        link: {
          href: data.siteInfo.urls.home,
        },
        url: `${data.siteInfo.urls.logo}`,
      },
    };

    const { currentUser } = data;

    if (!currentUser) {
      // If not authenticated, show 'Sing Up' and 'Log In' buttons
      this.config.signupLink = {
        href: data.siteInfo.urls.signup,
      };
      this.config.signinLink = {
        href: data.siteInfo.urls.login,
      };

      return;
    }

    const { avatarUrl, backgroundImageUrl, fullName } = currentUser;

    this.config.userProfile = {
      avatarUrl: avatarUrl,
      bannerUrl: backgroundImageUrl,
      fullName: fullName,
      navItems: [
        {
          label: this.labels.headerDropdownMyProfile,
          link: { href: data.siteInfo.urls.profile! },
          mobile: true,
        },
        {
          label: this.labels.headerDropdownMySettings,
          link: { href: data.siteInfo.urls.settings! },
          mobile: true,
        },
      ],
      logout: {
        label: this.labels.logOut,
        link: {
          href: data.siteInfo.urls.logout,
        },
      },
    };

    this.config.skipLink = {
      href: "#profile-sections-container",
    };

    this.config.apps = data.appLauncherItems;
  }
}
