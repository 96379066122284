import { memoizeOnce } from "../../../shared/memoize";
import {
  getContext,
  setContext,
  subscribeToContextChange,
} from "../../tbme/profilePage/context";
import { getProfileCommunityUserId } from "./state";
import {
  fetchTzUsers,
  fetchTbcUsers,
  fetchUsers,
  setShouldFetchUsers,
  setLocalUserRecords,
} from "./fetchUsers";
import { fetchCommunityStats } from "./fetchCommunityStats";
import { profileUser } from "tbme/localState";
import { ProfileContext } from "../../tbme/profilePage/types";
import { fetchConnections, fetchApexConnections } from "./fetchConnections";
import { logger } from "tbme/logger";

/**
 * Impure function which fetches community data using ids from state. Can only be called once.
 */
export const fetchCommunityProfile = memoizeOnce(() => {
  const profile = profileUser();

  if (!profile || !profile.id) {
    return;
  }

  const fetchData = async (profileUserId: string, isExternalUser: boolean) => {
    // Wait for users before requesting subsequent data
    if (!getProfileCommunityUserId()) {
      await fetchUsers(profileUserId, isExternalUser);
    }

    // Allow subsequent calls to be made in parallel
    fetchCommunityStats();
    fetchConnections(profileUserId, isExternalUser);

    // TODO: add connections
    // TODO: add mvp
  };

  return fetchData(profile.id, !!profile.isExternalUser);
});

export const resetCache = () => {
  fetchCommunityProfile.resetCache();
  fetchTzUsers.resetCache();
  fetchTbcUsers.resetCache();
  fetchConnections.resetCache();
  fetchApexConnections.resetCache();
};

const isEmptyUsers = (check: object) =>
  check && Object.keys(check).length === 0;

/**
 * Handle legacy page context changes. After the TBC2 launch community user records will no longer
 * be part of the Legacy Visualforce page state and must be fetched.
 */
subscribeToContextChange((context: ProfileContext) => {
  const { featureFlags } = context;

  const shouldFetchUsers = !!(
    featureFlags && featureFlags.isDeferCommunityUsersToClientOn
  );

  setShouldFetchUsers(shouldFetchUsers);

  if (!shouldFetchUsers) {
    const { tzUserRecords, tbc2UserRecords } = context;

    setLocalUserRecords("tz", tzUserRecords);
    setLocalUserRecords("tbc2", tbc2UserRecords);
  }

  updateTzUserFetchingState(context);
  updateTbc2UserFetchingState(context);
});

/**
 * Fetch TZ users
 */
async function updateTzUserFetchingState({
  featureFlags,
  tzUserRecords,
  profileUser,
  isExtProfileUser,
}: ProfileContext) {
  if (
    featureFlags &&
    featureFlags.isDeferCommunityUsersToClientOn &&
    profileUser &&
    profileUser.Id &&
    isEmptyUsers(tzUserRecords)
  ) {
    try {
      const result = await fetchTzUsers(profileUser.Id, !!isExtProfileUser);

      setContext({
        ...getContext(),
        tzUserRecords: {
          ...(result || {}),
          resolved: true,
        },
      });
    } catch (error) {
      logger.error(error);
      return;
    }
  }
}

/**
 * Fetch tbc2 users
 */
async function updateTbc2UserFetchingState({
  featureFlags,
  tbc2UserRecords,
  profileUser,
  isExtProfileUser,
}: ProfileContext) {
  if (
    featureFlags &&
    featureFlags.isDeferCommunityUsersToClientOn &&
    profileUser &&
    profileUser.Id &&
    isEmptyUsers(tbc2UserRecords)
  ) {
    try {
      const result = await fetchTbcUsers(profileUser.Id, !!isExtProfileUser);

      setContext({
        ...getContext(),
        tbc2UserRecords: {
          ...(result || {}),
          resolved: true,
        },
      });
    } catch (error) {
      logger.error(error);
      return;
    }
  }
}
