import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createContextAdapter } from "common/context";
import { isRenderProfileChassis, locale } from "tbme/localState";
import { fetch } from "@devforce/trailhead-client-fetch";
import tbcSchema from "../../../gql/tbcSchema";
import merge from "lodash/merge";

type Nullable<T> = T | null;

export interface ClientAdapterValue {
  client: ApolloClient<any>;
}
export interface ClientAdapterConfig {
  client?: Nullable<ApolloClient<any>>;
}
export interface ClientAdapterContext {
  client?: Nullable<ApolloClient<any>>;
}

export const adapterContextSchema = {
  client: "optional",
};

export interface ClientConfig {
  client?: ApolloClient<any>;
  accessToken: string;
  accessTokenType: "Bearer" | "Visualforce";
  uri: string;
  fetch: typeof fetch;
}

export const tbcApiBffUrl: string =
  window?.sfdcBase?.exp?.TBC_API_BFF_URL || "";

export const getDefaultConfig = (): ClientConfig => ({
  accessToken: "",
  accessTokenType: "Visualforce",
  uri: tbcApiBffUrl || (process.env.TBC_API_BFF_URL as string) || "",
  fetch,
});

let config = {
  ...getDefaultConfig(),
  client: createClient(getDefaultConfig()),
};

export const ClientAdapter = createContextAdapter<
  ClientAdapterValue,
  ClientAdapterConfig,
  ClientAdapterContext
>(() => ({ client: config.client }), adapterContextSchema);

export function setGlobalClient(client: ApolloClient<any>) {
  config.client = client;

  ClientAdapter.setGlobalContext({ client });
}

export function setAccessToken(accessToken: string) {
  config.accessToken = accessToken;

  setGlobalClient(createClient(config));
}

export function setAccessTokenType(accessTokenType: "Bearer" | "Visualforce") {
  config.accessTokenType = accessTokenType;

  setGlobalClient(createClient(config));
}

export function setUri(uri: string) {
  config.uri = uri;

  setGlobalClient(createClient(config));
}

export function getClient() {
  return config.client;
}

export function getConfig() {
  return config;
}

export function createClient(config: ClientConfig) {
  const { uri, accessToken, accessTokenType, fetch } = config;

  const httpLink = createHttpLink({
    uri,
    fetch,
  });
  const headersLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "accept-language": locale(),
        ...(isRenderProfileChassis()
          ? {
              "x-idp": "iis",
            }
          : {}),
        ...(accessToken
          ? {
              authorization: `${accessTokenType} ${accessToken}`,
            }
          : {}),
      },
    };
  });

  return new ApolloClient({
    link: headersLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        returnPartialData: true,
      },
    },
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            profileData: {
              merge(existing, incoming) {
                  return merge({}, existing, incoming);
              },
            },
          },
        },
      },
    }),
    typeDefs: tbcSchema,
  });
}
