import { ApolloQueryResult, gql } from "@apollo/client";
import classNames from "classnames";
import { CardState } from "elements/tbui/card/types";
import { LightningElement, track, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import {
  QueryAdapter as TbcQueryAdapter,
  QueryAdapterValue as TBCQueryAdapterValue,
} from "tbtbc/queryAdapter";
import {
  GetTbcCommunityConnections,
  GetTbcCommunityConnectionsVariables,
  GetTbcCommunityConnections_profileData_communityConnections as CommunityConnections,
  GetTbcCommunityConnections_profileData_communityConnections_following as FollowingConnectionsCollection,
  GetTbcCommunityConnections_profileData_communityConnections_followers as FollowersConnectionsCollection,
  GetTbcCommunityConnections_profileData_communityConnections_groups as GroupsConnectionsCollection,
  GetTbcCommunityConnections_profileData_communityConnections_followers_edges_node as FollowersConnection,
  GetTbcCommunityConnections_profileData_communityConnections_following_edges_node as FollowingConnection,
  GetTbcCommunityConnections_profileData_communityConnections_groups_edges_node as GroupsConnection,
  GetTbcCommunityConnections_profileData_communityUserUrls,
} from "./gql/GetTbcCommunityConnections";
import {
  GetUserConnectionsContext,
  GetUserConnectionsContext_siteInfo_urls,
} from "./gql/GetUserConnectionsContext";
import { CONNECTIONS_QUERY, USER_CONTEXT_QUERY } from "./query";

type Placeholder = {
  __typename: "Placeholder";
  id: string;
  className?: string;
};

type CommunityConnection =
  | FollowingConnection
  | FollowersConnection
  | GroupsConnection;

type CommunityConnectionsCollection =
  | FollowingConnectionsCollection
  | FollowersConnectionsCollection
  | GroupsConnectionsCollection;

type DisplayConnection = CommunityConnection & {
  className?: string;
};

type ConnectionsForDisplay = {
  totalCount: number | null;
  className?: string;
  items: (DisplayConnection | Placeholder)[] | null;
};

const imageErrors: WeakMap<HTMLImageElement, boolean> = new WeakMap();

export function handleImageError(event: Event, defaultImage: string) {
  const target = event.target as HTMLImageElement;

  if (!target) return;

  if (imageErrors.get(target)) return;

  const isGroup = target.classList.contains("group");

  if (isGroup) {
    target.src = defaultImage;

    imageErrors.set(target, true);
  }
}

export default class extends LightningElement {
  private visible: boolean = false;
  private cardState: CardState = "loading";
  private labels: LabelTranslations = undefined!;
  private urls: GetUserConnectionsContext_siteInfo_urls = undefined!;
  private isSelf: boolean = false;
  private variables: GetTbcCommunityConnectionsVariables = {
    userSlug: "",
    queryConnections: false,
  };

  @track
  private connections?: ConnectionsForDisplay[];

  @wire(MultiLabelAdapter, {
    labels: [
      "connections.title",
      "connections.followers",
      "connections.follower",
      "connections.following",
      "connections.followingSingular",
      "connections.groups",
      "connections.group",
      "connections.bannerTitle",
      "connections.bannerText",
    ],
  })
  private handleLabels(labels: { connections: LabelTranslations }) {
    this.labels = {
      ...labels.connections,
    };
  }
  private get showCommunityBanner() {
    return (
      this.connections &&
      this.isSelf &&
      !this.connections.reduce<number>(
        (acc: number, connection) => acc + (connection.totalCount ?? 0),
        0
      )
    );
  }

  @wire(QueryAdapter, {
    query: USER_CONTEXT_QUERY,
  })
  handleResult({
    data,
    errors,
    error,
  }: QueryAdapterValue<GetUserConnectionsContext>) {
    if (error || errors) {
      this.cardState = "loadFailed";
      return;
    } else if (!data) {
      return;
    }
    this.urls = data.siteInfo.urls;
    if (data.chassis?.profile?.username) {
      this.variables.userSlug = data.chassis.profile.username;
      this.variables.queryConnections = true;
      this.visible =
        data.chassis?.profile?.isPublicProfile || data.chassis?.isSelf;
    }
    if (data.chassis?.isSelf !== undefined) {
      this.isSelf = data.chassis.isSelf;
    }
  }

  @wire(TbcQueryAdapter, {
    query: CONNECTIONS_QUERY,
    variables: "$variables",
  })
  private handleResultTbc({
    data,
    error,
    errors,
    loading,
  }: TBCQueryAdapterValue<GetTbcCommunityConnections>) {
    if (error || errors) {
      this.cardState = "loadFailed";
      return;
    } else if (!data || !this.variables.queryConnections) {
      return;
    }

    const communityConnections = data.profileData?.communityConnections ?? null;
    const communityUrls = data.profileData?.communityUserUrls ?? null;

    this.setDisplayItems(
      communityConnections as GetTbcCommunityConnections | null,
      communityUrls
    );
    this.cardState = loading ? "loading" : "default";
  }

  private filterNull(input: object) {
    const result: any = {};

    for (let [key, value] of Object.entries(input)) {
      if (
        input[key as keyof typeof input] !== null &&
        input[key as keyof typeof input] !== undefined
      ) {
        result[key] = value;
      }
    }

    return result;
  }

  private handleImageError(event: Event) {
    handleImageError(event, this.urls.tbcDefaultGroupImage);
  }

  private setDisplayItems(
    queryResult: GetTbcCommunityConnections | null,
    communityUrls: GetTbcCommunityConnections_profileData_communityUserUrls | null
  ) {
    const urls = new Map();
    urls.set("followers", communityUrls?.followersUrl);
    urls.set("following", communityUrls?.followingUrl);
    urls.set("groups", communityUrls?.groupsUrl);
    this.connections = ["followers", "following", "groups"].map<
      ConnectionsForDisplay
    >((key) => {
      const defaultConnection: CommunityConnectionsCollection = {
        __typename: "ActorWithTotalCountConnection",
        totalCount: 0,
        edges: [],
      };
      const state: CommunityConnections = {
        __typename: "CommunityConnections",
        groups: {
          ...defaultConnection,
          __typename: "GroupDetailWithTotalCountConnection",
        } as GroupsConnectionsCollection,
        followers: {
          ...defaultConnection,
        } as FollowersConnectionsCollection,
        following: {
          ...defaultConnection,
        } as FollowingConnectionsCollection,
        ...(queryResult ? this.filterNull(queryResult) : {}),
      };
      const { totalCount, edges } = state[
        key as keyof CommunityConnections
      ] as CommunityConnectionsCollection;
      const count = totalCount ?? 0;
      const maxItems = 5;
      const items: (Placeholder | CommunityConnection)[] = [];
      edges?.forEach((edge: any) =>
        items.push({ ...edge.node, __typename: key })
      );

      while (items.length < 5) {
        items.push({
          __typename: "Placeholder",
          id: `placeholder-${items.length}`,
        });
      }
      const url = urls.get(key);
      const result = {
        totalCount,
        href: count > 0 ? url : null,
        className: count > 0 ? "items has-data" : "items",
        items: items.map((item: any) => ({
          ...item,
          className: classNames(
            {
              group: key === "groups",
              user: key !== "groups",
            },
            item.__typename
          ),
          avatar:
            typeof item.avatar !== "string" ? item.avatar?.large : item.avatar,
        })),
      };

      const getLabel = this.labelForConnection.bind(this);

      Object.defineProperty(result, "label", {
        get() {
          return getLabel(key, totalCount ?? 0) || "";
        },
      });

      return result;
    });
  }

  private labelForConnection(type: string, count: number) {
    switch (type) {
      case "following": {
        return t("connections.following", { count });
      }
      case "followers": {
        return t("connections.followers", { count });
      }
      case "groups": {
        return t("connections.groups", { count });
      }
    }
  }
}
