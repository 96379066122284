import { LightningElement, api, wire } from "lwc";
import { FooterContent, FooterGlobal } from "./types";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
export default class extends LightningElement {
  private labels: LabelTranslations = undefined!;
  @api content: FooterContent | null = null;
  @wire(MultiLabelAdapter, {
    labels: ["pageFooter.languageSelectAssistiveText"],
  })
  private handleLabels(labels: { pageFooter: LabelTranslations }) {
    this.labels = {
      ...labels.pageFooter,
    };
  }
  get selectedLocale() {
    if (!this.content?.locales || !this.content.locales.length)
      return undefined;
    let locale = this.content.locales.find((locale) => locale.selected);
    return locale ? locale.value : this.content.locales[0].value;
  }

  get globalContent(): FooterGlobal | undefined {
    return this.content?.global;
  }

  get links() {
    return this.globalContent!.links.map((link: any) =>
      Object.assign({}, link, {
        handleClick: this.handleLinkClick.bind(this, link),
      })
    );
  }

  get renderLeft() {
    return this.globalContent?.logo || this.globalContent?.copyright;
  }

  handleGlobalTrack(e: any) {
    let eventName = e.detail.eventName;
    this.track(eventName, e.detail.payload);
  }

  handleLinkClick(link: any) {
    // Keep for backwards compatibility
    this.dispatchEvent(
      new CustomEvent("link_click", {
        detail: link,
      })
    );
    this.track("link_clicked", {
      label: link.label,
    });
  }

  handleLocaleChange(e: any) {
    this.dispatchEvent(
      new CustomEvent("locale_change", {
        detail: {
          value: e.detail,
        },
      })
    );
    this.track("language_selector_changed", {
      label: e.detail,
    });
  }

  track(eventName: string, payload: any) {
    const trackEvent = new CustomEvent("track", {
      detail: {
        eventName,
        payload: { ...(payload ? payload : {}) },
      },
    });
    this.dispatchEvent(trackEvent);
  }
}
