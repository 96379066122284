import { trailheadUrl } from "tbme/localState";
import { ProfileContext } from "../types";

export const setLegacyTrailheadUrlState = ({
  appLauncherLinks,
}: ProfileContext) => {
  if (!appLauncherLinks) return;

  if (
    appLauncherLinks &&
    appLauncherLinks.trailhead &&
    appLauncherLinks.trailhead.AppUrl__c
  ) {
    trailheadUrl(appLauncherLinks.trailhead.AppUrl__c);
  }
};

export const setChassisTrailheadUrlState = () => {
  if (window?.sfdcBase?.exp?.TRAILHEAD_URL) {
    trailheadUrl(window?.sfdcBase?.exp?.TRAILHEAD_URL);
  }
};
