import { api, LightningElement, wire } from "lwc";

export default class extends LightningElement {
  @api buttonText!: string;
  @api additionalText!: string;
  @api acceptedFileTypes!: string;
  @api errorText!: string;

  private handleFileUpload(event: CustomEvent) {
    let files = (event.currentTarget as HTMLInputElement).files;
    this.dispatchEvent(
      new CustomEvent("filechange", {
        composed: true,
        bubbles: true,
        detail: { files: files },
      })
    );
  }

  private handleDragOver(event: CustomEvent) {
    event.preventDefault();
    const el = this.template.querySelector('[data-id="dropzone"]')!;
    if (!el.classList.contains("dragged-over")) {
      el.classList.add("dragged-over");
    }
  }

  private handleDragLeave(event: CustomEvent) {
    event.preventDefault();
    const el = this.template.querySelector('[data-id="dropzone"]')!;
    if (el.classList.contains("dragged-over")) {
      el.classList.remove("dragged-over");
    }
  }
}
