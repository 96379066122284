import { ReactiveVar } from "@apollo/client";
import { ReactiveListener } from "@apollo/client/cache/inmemory/reactiveVars";
import { WireAdapter } from "lwc";

export interface ReactiveVarAdapterConfig<T> {
  var: ReactiveVar<T>;
}

export interface ReactiveVarAdapterContext<T> {
  var: ReactiveVar<T>;
}

type Unsubscriber = () => void;

const unsubscribers: WeakMap<ReactiveVar<any>, Unsubscriber[]> = new WeakMap();

export function subscribeToVar<T = any>(
  reactiveVar: ReactiveVar<T>,
  listener: ReactiveListener<T>
) {
  if (!unsubscribers.has(reactiveVar)) {
    unsubscribers.set(reactiveVar, []);
  }

  let unsubs = unsubscribers.get(reactiveVar) as Unsubscriber[];
  let unsubscriber: Unsubscriber;

  const unsubscribe = () => unsubscriber && unsubscriber();

  unsubscribers.set(reactiveVar, [...unsubs, unsubscribe]);

  const onNextChange = (value: T) => {
    listener(value);
    unsubscriber = reactiveVar.onNextChange(onNextChange);
  };

  reactiveVar.onNextChange(onNextChange);

  return unsubscribe;
}

export function unsubscribeAll(reactiveVar: ReactiveVar<any>) {
  const unsubs = unsubscribers.get(reactiveVar);

  while (unsubs && unsubs.length) {
    const unsub = unsubs.pop();
    unsub && unsub();
  }

  unsubscribers.set(reactiveVar, []);
}

export class ReactiveVarAdapter<T>
  implements
    WireAdapter<T, ReactiveVarAdapterConfig<T>, ReactiveVarAdapterContext<T>> {
  constructor(public setValue: (value: T) => void) {}

  private reactiveVar?: ReactiveVar<T>;
  private unsubscribe?: () => void;

  update(config: ReactiveVarAdapterConfig<T>) {
    if (!this.reactiveVar) {
      this.reactiveVar = config.var;

      this.setValue(this.reactiveVar());
      this.unsubscribe = subscribeToVar(this.reactiveVar, (value: T) => {
        this.setValue(value);
      });
    } else if (this.reactiveVar !== config.var) {
      this.disconnect();
      this.update(config);
    }
  }
  connect() {}
  disconnect() {
    if (this.unsubscribe) {
      this.reactiveVar = undefined;
      this.unsubscribe();
    }
  }
}
