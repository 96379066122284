import { html, TemplateResult } from "lit-html";
import { withContext } from "../../../shared/lit/withContext";
import { AuraComponent, AuraComponentAttrs } from "../../../shared/aura";
import { auraComponent } from "../../../shared/lit/auraComponent";
import { ComponentTracker, trackedComponents } from "./componentTracker";
import { getProfileUserId } from "./context";
import { ProfileContext } from "./types";

export const renderTrackedComponent = (
  tracker: ComponentTracker,
  cmpRef: string,
  selector: (c: ProfileContext) => AuraComponentAttrs,
  shouldRender: (c: ProfileContext) => boolean = () => true,
  placeholder: TemplateResult = html``
) => {
  let rendered = false;
  let previousResult = html``;

  return html` ${withContext((c: ProfileContext) => {
    const render = shouldRender(c);

    if (render !== rendered) {
      rendered = render;

      if (
        render &&
        cmpRef === "c:AppExchange" &&
        process.env.DISABLE_APP_EXCHANGE_MODAL_HACK !== "true"
      ) {
        // TODO: Remove AppExchange hack after W-9167590
        previousResult = renderAppExchangeWithModalHack(
          tracker,
          cmpRef,
          c,
          selector
        );
      } else if (render) {
        previousResult = html`${auraComponent(cmpRef, selector(c), {
          onRender(cmp: AuraComponent) {
            tracker.setSelector(selector);
            tracker.setComponent(cmp);
          },
          placeholder,
        })}`;
      } else {
        previousResult = html``;
      }
    }
    return previousResult;
  })}`;
};

export const renderLegacyCard = (
  tracker: ComponentTracker,
  cmpRef: string,
  selector: (c: ProfileContext) => AuraComponentAttrs,
  shouldRender: (c: ProfileContext) => boolean = () => true
) =>
  renderTrackedComponent(
    tracker,
    cmpRef,
    selector,
    shouldRender,
    html` <tbui-card state="default">
      <div style="min-height: 8rem"></div>
    </tbui-card>`
  );

export const renderAboutMe = () => {
  return html`
    <tbme-about-me>
      <div slot="avatar" style="position: absolute; top: -6px">
        ${renderTrackedComponent(
          trackedComponents.aboutMeAvatar,
          "c:AboutMeAvatar",
          ({ profileUser, isSelf, profilePhotoUrl }: ProfileContext) => ({
            isSelf,
            profileUser: profileUser,
            profilePhotoUrl: profilePhotoUrl,
          }),
          () => true,
          html`<div
            style="
          border-radius: 50%; height: 11.25rem;
          width: 11.25rem;"
          ></div>`
        )}
      </div>
      <div slot="social-links">
        ${renderTrackedComponent(
          trackedComponents.aboutMeSocialLinks,
          "c:aboutMeSocialLinks",
          ({ profileUser }: ProfileContext) => ({
            size: "medium",
            name: profileUser ? profileUser.FirstName : "",
            google: profileUser ? profileUser.GoogleProfileLink__c : "",
            twitter: profileUser ? profileUser.TwitterProfileLink__c : "",
            facebook: profileUser ? profileUser.FacebookProfileLink__c : "",
            linkedin: profileUser ? profileUser.LinkedInProfileLink__c : "",
            website: profileUser ? profileUser.WebsiteLink__c : "",
          })
        )}
      </div>
    </tbme-about-me>
  `;
};

export const renderCertifications = () =>
  renderLegacyCard(
    trackedComponents.certifications,
    "c:lwcCertifications",
    ({ isSelf, profileUser, languageParam }: Partial<ProfileContext>) => ({
      isSelf,
      userId: getProfileUserId({ profileUser }),
      locale: languageParam,
      ...(process.env.FEATURE_ADDITIONAL_CERTS === "true" && {
        featureAdditionalCerts: true,
      }),
      ...(process.env.FEATURE_CERTIFICATIONS_STATUS_MESSAGE === "true" && {
        useStatusMessage: true,
      }),
    }),
    ({ isExtProfileUser }) => !isExtProfileUser
  );

export const renderUserDetails = () =>
  renderLegacyCard(
    trackedComponents.userDetails,
    "c:UserDetails",
    ({
      isSelf,
      profileUser,
      profilePhotoUrl,
      identity,
      profileUserBgImageUrl,
      pickLists,
    }: ProfileContext) => ({
      isSelf,
      profileUser,
      profilePhotoUrl,
      trailheadEmail: identity ? identity.email : "",
      profileUserBgImageUrl,
      pickLists,
    }),
    ({ isExtProfileUser }) => !isExtProfileUser
  );

export const renderRecognitions = () =>
  renderLegacyCard(
    trackedComponents.recognitions,
    "c:lwcRecognitions",
    ({ recognitions }: ProfileContext) => ({
      recognitions,
    }),
    ({ featureFlags, isExtProfileUser, recognitions }) =>
      !!(
        featureFlags &&
        !isExtProfileUser &&
        featureFlags.isRecognitionsOn &&
        recognitions &&
        recognitions.length > 0
      )
  );

export const renderAppExchange = () =>
  renderLegacyCard(
    trackedComponents.appExchange,
    "c:AppExchange",
    ({ cuid, profileUser }: ProfileContext) => ({
      uid: getProfileUserId({ profileUser }),
      cuid: cuid,
    }),
    ({ featureFlags, extProfileUserCmty, isExtProfileUser }) =>
      !!(
        featureFlags &&
        (!isExtProfileUser || extProfileUserCmty === "Tz") &&
        featureFlags.isAppExchangeOn
      )
  );

export const renderDeveloperMarketplace = () =>
  renderLegacyCard(
    trackedComponents.developerMarketplace,
    "c:DeveloperMarketplace",
    ({ cuid, profileUser }: ProfileContext) => ({
      uid: getProfileUserId({ profileUser }),
      cuid: cuid,
    }),
    ({ featureFlags, isExtProfileUser, extProfileUserCmty }) =>
      !!(
        featureFlags &&
        (!isExtProfileUser || extProfileUserCmty === "Tz") &&
        featureFlags.isAppExchangeOn &&
        featureFlags.isDevMarketplaceOn
      )
  );

export const renderMVPComponent = () =>
  renderLegacyCard(
    trackedComponents.mvp,
    "c:MVPComponent",
    ({
      cuid,
      profileUser,
      isExtProfileUser,
      tbc2UserRecords,
    }: ProfileContext) => ({
      uid: getProfileUserId({ profileUser }),
      cuid: cuid,
      isMockProfile: isExtProfileUser,
      aggregatedMvpExtUserIds:
        tbc2UserRecords && tbc2UserRecords.contextProfileTbc2User
          ? tbc2UserRecords.contextProfileTbc2User.aggregatedTbc2UserIds
          : undefined,
    }),
    (context) =>
      shouldRenderCommunityComponent(context) &&
      !!(
        context.featureFlags &&
        (!context.isExtProfileUser || context.extProfileUserCmty === "Tz") &&
        !context.featureFlags.isRecognitionsOn
      )
  );

export function shouldRenderCommunityComponent({
  featureFlags,
  tbc2UserRecords,
}: ProfileContext) {
  if (!featureFlags) {
    return true;
  }

  const { isDeferCommunityUsersToClientOn } = featureFlags;

  if (!isDeferCommunityUsersToClientOn) {
    return true;
  }

  const hasValue = (userRecords: object) => {
    return userRecords && Object.keys(userRecords).length > 0;
  };

  if (hasValue(tbc2UserRecords)) {
    return true;
  }

  return false;
}

/**
 * Workaround for AppExchange component not being wrapped in a ModalContainer.
 *
 * Render both a c:ModalContainer instance and a c:AppExchange instance, then place
 * the appExchange component into the ModalContainer. This will result in duplicate
 * Elements in the HTML and a broken visual state but the links will be clickable
 * and will open a modal.
 *
 * As a workaround for the visual regressions after adding the component to the
 * modalContainer, an additional AppExchange component is added outside of the container,
 * click events are captured on the tally elements, and then click events are triggered
 * on the corresponding elements in the hidden modal enabled element.
 *
 * Because this is clearly hacky, it should be removed after the TBID implementation
 * is fixed.
 *
 * TODO: Remove after W-9167590
 */
function renderAppExchangeWithModalHack(
  tracker: ComponentTracker,
  cmpRef: string,
  context: ProfileContext,
  selector: (c: ProfileContext) => AuraComponentAttrs
) {
  let modalContainer: AuraComponent;
  let appExchange: AuraComponent;

  const renderTogether = () => {
    if (modalContainer && appExchange) {
      modalContainer.set("v.body", appExchange);
    }
  };

  const triggerClick = (index: number) => {
    const buttons = document.querySelectorAll(
      ".appex-modal-container .button-tally"
    );

    (buttons[index] as HTMLElement).click();
  };

  document.addEventListener("click", (e: MouseEvent) => {
    const target: HTMLElement | null = e.target as HTMLElement;
    if (!target || !(target as HTMLElement).closest(".appex-visible")) {
      return;
    }

    const button = target.closest(".button-tally");

    if (!button) return;

    const index = Array.prototype.indexOf.call(
      button.parentNode!.children,
      button
    );

    if (index > -1) {
      triggerClick(index);
    }
  });

  return html`
    <style>
      .appex-modal-container .slds-card {
        display: none;
      }
    </style>
    <div class="appex-modal-container">
      ${auraComponent(
        "c:ModalContainer",
        {},
        {
          onRender(cmp) {
            modalContainer = cmp;

            renderTogether();
          },
        }
      )}
      <div class="appex-has-modal">
        ${auraComponent(cmpRef, selector(context), {
          onRender(cmp) {
            appExchange = cmp;

            renderTogether();
          },
        })}
      </div>
    </div>
    <div class="appex-visible">
      ${auraComponent(cmpRef, selector(context), {
        onRender(cmp) {
          tracker.setSelector(selector);
          tracker.setComponent(cmp);
        },
      })}
    </div>
  `;
}
