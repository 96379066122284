import { AuraComponent } from "shared/aura";
import { ApexError } from "./types";

interface ApexCallback {
  (): void;
}

interface MethodArgs {
  [k: string]: any;
}

interface BridgeComponent<A = MethodArgs, T = any> extends AuraComponent {
  getApexMethod(path: string): (args: A) => Promise<T>;
}

const BRIDGE_COMPONENT_TIMEOUT = 150000;

let bridgeComponent: BridgeComponent;
let pendingCalls: ApexCallback[] = [];
let componentTimeout: NodeJS.Timeout;

const resolvePendingCalls = () => {
  while (pendingCalls.length > 0) {
    let cb = pendingCalls.shift();
    cb && cb();
  }
};

export const setBridgeComponent = (component: AuraComponent) => {
  bridgeComponent = component as BridgeComponent;
  clearTimeout(componentTimeout);

  resolvePendingCalls();
};

export const getBridgeComponent = () => bridgeComponent;

export const useApexMethod = <A, T>(path: string) => {
  return (args: A) => {
    if (!bridgeComponent && !componentTimeout) {
      componentTimeout = setTimeout(() => {
        resolvePendingCalls();
      }, BRIDGE_COMPONENT_TIMEOUT);
    }

    let resolver: (value: Promise<T>) => void;
    let rejecter: (error: ApexError | string | Error) => void;

    const promise = new Promise<T>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    const callback = () => {
      if (!bridgeComponent) {
        return rejecter(
          `LegacyApexBridge: Timed out. Bridge component was not set after ${BRIDGE_COMPONENT_TIMEOUT}ms.`
        );
      }

      try {
        const method = bridgeComponent.getApexMethod(path);
        resolver(method(args));
      } catch (e) {
        rejecter(getErrorMessage(e));
      }
    };

    if (!bridgeComponent) {
      pendingCalls.push(callback);
    } else {
      callback();
    }

    return promise;
  };
};

export function getErrorMessage(error: ApexError | string | Error) {
  if (typeof error === "string") return error;
  if (error instanceof Error) return error.message;
  return (error && error.body && error.body.message) || "Unknown Error";
}
