import { ApolloQueryResult, gql } from "@apollo/client";
import { LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import {
  GetQuestionsAndAnswers,
  GetQuestionsAndAnswers_siteInfo_urls,
} from "./gql/GetQuestionsAndAnswers";

import trophyIcon from "./assets/trophy.svg";
import { fetchCommunityProfile } from "../communityData/communityData";
import { getError, getIsLoading, getUserId } from "../communityData/queryUtils";
import {
  CommunityAsyncResponse,
  CommunityStats,
  CommunityUser,
} from "../communityData/types";
import { CardState } from "../../tbui/card/types";
import { Q_AND_A_QUERY } from "./query";

export default class extends LightningElement {
  private visible: boolean = false;
  private footerUrl: string = "";
  private isSelf: boolean = false;
  private noActivity: boolean = false;
  private labels: LabelTranslations = undefined!;
  private cardState: CardState = "default";

  private get showNoActivity() {
    return !this.visible && this.noActivity && this.cardState === "default";
  }

  private get assets() {
    return {
      trophy: trophyIcon,
    };
  }

  private stats: CommunityStats | null = null;

  connectedCallback() {
    fetchCommunityProfile();
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "questionsAndAnswers.title",
      "questionsAndAnswers.goToCommunityFeed",
    ],
  })
  private handleLabels(labels: { questionsAndAnswers: LabelTranslations }) {
    this.labels = {
      ...labels.questionsAndAnswers,
    };
  }

  private get answersLabel() {
    return t("questionsAndAnswers.answers", {
      count: this.stats ? this.stats.answersCount : 0,
    });
  }

  private get bestAnswersLabel() {
    let labelName = "questionsAndAnswers.bestAnswers";
    if (process.env.FEATURE_ACCEPTED_ANSWERS_LABEL === "true") {
      labelName = "questionsAndAnswers.acceptedAnswers";
    }
    return t(labelName, {
      count: this.stats ? this.stats.bestAnswersCount : 0,
    });
  }

  private get questionsLabel() {
    return t("questionsAndAnswers.questions", {
      count: this.stats ? this.stats.questionsCount : 0,
    });
  }

  private get rankLabel() {
    return t("questionsAndAnswers.rank", {
      rank: (this.stats && this.stats.communityUserRank) || "",
    });
  }

  private get answersLink() {
    return (
      this.stats && this.stats.answersCount && this.urls.tbcUserFeedAnswers
    );
  }

  private get bestAnswersLink() {
    return (
      this.stats &&
      this.stats.bestAnswersCount &&
      this.urls.tbcUserFeedBestAnswers
    );
  }

  private get questionsLink() {
    return (
      this.stats && this.stats.questionsCount && this.urls.tbcUserFeedQuestions
    );
  }

  private get activityCount() {
    if (!this.stats) return 0;

    return [
      this.stats.questionsCount,
      this.stats.answersCount,
      this.stats.bestAnswersCount,
    ].reduce<number>((acc, value) => {
      if (value === null) return acc;

      return acc + value;
    }, 0);
  }

  private get communityFeedLink() {
    return this.activityCount
      ? this.urls.tbcUserFeed
      : this.urls.tbcCommunityFeed;
  }

  private urls: GetQuestionsAndAnswers_siteInfo_urls = undefined!;

  @wire(QueryAdapter, {
    query: Q_AND_A_QUERY,
    returnPartialData: true,
  })
  private handleChange({ data }: ApolloQueryResult<GetQuestionsAndAnswers>) {
    if (!data) {
      return;
    }

    this.urls = data.siteInfo.urls;

    if (data.profileUser.__typename !== "PublicProfile") {
      return;
    }

    const profileUser = data.profileUser;
    const { isSelf, communityUser, communityStats } = profileUser;
    const isTbcUser = !!getUserId(communityUser);
    const isLoading = getIsLoading(communityStats);

    if ((isSelf || isTbcUser) && isLoading) {
      this.cardState = "loading";
      this.visible = true;
      return;
    }

    if (!hasResolved(communityStats)) {
      return;
    }

    const isError = getError(communityStats);

    if (!isTbcUser && !isError && !isSelf) {
      this.noActivity = true;
      this.visible = false;
      return;
    }

    if (isSelf && isError) {
      this.cardState = "loadFailed";
      this.visible = true;
      this.noActivity = false;
      return;
    } else if (isError) {
      this.visible = false;
      this.noActivity = false;
      return;
    }

    if (communityStats && communityStats.__typename === "CommunityStats") {
      this.stats = communityStats;
    } else if (isSelf && !communityStats) {
      this.stats = {
        questionsCount: 0,
        answersCount: 0,
        bestAnswersCount: 0,
      } as CommunityStats;
    }

    this.cardState = "default";
    this.visible = !!this.stats;
  }
}

function hasResolved(
  check:
    | Partial<CommunityUser>
    | Partial<CommunityStats>
    | Partial<CommunityAsyncResponse>
    | null
) {
  return (
    check === null ||
    getError(check) ||
    (check && check.__typename !== "CommunityAsyncResponse")
  );
}
