import { AwardTypeFilter } from "../../../gql/types";
import { LabelTranslations } from "tbme/localization";
export interface EmptyItem {
  imageUrl: string;
  itemUrl: string;
  title: string;
  text: string;
}

export function getEmptyBadge(
  activeFilter: AwardTypeFilter,
  isSelf: boolean,
  labels: LabelTranslations,
  urls: any
) {
  const emptyItems: {
    [key in AwardTypeFilter]: EmptyItem;
  } = {
    EVENT: {
      imageUrl: urls.eventImage,
      itemUrl: urls.noneMessageEventItem,
      title: labels.noneMessageEventItemTitle,
      text: labels.noneMessageEventItemDescription,
    },
    MODULE: {
      imageUrl: urls.moduleImage,
      itemUrl: urls.noneMessageModuleItem,
      title: labels.noneMessageModuleItemTitle,
      text: labels.noneMessageModuleItemDescription,
    },
    PROJECT: {
      imageUrl: urls.projectImage,
      itemUrl: urls.noneMessageProjectItem,
      title: labels.noneMessageProjectItemTitle,
      text: labels.noneMessageProjectItemDescription,
    },
    STANDALONE: {
      imageUrl: urls.moduleImage,
      itemUrl: urls.noneMessageModuleItem,
      title: labels.noneMessageModuleItemTitle,
      text: labels.noneMessageModuleItemDescription,
    },
    SUPERBADGE: {
      imageUrl: urls.superbadgeImage,
      itemUrl: urls.noneMessageSuperbadgeItem,
      title: labels.noneMessageSuperbadgeAltText,
      text: labels.noneMessageSuperbadgeItemDescription,
    },
  };

  const thirdPersonEmptyItems: {
    [key in AwardTypeFilter]: EmptyItem;
  } = {
    EVENT: {
      imageUrl: urls.eventImage,
      itemUrl: "",
      title: labels.noneEarned,
      text: labels.noneMessageEventItemDescription,
    },
    MODULE: {
      imageUrl: urls.moduleImage,
      itemUrl: "",
      title: labels.noneEarned,
      text: labels.noneMessageModuleItemDescription,
    },
    PROJECT: {
      imageUrl: urls.projectImage,
      itemUrl: "",
      title: labels.noneEarned,
      text: labels.noneMessageProjectItemDescription,
    },
    STANDALONE: {
      imageUrl: urls.moduleImage,
      itemUrl: "",
      title: labels.noneEarned,
      text: labels.noneMessageModuleItemDescription,
    },
    SUPERBADGE: {
      imageUrl: urls.superbadgeImage,
      itemUrl: "",
      title: labels.noneEarned,
      text: labels.noneMessageSuperbadgeItemDescription,
    },
  };

  if (isSelf) {
    return emptyItems[activeFilter];
  } else {
    return thirdPersonEmptyItems[activeFilter];
  }
}
