import { WireAdapter } from "lwc";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIISImport = (): Promise<any> => {
  try {
    // @ts-ignore
    return import("https://a.sfdcstatic.com/digital/iis/v1-latest/iis.js");
  } catch {
    console.info(
      `Unable to find IIS at https://a.sfdcstatic.com/digital/iis/v1-latest/iis.js, falling back to https://www.salesforce.com/c/public/redirect/auth.js instead`
    );
    try {
      return import(
        // @ts-ignore
        "https://www.salesforce.com/c/public/redirect/auth.js"
      );
    } catch {
      throw new Error("Authentication disabled");
    }
  }
};

export type WireDataCallback = (value: any) => void;
export type WireConfigValue = Record<string, any>;

export type IISResponse = {
  isAuthenticated: boolean;
  accessToken: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  authHref: string;
  logoutHref: string;
  profileHref: string;
  settingsHref: string;
  iisError: string;
};

export class getIISModule<Value, Config, Context>
  implements WireAdapter<Value, Config, Context> {
  callback: WireDataCallback;

  isAuthenticated: boolean | undefined = undefined;
  accessToken: string | undefined = undefined;
  firstName: string | undefined = undefined;
  lastName: string | undefined = undefined;
  profileImageUrl: string | undefined = undefined;
  authHref: string | undefined = "";
  logoutHref: string | undefined = "";
  profileHref: string | undefined = "";
  settingsHref: string | undefined = "";

  constructor(callback: WireDataCallback) {
    this.callback = callback;
  }

  private async initIIS(config?: WireConfigValue) {
    // Import the IIS module. In case it isn't found,
    // fallback to importing it from the prod URL
    const iisImport = getIISImport();

    iisImport
      .then((module) => {
        if (typeof module === "object" && module.default) {
          const allFields = JSON.stringify(config) === "{}";
          if (!allFields && config && !("fields" in config)) {
            throw new Error(
              'Config must contain "fields" property if a config is passed'
            );
          }
          if (allFields || config?.fields.includes("isAuthenticated")) {
            module.default
              .isUserAuthenticated()
              .then((val: boolean) => {
                this.isAuthenticated = val;
                this.callback({
                  isAuthenticated: this.isAuthenticated,
                } as Partial<IISResponse>);
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          if (allFields || config?.fields.includes("accessToken")) {
            module.default
              .getAccessToken()
              .then((val: string) => {
                this.accessToken = val;
                this.callback({
                  accessToken: this.accessToken,
                } as Partial<IISResponse>);
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          if (allFields || config?.fields.includes("authHref")) {
            module.default
              .getAuthLoginUrl()
              .then((val: string) => {
                if (val) {
                  this.authHref = val;
                  this.callback({
                    authHref: this.authHref,
                  } as Partial<IISResponse>);
                }
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          if (allFields || config?.fields.includes("logoutHref")) {
            module.default
              .getAuthLogoutUrl()
              .then((val: string) => {
                if (val) {
                  this.logoutHref = val;
                  this.callback({
                    logoutHref: this.logoutHref,
                  } as Partial<IISResponse>);
                }
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          if (allFields || config?.fields.includes("profileHref")) {
            module.default
              .getProfileUrl()
              .then((val: string) => {
                if (val) {
                  this.profileHref = val;
                  this.callback({
                    profileHref: this.profileHref,
                  } as Partial<IISResponse>);
                }
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          if (allFields || config?.fields.includes("settingsHref")) {
            module.default
              .getSettingsUrl()
              .then((val: string) => {
                if (val) {
                  this.settingsHref = val;
                  this.callback({
                    settingsHref: this.settingsHref,
                  } as Partial<IISResponse>);
                }
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }

          // update user info as soon as it's available
          if (allFields || config?.fields.includes("userInfo")) {
            module.default
              .getUserInfo()
              .then((userinfo: any) => {
                if (typeof userinfo === "object") {
                  this.firstName = userinfo.given_name
                    ? userinfo.given_name
                    : "";
                  this.lastName = userinfo.family_name
                    ? userinfo.family_name
                    : "";
                  this.profileImageUrl = userinfo.picture;
                  // Add in additional IIS properties as needed

                  this.callback({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    profileImageUrl: this.profileImageUrl,
                  } as Partial<IISResponse>);
                }
              })
              .catch((error: Error) => {
                this.handleIISError(error);
              });
          }
        }
      })
      .catch((error: Error) => {
        this.handleIISError(error);
      });
  }

  private handleIISError(error: Error) {
    const msg = error.message;
    if (msg && msg.includes("Authentication disabled")) {
      console.warn("Authentication is currently unavailable");
      this.callback({
        iisError: "Authentication unavailable",
      } as Partial<IISResponse>);
    } else {
      console.error("iis error: ", error);
    }
  }

  update(config?: WireConfigValue): void {
    this.initIIS(config);
  }

  /**
   * required by interface
   */
  connect(): void {
    //
  }

  /**
   * required by interface
   */
  disconnect(): void {
    //
  }
}
