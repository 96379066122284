import { LightningElement, wire } from "lwc";
import { ApolloQueryResult } from "@apollo/client";
import {
  MultiLabelAdapter,
  LabelTranslations,
  legacyFormat,
} from "tbme/localization";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";

import {
  GetTrailheadBadges,
  GetTrailheadBadgesVariables,
  GetTrailheadBadges_profile_PublicProfile_earnedAwards_edges,
} from "./gql/GetTrailheadBadges";
import { EarnedAward } from "./gql/EarnedAward";
import { EarnedAwardSelf } from "./gql/EarnedAwardSelf";
import { CardState } from "elements/tbui/card/types";
import { PageInfoBidirectional } from "gql/fragments/gql/PageInfoBidirectional";
import { AwardTypeFilter } from "../../../gql/types";
import { TRAILHEAD_BADGES_QUERY } from "./query";
import { getEmptyBadge } from "./emptyBadges";
import { chassis } from "tbme/localState";
import { getSharedUrlValues } from "../localState/urls";

interface DisplayAward {
  id: string;
  isArchived: boolean;
  isSelf: boolean;
  title: string;
  url: string | null;
  imageUrl: string;
  earnedAt: string | null;
  earnedPointsSum: number | null;
}

type Filter = AwardTypeFilter | "ALL";

const FilterTypes: {
  [k: string]: Filter;
} = {
  ...AwardTypeFilter,
  ALL: "ALL",
};

/** The amount of badges initially visible */
export const initialVisible = 8;

/** Page size for pagination */
export const perPage = 24;

export default class extends LightningElement {
  private isSelf: boolean = undefined!;
  private badges: DisplayAward[] = [];
  private labels: LabelTranslations = {};
  private cardState: CardState = "loading";

  private urls: { trailheadUnknownBadge: string } = undefined!;

  private pageInfo: PageInfoBidirectional = {
    hasNextPage: false,
    endCursor: null,
  } as PageInfoBidirectional;
  private badgeTotal: number = 0;
  private firstFetchLoading: boolean = true;
  private activeFilter: Filter = FilterTypes.ALL;
  private fetchingMore = false;
  private fetchMore?: (options: {
    variables: GetTrailheadBadgesVariables;
  }) => Promise<ApolloQueryResult<GetTrailheadBadges>>;
  private refetch?: (
    variables?: Partial<GetTrailheadBadgesVariables>
  ) => Promise<ApolloQueryResult<GetTrailheadBadges>>;

  /** Did the user click show less after loading all badges for the current filter? */
  private viewingLess: boolean = false;
  private shouldFocusNewBadge: boolean = false;
  private previousBadgeCount: number = initialVisible;

  private variables: GetTrailheadBadgesVariables = {
    count: initialVisible,
    after: null,
    filter: null,
    hasSlug: false,
  };

  connectedCallback() {
    this.urls = getSharedUrlValues() as any;
    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    this.isSelf = chassisData?.isSelf || false;

    this.variables = {
      ...this.variables,
      slug: username,
      hasSlug: !!username,
    };
  }

  renderedCallback() {
    if (this.shouldFocusNewBadge) {
      this.focusNewBadge();
    }
  }

  focusNewBadge() {
    const badge = this.visibleBadges[this.previousBadgeCount];

    if (badge) {
      const badgeComponent = this.template.querySelectorAll("lwc-tbui-badge")[
        this.previousBadgeCount
      ];
      const link = badgeComponent?.shadowRoot?.querySelector(
        `a[href='${badge.url}']`
      ) as HTMLAnchorElement;

      if (link) {
        requestAnimationFrame(() => {
          link.focus();
        });
        this.shouldFocusNewBadge = false;
      }
    }
  }

  /** Should the badges component be visible */
  get isVisible() {
    return this.isSelf || (this.hasBadges && !this.firstFetchLoading);
  }

  get hasBadges() {
    return this.badgeTotal > 0;
  }

  /** Does the user have zero trailhead badges? */
  get hasNoneAtAll() {
    return (
      this.cardState !== "loading" &&
      !this.hasBadges &&
      this.activeFilter === FilterTypes.ALL &&
      !this.loadFailed
    );
  }

  /** Does the user have some badges, but not of the active filter? */
  get hasNoneOfType() {
    return this.badges.length === 0 && this.cardState === "default";
  }

  /** The data to show if hasNoneOfType */
  get emptyItem() {
    return getEmptyBadge(
      this.activeFilter as AwardTypeFilter,
      this.isSelf,
      this.labels,
      this.urls
    );
  }

  /** Does the current filter have more than one page? */
  get hasManyPages() {
    return this.activeFilter === FilterTypes.ALL
      ? this.badgeTotal > initialVisible
      : this.badges.length > initialVisible ||
          (this.pageInfo && this.pageInfo.hasNextPage);
  }

  get showViewLess() {
    return this.hasManyPages && this.pageInfo && !this.pageInfo.hasNextPage;
  }

  /** Is the cardState in loadFailed? */
  get loadFailed() {
    return this.cardState === "loadFailed";
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "allBadges",
      "trailheadBasics",
      "trailheadBasicsDescription",
      "failedToUpdate",
      "filterByAllBadges",
      "badgesHeader",
      "superbadgesHeader",
      "filterBySuperBadges",
      "filterByModuleBadges",
      "moduleBadgesHeader",
      "filterByProjectBadges",
      "projectBadgesHeader",
      "filterByEventBadges",
      "eventBadgesHeader",
      "badgeCountHeader",
      "trailheadBadgesFilter",
      "showMore",
      "showLess",
      "filterByPeerAssessmentBadges",
      "peerAssessmentBadgesHeader",
      "unknownBadgeTitle",
      "noneMessageSuperbadgeAltText",
      "noneMessageSuperbadgeItemDescription",
      "noneMessageModuleItemTitle",
      "noneMessageModuleItemDescription",
      "noneMessageProjectItemTitle",
      "noneMessageProjectItemDescription",
      "noneMessageEventItemTitle",
      "noneMessageEventItemDescription",
      "noneEarned",
    ],
  })
  private handleLabels(labels: LabelTranslations) {
    this.labels = {
      ...labels,
      emptyBadgesTitle: legacyFormat(
        labels.badgeCountHeader,
        0,
        labels.badgesHeader
      ),
    };
  }

  @wire(QueryAdapter, {
    query: TRAILHEAD_BADGES_QUERY,
    variables: "$variables",
  })
  private handleResult(
    result: QueryAdapterValue<GetTrailheadBadges, GetTrailheadBadgesVariables>
  ) {
    const { data, loading, error, errors, fetchMore, refetch } = result;

    this.fetchMore = fetchMore;
    this.refetch = refetch;

    if (loading) {
      this.cardState = "loading";
      return;
    }

    if (error || errors) {
      this.cardState = "loadFailed";
      this.firstFetchLoading = false;
      return;
    }

    let profile = data.profile;
    if (!profile) {
      return;
    }

    if (
      profile.__typename !== "PublicProfile" ||
      !profile.earnedAwards ||
      !profile.trailheadStats
    ) {
      this.cardState = "default";
      return;
    }

    const { earnedAwards } = profile;
    const { edges, pageInfo } = earnedAwards;
    const { earnedBadgesCount } = profile.trailheadStats;
    this.badgeTotal = earnedBadgesCount ? Number(earnedBadgesCount) : 0;

    this.badges = this.transformBadges(edges);

    this.pageInfo = pageInfo;
    this.cardState = "default";
    this.firstFetchLoading = false;
  }

  private mergeVariables(updates: Partial<GetTrailheadBadgesVariables>) {
    return {
      ...this.variables,
      ...updates,
    };
  }

  private handleFilterChange({ detail }: CustomEvent) {
    if (this.activeFilter === detail) {
      return;
    }

    this.cardState = "loading";
    this.badges = [];
    this.activeFilter = detail;
    this.variables = this.mergeVariables({
      count: initialVisible,
      after: null,
      filter: detail === FilterTypes.ALL ? null : detail,
    });
  }

  private async handleLoadMore() {
    if (!this.fetchMore) return;

    this.fetchingMore = true;
    this.previousBadgeCount = this.visibleBadges.length;

    await this.fetchMore({
      variables: this.mergeVariables({
        after: this.pageInfo.endCursor,
        count: perPage,
      }),
    });

    this.fetchingMore = false;
    this.shouldFocusNewBadge = true;
  }

  private async handleToggleViewLess() {
    if (!this.refetch) return;

    this.fetchingMore = true;
    await this.refetch(
      this.mergeVariables({
        after: null,
        count: initialVisible,
      })
    );

    this.fetchingMore = false;
  }

  private transformBadges(
    edges:
      | (GetTrailheadBadges_profile_PublicProfile_earnedAwards_edges | null)[]
      | null
  ): DisplayAward[] {
    if (!edges) return [];

    return (edges
      .filter((item) => item !== null)
      .map((item) => item && item.node) as (
      | EarnedAward
      | EarnedAwardSelf
    )[]).map<DisplayAward>((earnedAward) => {
      const { __typename, id, award } = earnedAward;
      let earnedAt = null;
      let earnedPointsSum = null;

      if (__typename === "EarnedAwardSelf") {
        earnedAt = (earnedAward as EarnedAwardSelf).earnedAt;
        earnedPointsSum = (earnedAward as EarnedAwardSelf).earnedPointsSum;
      }

      return {
        id,
        isArchived: !award,
        // This allows the badges to display correctly in a standalone story
        isSelf: __typename === "EarnedAwardSelf",
        title: award ? award.title : this.labels.unknownBadgeTitle,
        url: award && award.content ? award.content.webUrl : "",
        imageUrl: award ? award.icon : this.urls.trailheadUnknownBadge,
        earnedAt,
        earnedPointsSum,
      };
    });
  }

  /** If viewing less, show minimum. Else, show all in memory */
  get visibleBadges() {
    return this.viewingLess
      ? this.badges.slice(0, initialVisible)
      : this.badges;
  }

  /** The label, value, and header for the filter options */
  get filterOptions() {
    const { labels } = this;

    return [
      {
        label: labels.filterByAllBadges,
        value: FilterTypes.ALL,
        header: labels.badgesHeader,
      },
      {
        label: labels.filterBySuperBadges,
        value: FilterTypes.SUPERBADGE,
        header: labels.superbadgesHeader,
      },
      {
        label: labels.filterByModuleBadges,
        value: FilterTypes.MODULE,
        header: labels.moduleBadgesHeader,
      },
      {
        label: labels.filterByProjectBadges,
        value: FilterTypes.PROJECT,
        header: labels.projectBadgesHeader,
      },
      {
        label: labels.filterByEventBadges,
        value: FilterTypes.EVENT,
        header: labels.eventBadgesHeader,
      },
      {
        label: labels.filterByPeerAssessmentBadges,
        value: FilterTypes.STANDALONE,
        header: labels.peerAssessmentBadgesHeader,
      },
    ];
  }

  /** The header of the active filter */
  get activeFilterHeader() {
    const activeOption = this.filterOptions.find(
      (o) => o.value === this.activeFilter
    );

    if (activeOption && activeOption.header) {
      return activeOption.header;
    }

    // Ignore coverage on this return because it is not a realistic scenario
    /* istanbul ignore next */
    return "";
  }

  /**
   * The title of the Card
   * If loading the first fetch, it's "Badges"
   * If some load failed, it's the filter type without the number (i.e. "Superbadges")
   * Else, it's the filter type with the number (i.e. "2 Superbadges")
   */
  get cardTitle() {
    if (this.firstFetchLoading) return this.labels.allBadges;
    if (this.loadFailed) return this.activeFilterHeader;
    if (!this.hasBadges) return this.labels.allBadges;

    if (this.activeFilter === FilterTypes.ALL) {
      return legacyFormat(
        this.labels.badgeCountHeader,
        this.badgeTotal,
        this.filterOptions[0].header
      );
    }

    return this.activeFilterHeader;
  }
}
