/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The type of an Award.
 */
export enum AwardType {
  EVENT = "EVENT",
  MODULE = "MODULE",
  PROJECT = "PROJECT",
  STANDALONE = "STANDALONE",
  SUPERBADGE = "SUPERBADGE",
}

/**
 * A filter on the type of an Award.
 */
export enum AwardTypeFilter {
  EVENT = "EVENT",
  MODULE = "MODULE",
  PROJECT = "PROJECT",
  STANDALONE = "STANDALONE",
  SUPERBADGE = "SUPERBADGE",
}

/**
 * Where the message should be displayed
 */
export enum Location {
  BOTTOM = "BOTTOM",
  TOAST = "TOAST",
  TOP = "TOP",
}

/**
 * This determines the layout orientation of the message
 */
export enum Orientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
