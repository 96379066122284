import { LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import { CardState } from "../../tbui/card/types";
import {
  GetUserCertifications,
  GetUserCertificationsVariables,
  GetUserCertifications_profile_PublicProfile_credential_brands,
  GetUserCertifications_profile_PublicProfile_credential_certifications,
  GetUserCertifications_profile_PublicProfile_credential_messages as Message,
} from "./gql/GetUserCertifications";
import { Location } from "../../../gql/types";
import { showToast } from "tds/toaster";
import { USER_CERTIFICATIONS_QUERY } from "./query";
import { chassis } from "tbme/localState";

export type Certification = Omit<
  GetUserCertifications_profile_PublicProfile_credential_certifications,
  "__typename"
> & { key: string | null };

export type Brand = Omit<
  GetUserCertifications_profile_PublicProfile_credential_brands,
  "__typename"
>;

export const MINIMUM_CERTIFICATIONS_THRESHOLD = 3;

type SortOption = "recent" | "product" | "status";

export const SortType: Record<string, SortOption> = {
  recent: "recent",
  product: "product",
  status: "status",
};

interface CertificationSortOption {
  label: string;
  value: SortOption;
}

type CertificationGroup = {
  product: string;
  logoUrl: string;
  certifications: Certification[];
};

export default class extends LightningElement {
  private isSelf: boolean = false;
  private labels: LabelTranslations = undefined!;
  private firstFetchLoading: boolean = true;
  private cardState: CardState = "default";
  private brands: Brand[] = Array<Brand>();
  private certifications: Certification[] | null = Array<Certification>();
  private messagesOnly: boolean = false;
  private topMessages: Message[] | null = Array<Message>();
  private bottomMessages: Message[] | null = Array<Message>();
  private searchText: string | null = null;
  private showMoreCertifications: boolean = false;
  private defaultOption: SortOption = SortType.product;
  private selectedSortOption: SortOption = SortType.product;

  private variables: GetUserCertificationsVariables = {
    hasSlug: false,
  };

  connectedCallback() {
    const chassisData = chassis();
    const username = chassisData?.profile?.username;
    this.isSelf = chassisData?.isSelf || false;

    this.variables = {
      hasSlug: !!username,
      slug: username,
    };
  }

  renderedCallback() {
    this.focusNewCertification();
  }

  focusNewCertification() {
    if (this.showMoreCertifications) {
      const certification = this.sortedCertifications?.[
        MINIMUM_CERTIFICATIONS_THRESHOLD
      ];

      if (certification) {
        const certItems = this.template.querySelectorAll(
          "lwc-tbui-certification-item"
        );
        const certItemToFocus = certItems[MINIMUM_CERTIFICATIONS_THRESHOLD];
        const link = certItemToFocus?.shadowRoot?.querySelector(
          `a[href='${certification.infoUrl}']`
        ) as HTMLAnchorElement;
        if (link) {
          requestAnimationFrame(() => {
            link.focus();
          });
        }
      }
    }
  }

  /** Formats the results label */
  get searchResultLabel() {
    return t("certifications.searchResultLabel", {
      resultsCount: this.filteredCertifications?.length,
      searchTerm: this.searchText,
    });
  }

  /** Boolean method for whether or not the user is searching */
  get isSearching() {
    return !!this.searchText;
  }

  /** Determines the title of the component */
  get cardTitle() {
    return this.certifications &&
      this.certifications.length > 1 &&
      !this.messagesOnly
      ? `${this.certifications.length} ${this.labels.title}`
      : this.labels.title;
  }

  /** Shows or hides the card actions (ie. Search and Sort) */
  get showActions() {
    return !this.messagesOnly && this.certifications?.length !== 0;
  }

  get filteredCertifications(): Certification[] {
    if (this.searchText === null || this.certifications === null) {
      return [];
    }

    return this.certifications!.filter((cert) => {
      const title = cert.title.toLowerCase();

      return title.includes(this.searchText!.toLowerCase());
    });
  }

  get showGroupedCertifications(): boolean {
    return this.selectedSortOption === SortType.product;
  }

  get groupedCertifications(): CertificationGroup[] {
    if (this.certifications === null) {
      return [];
    }

    const groups = this.certifications!.reduce((record, certification) => {
      if (record[certification.product]) {
        record[certification.product].push(certification);
      } else {
        record[certification.product] = [certification];
      }

      return record;
    }, {} as Record<string, Certification[]>);

    return Object.keys(groups)
      .sort((left, right) => left.localeCompare(right))
      .map((key) => {
        const brand = this.brands.find(
          (brand) => brand.name.toLocaleLowerCase() === key.toLocaleLowerCase()
        );

        return {
          product: brand?.name,
          logoUrl: brand?.logo,
          certifications: this.sortAlphabetically(groups[key]),
        } as CertificationGroup;
      });
  }

  get sortedCertifications(): Certification[] | null {
    if (this.selectedSortOption === SortType.status && this.isSelf) {
      return this.sortByStatus(this.certifications);
    }

    return this.sortByMostRecent(this.certifications);
  }

  get isVisible(): boolean {
    return !(
      !this.isSelf &&
      (this.certifications?.length === 0 || this.messagesOnly)
    );
  }

  private sortByMostRecent(
    certifications: Certification[] | null
  ): Certification[] | null {
    if (certifications === null) {
      return certifications;
    }

    return [...certifications].sort((left, right) => {
      const [leftCompletionDate, rightCompletionDate] = [left, right].map(
        (certification) => new Date(certification.dateCompleted)
      );

      if (leftCompletionDate < rightCompletionDate) {
        return 1;
      }

      if (leftCompletionDate > rightCompletionDate) {
        return -1;
      }

      return 0;
    });
  }

  private sortAlphabetically(
    certifications: Certification[] | null
  ): Certification[] | null {
    if (certifications === null) {
      return certifications;
    }

    return [...certifications].sort((left, right) => {
      return left.title.localeCompare(right.title);
    });
  }

  private sortByStatus(
    certifications: Certification[] | null
  ): Certification[] | null {
    if (certifications === null) {
      return certifications;
    }

    return [...certifications].sort(
      (a, b) =>
        a.status.order! - b.status.order! || a.product.localeCompare(b.product)
    );
  }

  /** The certifications that the user sees */
  get visibleCertifications() {
    if (this.isSearching) {
      return this.filteredCertifications;
    }

    if (!this.showFooter) {
      return this.sortedCertifications;
    }
    return this.showMoreCertifications
      ? this.sortedCertifications
      : this.sortedCertifications?.slice(0, MINIMUM_CERTIFICATIONS_THRESHOLD);
  }

  /** The collection of sort options */
  get sortOptions(): CertificationSortOption[] {
    return [
      ...(this.isSelf
        ? [
            {
              label: this.labels.sortByStatus,
              value: SortType.status,
            },
          ]
        : []),
      {
        label: this.labels.sortByProduct,
        value: SortType.product,
      },
      {
        label: this.labels.sortByMostRecent,
        value: SortType.recent,
      },
    ];
  }

  get showFooter(): boolean {
    return !this.messagesOnly &&
      !this.isSearching &&
      !this.showGroupedCertifications &&
      this.certifications &&
      this.certifications.length > MINIMUM_CERTIFICATIONS_THRESHOLD
      ? true
      : false;
  }

  get footerTitle(): string {
    return this.showMoreCertifications
      ? this.labels["showLess"]
      : this.labels["showMore"];
  }

  handleFooterClick(): void {
    this.showMoreCertifications = !this.showMoreCertifications;
  }

  /** Handles the search event */
  handleSearchEvent(event: CustomEvent) {
    this.searchText = event.detail.value;
    this.resetSelectedSortOption();
  }

  /** Resets the filter selector back to its default value */
  private resetSelectedSortOption(): void {
    this.selectedSortOption = this.isSearching
      ? SortType.recent
      : this.defaultOption;
  }

  /** Handles the tds-select component value change */
  handleSortChange(event: CustomEvent) {
    this.resetSearch();
    this.selectedSortOption = event.detail as SortOption;
  }

  /** Resets the search component */
  private resetSearch() {
    this.searchText = null;
    const element = this.template.querySelector(`[data-id="cert-search"]`);
    const searchInput = element?.shadowRoot?.querySelector(
      ".search-input__input"
    ) as HTMLInputElement;
    if (searchInput) searchInput.value = "";
  }

  @wire(QueryAdapter, {
    query: USER_CERTIFICATIONS_QUERY,
    variables: "$variables",
  })
  private handleResult(result: QueryAdapterValue<GetUserCertifications>) {
    const { data, loading, error, errors } = result;
    if (loading) {
      this.cardState = "loading";
      return;
    }

    let profile = data.profile;
    if (!profile) {
      return;
    }

    if (error || errors || profile.__typename !== "PublicProfile") {
      this.cardState = "loadFailed";
      this.firstFetchLoading = false;
      return;
    }
    this.brands = profile.credential?.brands ?? [];
    this.defaultOption = this.isSelf
      ? SortType.status
      : this.brands.length > 1
      ? SortType.product
      : SortType.recent;
    this.selectedSortOption = this.defaultOption;
    this.messagesOnly = profile.credential?.messagesOnly ?? false;

    this.certifications = this.formatCertifications(
      profile.credential?.certifications ?? []
    );

    this.bottomMessages = [];
    this.topMessages = [];
    profile.credential?.messages?.forEach((item) => {
      switch (item.location) {
        case Location.BOTTOM:
          this.bottomMessages?.push(item);
          break;
        case Location.TOP:
          this.topMessages?.push(item);
          break;
        case Location.TOAST:
          showToast({ message: item.body, variant: "error" });
          break;
      }
    });
    this.cardState = "default";
    this.firstFetchLoading = false;
  }

  /** Formats the certifications to be displayed */
  private formatCertifications(
    edges: GetUserCertifications_profile_PublicProfile_credential_certifications[]
  ): Certification[] {
    if (!edges) return [];
    return edges.map((item, index) => {
      return {
        ...item,
        key: `${index}  ${item!.title}`,
        status: {
          ...item!.status,
          title: item!.status!.title!.replace("_", " "),
        },
      } as Certification;
    });
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "certifications.title",
      "certifications.searchPlaceholder",
      "certifications.sortByProduct",
      "certifications.sortByStatus",
      "certifications.sortByMostRecent",
      "certifications.searchResultLabel",
      "certifications.sortAssistiveText",
      "certifications.showMore",
      "certifications.showLess",
    ],
  })
  private handleLabels(labels: { certifications: LabelTranslations }) {
    this.labels = {
      ...labels.certifications,
    };
  }
}
