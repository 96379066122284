import { assetsUrl } from "tbme/localState";
import { ProfileContext } from "../types";
import { setGlobalContext as setTdsContext } from "tds/contextAdapter";

/**
 * Configure tds components with assets URL so that they can find icons and other resources.
 */
export const setAssetsState = ({ profileResourceUrl }: ProfileContext) => {
  if (!profileResourceUrl) return;

  assetsUrl(profileResourceUrl);

  setTdsContext({
    crossOriginAssets: true,
    assetsUrl: profileResourceUrl,
  });
};
