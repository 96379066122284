import { api, LightningElement, wire } from "lwc";

import { MultiLabelAdapter, LabelTranslations, t } from "tbme/localization";
import { Certification } from "tbme/certifications";

export default class extends LightningElement {
  @api product: string = "";
  @api logoUrl: string = "";
  @api certifications: Certification[] = [];
  @api isSelf: boolean = false;

  isExpanded: boolean = false;

  @wire(MultiLabelAdapter, {
    labels: ["certifications.title"],
  })
  private labels: Record<string, LabelTranslations> = {};

  get subtitle(): string {
    return t("certifications.productCount", {
      count: this.certifications.length,
    });
  }

  toggleVisibility(event: CustomEvent): void {
    this.isExpanded = !this.isExpanded;
  }
}
