import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { MockedResponse, MockLink } from "@apollo/client/testing";
import { createCache } from "../clientAdapter/cache";

export const createMockClient = (
  mocks: MockedResponse[],
  addTypename: boolean = false
) => {
  return new ApolloClient<NormalizedCacheObject>({
    cache: createCache(),
    link: new MockLink(mocks, addTypename),
  });
};
