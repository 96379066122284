import {
  CommunityAsyncResponse,
  LegacyCommunityConnections,
  CommunityStats,
  CommunityUser,
} from "./types";

type CommunityType =
  | Partial<LegacyCommunityConnections>
  | Partial<CommunityStats>
  | Partial<CommunityUser>
  | null
  | Partial<CommunityAsyncResponse>;
type CommunityUserType =
  | Partial<CommunityUser>
  | null
  | Partial<CommunityAsyncResponse>;

const checkForTypename = (data: CommunityType) => {
  if (data && !data.__typename) {
    throw new Error(
      "Error handling CommunityUser data. Did you forget to include __typename in your query?"
    );
  }
};

export const getIsLoading = (data: CommunityType) => {
  checkForTypename(data);
  return data && data.__typename === "CommunityAsyncResponse"
    ? data.isLoading
    : false;
};

export const getError = (data: CommunityType) => {
  checkForTypename(data);
  return data && data.__typename === "CommunityAsyncResponse" && data.error
    ? data.error
    : null;
};

export const getUserId = (data: CommunityUserType) => {
  checkForTypename(data);
  return data && data.__typename === "CommunityUser" ? data.id : null;
};
