import { InMemoryCache, FieldFunctionOptions } from "@apollo/client";
import {
  appLauncherItems,
  profileUser,
  currentUser,
  siteInfo,
  locale,
  localeOptions,
  footerLinks,
  tbcProfileUserId,
  chassis,
} from "tbme/localState";
import { Locale } from "tbme/localization";
import {
  AppLauncherItem,
  Link,
  LocaleOption,
  LocalProfile,
  SiteInfo,
} from "../localState/types";

import { ChassisContext } from "../profilePage/types";

import { urlFieldPolicies } from "../localState/urls";
import {
  legacyCommunityConnections,
  communityStats,
  currentCommunityUser,
  profileCommunityUser,
} from "../../tbtbc/communityData/state";
import { relayStylePagination } from "@apollo/client/utilities";

export const createCache = () =>
  new InMemoryCache({
    addTypename: false,
    typePolicies: {
      SiteUrls: {
        fields: {
          ...urlFieldPolicies,
        },
      },
      User: {
        fields: {
          communityUser: {
            read(value = currentCommunityUser()) {
              return value;
            },
          },
        },
      },
      TrailheadProfileStats: {
        keyFields: [],
      },
      PublicProfile: {
        keyFields: [],
        fields: {
          earnedAwards: relayStylePagination(["awardType"]),
          tbcUserId: {
            read(value = tbcProfileUserId()) {
              return value;
            },
          },
          communityUser: {
            read(value = profileCommunityUser()) {
              return value;
            },
          },
          communityStats: {
            read(value = communityStats()) {
              return value;
            },
          },
          legacyCommunityConnections: {
            read(value = legacyCommunityConnections()) {
              return value;
            },
          },
        },
      },
      SiteInfo: {
        fields: {
          urls: {
            read(value = { __typename: "SiteUrls" }) {
              return value;
            },
          },
        },
      },
      Query: {
        fields: {
          appLauncherItems: {
            read(
              items: AppLauncherItem[] = appLauncherItems(),
              { args }: FieldFunctionOptions
            ) {
              if (args && args.id) {
                return items.filter((item) => item.id === args.id);
              }

              return items;
            },
          },
          appLauncherItem: {
            read(item = undefined, { args }: FieldFunctionOptions) {
              const items = appLauncherItems();
              if (!args || !args.id) {
                return undefined;
              }

              return items.find((item) => item.id === args.id);
            },
          },
          localeOptions: {
            read(options: LocaleOption[] = localeOptions()) {
              return options;
            },
          },
          footerLinks: {
            read(links: Link[] = footerLinks()) {
              return links;
            },
          },
          currentUser: {
            read(user = currentUser()) {
              return user;
            },
          },
          profileUser: {
            read(profile = profileUser()): LocalProfile {
              const user = currentUser();

              const isSelf = !!(user && profile && user.id === profile.id);
              const isPublic = isSelf || (profile && profile.isPublicProfile);

              return {
                __typename: isPublic ? "PublicProfile" : "PrivateProfile",
                isSelf,
                ...profile,
              };
            },
          },
          siteInfo: {
            read(value = siteInfo()): SiteInfo {
              return {
                __typename: "SiteInfo",
                ...value,
              };
            },
          },
          locale: {
            read(value = locale()): Locale {
              return value;
            },
          },
          chassis: {
            read(value = chassis()): ChassisContext {
              return value;
            },
          },
        },
      },
    },
  });

export const cache = createCache();
