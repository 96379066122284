import { ApolloQueryResult, gql } from "@apollo/client";
import { api, LightningElement, wire } from "lwc";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { fetchCommunityProfile } from "tbtbc/communityData";
import { getError, getIsLoading, getUserId } from "../communityData/queryUtils";
import { LegacyCommunityConnections } from "../communityData/types";
import { follow, unfollow } from "../communityData/followActions";
import { FollowingContext } from "./gql/FollowingContext";
import { Size } from "@devforce/th-components/modules/tds/button/button";
import { showToast } from "@devforce/th-components/modules/tds/toaster/toaster";

export const FOLLOWING_QUERY = gql`
  query FollowingContext {
    profileUser @client {
      __typename
      ... on PublicProfile {
        id
        isSelf
        isExternalUser

        legacyCommunityConnections @client {
          __typename
          ... on CommunityAsyncResponse {
            isLoading
            error
          }
          ... on LegacyCommunityConnections {
            viewerCanFollow
            viewerSubscriptionId
          }
        }
      }
    }
  }
`;
export default class extends LightningElement {
  @api following: boolean = false;
  @api size: Size = "small";

  // internal only, for stories
  @api focused: boolean = false;

  @wire(MultiLabelAdapter, {
    labels: [
      "tbcUserActions.follow",
      "tbcUserActions.unfollow",
      "tbcUserActions.following",
      "tbcUserActions.followError",
    ],
  })
  private labels: { tbcUserActions: LabelTranslations } = undefined!;

  private visible = false;
  private pending = false;
  private userId: string = undefined!;
  private isExternalUser: boolean = false;
  private subscriptionId?: string | null;

  @wire(QueryAdapter, {
    query: FOLLOWING_QUERY,
  })
  private handleResult({ data }: ApolloQueryResult<FollowingContext>) {
    const { profileUser } = data || {};

    if (!profileUser || profileUser.__typename !== "PublicProfile") {
      return;
    }

    const { legacyCommunityConnections, isSelf } = profileUser;

    if (
      getIsLoading(legacyCommunityConnections) ||
      getError(legacyCommunityConnections)
    ) {
      this.visible = false;
      return;
    }

    if (!legacyCommunityConnections) {
      this.visible = false;
      return;
    }

    const {
      viewerCanFollow,
      viewerSubscriptionId,
    } = legacyCommunityConnections as LegacyCommunityConnections;

    this.visible = !!(!isSelf && viewerCanFollow);
    this.following = !!viewerSubscriptionId;
    this.subscriptionId = viewerSubscriptionId;
    this.userId = profileUser.id;
    this.isExternalUser = profileUser.isExternalUser;
  }

  private async handleFollowClick() {
    if (this.pending) return;

    const isFollowing = this.following;
    this.pending = true;

    //optimistic change
    this.following = !this.following;

    const result = isFollowing
      ? await unfollow(this.userId, this.isExternalUser, this.subscriptionId!)
      : await follow(this.userId, this.isExternalUser);

    if (result) {
      this.pending = false;
    } else {
      this.following = !this.following;
      showToast({
        variant: "error",
        message: this.labels.tbcUserActions.followError,
      });
    }
  }
}
