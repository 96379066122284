import { AppLauncherItem } from "elements/tbme/localState/types";
import { appLauncherItems } from "tbme/localState";
import { ProfileContext } from "../types";

export class AppLauncherContextError extends Error {}

/**
 * Update appLauncherItem variable from ProfileContext
 */
export const setAppLauncherState = ({ appLauncherLinks }: ProfileContext) => {
  if (!appLauncherLinks) return;

  let items: AppLauncherItem[] = [];

  try {
    items = Object.entries(appLauncherLinks)
      .sort((a, b) => {
        const [aKey, aEntry] = a;
        const [bKey, bEntry] = b;
        return (
          aEntry.UserInterfacePosition__c - bEntry.UserInterfacePosition__c
        );
      })
      .reduce(
        (
          acc: AppLauncherItem[],
          [key, { AppUrl__c, ImageUrl__c, Label }]
        ): AppLauncherItem[] => {
          return [
            ...acc,
            {
              id: key,
              link: { href: AppUrl__c },
              imageUrl: ImageUrl__c,
              label: Label,
            },
          ];
        },
        []
      );
  } catch (e) {
    throw new AppLauncherContextError(
      `Error processing appLauncherLinks: ${e}`
    );
  }

  appLauncherItems(items);
};
