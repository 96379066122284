import { gql } from "@apollo/client";

export const COMMUNITY_TAGS_MUTATION = gql`
  mutation updateCommunityTags($tags: [String!]!) {
    updateCommunityTags(input: { tags: $tags }) {
      ... on UpdateCommunityTagsPayload {
        communityTags {
          id
          title
          imageUrl
        }
      }
    }
  }
`;
