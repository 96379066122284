import { gql } from "@apollo/client";

export default gql`
  type User {
    detail: Actor!
  }
  type ProfileData {
    communityUserId: String!
    isMvp: Boolean
    runningCommunityUserId: String
    communityUserUrls: CommunityUserUrls
    questionAndAnswersStats: QuestionAndAnswersStats
    communityConnections: CommunityConnections
  }

  type CommunityUserUrls {
    communityUserUrls: CommunityUserUrls
    answersUrl: String
    bestAnswersUrl: String
    feedUrl: String
    filesUrl: String
    followersUrl: String
    followingUrl: String
    groupsUrl: String
    inboxUrl: String
    questionsUrl: String
  }

  type QuestionAndAnswersStats {
    answersCount: Int!
    bestAnswersCount: Int!
    communityUserRank: Int
    questionsCount: Int!
  }

  type CommunityConnections {
    followers(after: String, first: Int): ActorWithTotalCountConnection!
    following(after: String, first: Int): ActorWithTotalCountConnection!
    groups(after: String, first: Int): GroupDetailWithTotalCountConnection!
  }

  type ActorWithTotalCountConnection {
    edges: [ActorEdge]
    pageInfo: PageInfo!
    totalCount: Int
  }

  type GroupDetailWithTotalCountConnection {
    edges: [GroupDetailEdge]
    pageInfo: PageInfo!
    totalCount: Int
  }

  type ActorEdge {
    node: Actor!
  }

  type Actor {
    id: ID!
    avatar: String!
    url: String
    name: String!
    isCurrentUser: Boolean!
    following: Boolean
  }

  type GroupDetailEdge {
    node: GroupDetail!
  }

  type GroupDetail {
    id: ID!
    avatar: Avatar!
    url: String
    name: String!
  }

  type Avatar {
    large: String!
  }

  interface Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }

  type InsufficientAccessError implements Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }
  type InvalidArgumentError implements Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }
  type RequestTimeoutError implements Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }
  type UnknownError implements Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }
  type UserNotFoundError implements Error {
    argumentName: String
    clientMessage: String!
    clientMutationId: String
    code: String!
    message: String!
    statusCode: Int!
  }

  type FollowUserInput {
    clientMutationId: String
    userId: ID!
  }

  type FollowUserResult {
    clientMutationId: String
    user: Actor!
  }

  union FollowUserPayload =
      FollowUserResult
    | InsufficientAccessError
    | InvalidArgumentError
    | RequestTimeoutError
    | UnknownError
    | UserNotFoundError

  type UnfollowUserInput {
    clientMutationId: String
    userId: ID!
  }

  type UnfollowUserResult {
    clientMutationId: String
    user: Actor!
  }

  union UnfollowUserPayload =
      UnfollowUserResult
    | InsufficientAccessError
    | InvalidArgumentError
    | RequestTimeoutError
    | UnknownError
    | UserNotFoundError

  extend type Query {
    profileData(userSlug: ID!): ProfileData!
    user(userId: ID!): User!
  }

  extend type Mutation {
    followUser(input: FollowUserInput!): FollowUserPayload
    unfollowUser(input: UnfollowUserInput!): UnfollowUserPayload
  }
`;
