import { memoizeOnce } from "../../../shared/memoize";
import { getErrorMessage, useApexMethod } from "tbme/legacyApexBridge";
import { currentUser } from "tbme/localState";
import { fetchTbcUsers } from "./fetchUsers";
import { legacyCommunityConnections, pendingValue } from "./state";
import { ApexConnectionsResponse, LegacyCommunityConnections } from "./types";
import { sharedUrls } from "../../tbme/localState/urls";

const { tbcUserConnections, tbcUserGroups, tbcGroupDetail } = sharedUrls;

interface ApexArgs {
  cuid: string | null;
  uid: string;
  isMockProfile: boolean;
  contextProfileCommunityUser: object | null;
  currentCommunityUser: object | null;
}

const apexGetConnections = useApexMethod<ApexArgs, ApexConnectionsResponse>(
  "TrailblazerConnectionsController.getCommunityConnections"
);

/**
 * Fetchs raw apex response
 */
export const fetchApexConnections = memoizeOnce(
  async (profileUserId: string, isExternalUser: boolean) => {
    const user = currentUser();
    const currentUserId = user ? user.id : null;
    const tbcUsers = await fetchTbcUsers(profileUserId, isExternalUser);

    if (!(tbcUsers.contextUser && tbcUsers.contextUser.ExternalId)) {
      return Promise.resolve(null);
    }

    return apexGetConnections({
      uid: profileUserId,
      isMockProfile: isExternalUser,
      cuid: currentUserId,
      contextProfileCommunityUser:
        tbcUsers && tbcUsers.contextUser ? tbcUsers.contextUser : null,
      currentCommunityUser:
        tbcUsers && tbcUsers.currentUser ? tbcUsers.currentUser : null,
    });
  }
);

/**
 * Primary entry point
 */
export const fetchConnections = memoizeOnce(
  async (profileUserId: string, isExternalUser: boolean) => {
    legacyCommunityConnections(pendingValue);

    try {
      const response = await fetchApexConnections(
        profileUserId,
        isExternalUser
      );
      legacyCommunityConnections(transformResponse(response));
    } catch (error) {
      legacyCommunityConnections({
        __typename: "CommunityAsyncResponse",
        isLoading: false,
        error: getErrorMessage(error),
      });
    }
  }
);

function transformResponse(
  response: ApexConnectionsResponse | null
): LegacyCommunityConnections | null {
  if (!response || !response.collaborationDataWrappers) {
    return null;
  }

  const result: LegacyCommunityConnections = {
    __typename: "LegacyCommunityConnections",
    viewerSubscriptionId: null,
    viewerCanFollow: false,
    followers: null,
    groups: null,
    following: null,
  };

  const {
    collaborationDataWrappers,
    existingEntitySubscriptionId,
    communityUserStatus,
  } = response;

  if (communityUserStatus) {
    const {
      runningUserCommunityUserId,
      contextProfileUserCommunityUserId,
      contextProfileUserIsActive,
    } = communityUserStatus;

    const user = currentUser();
    const currentUserId = user ? user.id : null;

    result.viewerCanFollow = !!(
      currentUserId &&
      runningUserCommunityUserId &&
      contextProfileUserCommunityUserId &&
      contextProfileUserIsActive
    );

    result.viewerSubscriptionId = existingEntitySubscriptionId || null;
  }

  collaborationDataWrappers.forEach(
    ({ count, typeName, collaborationData }) => {
      result[typeName] = {
        __typename: "CommunityConnectionCollection",
        count,
        url:
          typeName === "groups"
            ? tbcUserGroups()
            : tbcUserConnections({ tab: typeName }),
        connections: collaborationData.map(
          ({ communityIdentifier, imageUrl, linkUrl, name }) => {
            return {
              __typename: "CommunityConnection",
              id: communityIdentifier,
              imageUrl,
              url:
                typeName === "groups"
                  ? tbcGroupDetail({ groupId: communityIdentifier })
                  : linkUrl,
              name,
            };
          }
        ),
      };
    }
  );

  return result;
}
