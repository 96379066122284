import { LightningElement, api, wire } from "lwc";
import { FooterContent, Locale, FooterLink } from "../../tbui/pageFooter/types";
import { GetFooterContext } from "./gql/GetFooterContext";
import { gql, ApolloQueryResult } from "@apollo/client";
import { QueryAdapter } from "tbme/queryAdapter";
import { MultiLabelAdapter, LabelTranslations } from "tbme/localization";

export const FOOTER_QUERY = gql`
  query GetFooterContext {
    currentUser @client {
      id
    }
    footerLinks @client {
      href
      label
      image
    }
    localeOptions @client {
      value
      label
    }
    locale @client
    siteInfo @client {
      urls {
        assets
      }
    }
  }
`;

export default class extends LightningElement {
  @wire(MultiLabelAdapter, {
    labels: [
      {
        labelKey: "copyright",
        year: new Date().getFullYear(),
        salesforceCompanyName: "Salesforce, Inc.",
      },
    ],
  })
  private labels: LabelTranslations = {};

  links: FooterLink[] = [];
  locales: Locale[] = [];
  currentLocale: string = "";
  footerUrl: string = "";
  isAuthenticated: boolean = false;

  get footerContent(): FooterContent {
    return {
      global: {
        logo: {
          assistiveText: "Salesforce",
          url: this.footerUrl,
        },
        copyright: this.labels.copyright,
        links: this.links,
      },
      locales: this.locales,
    };
  }

  @wire(QueryAdapter, {
    query: FOOTER_QUERY,
  })
  handleResult({ data }: ApolloQueryResult<GetFooterContext>) {
    if (!data) {
      return;
    }
    this.links = [...data.footerLinks].map((link) => {
      if (link.image) {
        return {
          ...link,
          image: `${data.siteInfo.urls.assets}/images/${link.image}`,
        };
      }
      return link;
    });
    this.currentLocale = data.locale;
    this.locales = data.localeOptions.map((localeOption) => {
      return {
        ...localeOption,
        selected: localeOption.value === this.currentLocale,
      };
    });

    this.footerUrl = `${data.siteInfo.urls.assets}/images/salesforce.svg`;
    this.isAuthenticated = !!data.currentUser && !!data.currentUser.id;
  }

  private handleLanguageChange(e: CustomEvent) {
    const params = new URLSearchParams(window.location.search);

    // See: https://help.salesforce.com/articleView?id=faq_getstart_what_languages_does.htm&type=5
    // Salesforce uses JDK 8 format for locales. We can achieve this replacing "hyphens" with "underscores"
    // Salesforce doesn't understand "en", you need to indicate a territory.
    let value = e.detail.value;

    if (value === "en") {
      value = "en_US";
    } else {
      value = value.replace("-", "_");
    }

    params.set("lang", value);
    window.location.search = params.toString();
  }
}
