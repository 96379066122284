import { ChassisContextProfile } from "elements/tbme/profilePage/types";

const BASE_URL =
  window.sfdcBase?.exp?.PROFILE_SERVICE_INTERNAL_API_BASE_URL ||
  process.env.PROFILE_SERVICE_INTERNAL_API_BASE_URL;

const EXTERNAL_BASE_URL =
  window.sfdcBase?.exp?.PROFILE_SERVICE_EXTERNAL_API_BASE_URL ||
  process.env.PROFILE_SERVICE_EXTERNAL_API_BASE_URL;

type UploadImageResponse = {
  upload: {
    url: string;
    fields: Record<string, never>;
  };
  download: {
    url: string;
  };
};

type DeleteImageResponse = {
  photoUrl: string;
};
const getUserImageUploadResponse: () => Promise<
  UploadImageResponse
> = async () => {
  const ENDPOINT = "/v1/userprofile/generate-image-upload-url";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  } as RequestInit;

  try {
    const response = await fetch(`${BASE_URL}${ENDPOINT}`, options);
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }
    return response.json() as Promise<UploadImageResponse>;
  } catch (e) {
    throw new Error(JSON.stringify(e));
  }
};

export const uploadUserImage: (
  imageUrl: string,
  fileName: string
) => Promise<string | Error> = async (imageUrl: string, fileName: string) => {
  try {
    const { upload } = await getUserImageUploadResponse();
    const blob = await fetch(imageUrl).then((r) => r.blob());
    const form = new FormData();
    Object.entries(upload.fields).forEach(([field, value]) => {
      form.append(field, value);
    });
    form.append("Content-Type", blob.type);
    form.append("file", new File([blob], fileName, { type: blob.type }));
    var options = {
      method: "POST",
      body: form,
    };
    const response = await fetch(upload.url, options);
    URL.revokeObjectURL(imageUrl);
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }
    const responseText = await response.text();
    const parser = new DOMParser();
    const xmlDOM = parser.parseFromString(responseText, "text/xml");
    const value =
      xmlDOM.getElementsByTagName("Key")[0].childNodes[0].nodeValue ?? "";
    return Promise.resolve(
      `${EXTERNAL_BASE_URL.substring(
        0,
        EXTERNAL_BASE_URL.lastIndexOf("/")
      )}/${value}`
    ) as Promise<string>;
  } catch (e) {
    return new Error(JSON.stringify(e));
  }
};

export const deleteUserImage = async (): Promise<DeleteImageResponse> => {
  const ENDPOINT = "/v1/userprofile/profile-image";
  const options = {
    method: "DELETE",
    credentials: "include",
  } as RequestInit;

  try {
    const response = await fetch(`${BASE_URL}${ENDPOINT}`, options);
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text);
    }
    return response.json() as Promise<DeleteImageResponse>;
  } catch (e) {
    throw new Error(JSON.stringify(e));
  }
};

export type UserProfile = ChassisContextProfile;

export const getProfile = async (): Promise<UserProfile> => {
  const ENDPOINT = "/v1/userprofile";
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  } as RequestInit;
  const result = await fetch(`${BASE_URL}${ENDPOINT}`, options);
  if (!result.ok) {
    const text = await result.text();
    throw new Error(text);
  }

  const response = await result.json();
  return response as UserProfile;
};

export const updateProfile: (
  values: Partial<UserProfile>
) => Promise<UserProfile> = async (values: Partial<UserProfile>) => {
  const ENDPOINT = "/v1/userprofile";
  const options = {
    body: JSON.stringify(values),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "PATCH",
  } as RequestInit;

  const result = await fetch(`${BASE_URL}${ENDPOINT}`, options);
  if (!result.ok) {
    const text = await result.text();
    throw new Error(text);
  }

  const response = await result.json();
  return response as UserProfile;
};
