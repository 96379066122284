export type AnalyticElementTypes =
  | "button"
  | "text-button"
  | "radio-button"
  | "";

export interface AnalyticModule {
  name: string;
  link: {
    text: string;
    url: string | null;
    elementType: AnalyticElementTypes;
  };
}

export type AnalyticEventTypes = "custEv_contentClick";

export interface AnalyticEvent {
  event: AnalyticEventTypes;
  blade: {
    name: string;
    type: "blade";
    module: AnalyticModule[];
  };
}

export const sendAnalyticEvent = (
  bladeName: string,
  analyticModule: AnalyticModule,
  eventName: AnalyticEventTypes = "custEv_contentClick"
) => {
  window.dataLayer?.push({
    event: eventName,
    blade: {
      name: bladeName,
      type: "blade",
      module: [
        {
          name: analyticModule.name,
          link: {
            text: analyticModule.link.text,
            url: analyticModule.link.url,
            elementType: analyticModule.link.elementType,
          },
        },
      ],
    },
  });
};
