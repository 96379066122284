import { LightningElement, api, wire } from "lwc";
import classNames from "classnames";
import { assignedSlotNames } from "common/slot";
import {
  MultiLabelAdapter,
  LabelTranslations,
  legacyFormat,
} from "tbme/localization";

import { CardState } from "./types";

export default class extends LightningElement {
  @api title!: string;
  @api subtitle?: string;
  @api flush?: boolean = false;
  @api state?: CardState = "default";
  @api hasHeader?: boolean = false;

  private _errorText?: string | null;
  private _hasFooter?: boolean = false;
  private slots: string[] = [];

  @api
  get errorText() {
    if (this._errorText) return this._errorText;
    return this.labels.failedToUpdate;
  }
  set errorText(value) {
    this._errorText = value || null;
  }

  @api
  get hasFooter() {
    return this.isDefault && this._hasFooter;
  }
  set hasFooter(value) {
    this._hasFooter = value;
  }

  @api
  get className() {
    return classNames("card", {
      card_loading: this.isLoading,
      card_flush: this.flush,
      "card_no-body": !this.hasBodySlot && this.isDefault,
      "p-top_large": !this.hasHeader && !this.flush,
      "p-bottom_x-large": this.isLoadFailed && this.hasHeader,
    });
  }

  @wire(MultiLabelAdapter, {
    labels: ["cardData", "failedToUpdate"],
  })
  private labels: LabelTranslations = undefined!;

  get bodyClass() {
    return classNames("card__body", {
      card__body_inner: this.isDefault,
    });
  }

  get isDefault() {
    return this.state === "default";
  }

  get isLoading() {
    return this.state === "loading";
  }

  get isLoadFailed() {
    return this.state === "loadFailed";
  }

  get hasBodySlot() {
    return this.slots.includes("default");
  }

  private onSlotChange() {
    this.slots = assignedSlotNames(this.template);
  }
}
