import { LightningElement, api, wire } from "lwc";
import { assetsUrl } from "tbme/localState";
import {
  ReactiveVarAdapter,
  ReactiveVarAdapterConfig,
} from "tbme/reactiveVarAdapter";
import { SiteInfo } from "../../tbme/localState/types";
import { TeamName } from "./types";

export type GetHeaderContextResult = {
  siteInfo: SiteInfo;
};
export default class extends LightningElement {
  @api team: TeamName = undefined!;
  @api notificationHeader: string = undefined!;
  @api notificationBody: string = undefined!;

  notificationImages: {
    trailhead?: string;
    trailblazerCommunity?: string;
    appExchange?: string;
    developerMarketplace?: string;
  } = {};

  backgroundClasses = {
    trailhead: "bg-trailhead",
    trailblazerCommunity: "bg-trailblazerCommunity",
    appExchange: "bg-appExchange",
    developerMarketplace: "bg-developerMarketplace",
  };

  get notificationImage() {
    return this.notificationImages[this.team];
  }

  get className() {
    return `container ${this.backgroundClasses[this.team]}`;
  }

  @wire(ReactiveVarAdapter, {
    var: assetsUrl,
  })
  handleResult(prefix: string) {
    this.notificationImages = {
      trailhead: `${prefix}/images/earnie.svg`,
      trailblazerCommunity: `${prefix}/images/astro.svg`,
      appExchange: `${prefix}/images/appy.svg`,
      developerMarketplace: `${prefix}/images/codey.svg`,
    };
  }
}
