import { gql } from "@apollo/client";

export const FOLLOWING_QUERY = gql`
  query FollowingQuery {
    chassis @client {
      profile {
        username
      }
      isSelf
    }
  }
`;

export const TBC_FOLLOWING_QUERY = gql`
  query GetIsFollowing(
    $userSlug: ID!
    $userId: ID!
    $queryUserData: Boolean!
    $queryUser: Boolean!
  ) {
    profileData(userSlug: $userSlug) @include(if: $queryUserData) {
      communityUserId
    }
    user(userId: $userId) @include(if: $queryUser) {
      detail {
        isCurrentUser
        following
      }
    }
  }
`;
