import { api, LightningElement } from "lwc";
import { GetUserCertifications_profile_PublicProfile_credential_messages as Message } from "../../tbme/certifications/gql/GetUserCertifications";
import { Orientation } from "../../../gql/types";

export default class extends LightningElement {
  @api message: Message | null = null;
  sanitizedHTMLConfig = {
    ALLOWED_TAGS: ["b", "div", "span", "p"],
    ALLOWED_ATTR: ["style", "class"],
  };

  get isHorizontal() {
    return this.message?.orientation === Orientation.HORIZONTAL;
  }

  get isVertical() {
    return this.message?.orientation === Orientation.VERTICAL;
  }

  get hasCta() {
    return this.message?.cta ? true : false;
  }

  get htmlBody() {
    return `<span class="message_body">${this.message!.body}</span>`;
  }
}
