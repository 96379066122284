import { ApolloQueryResult, FetchResult } from "@apollo/client";
import { api, LightningElement, wire, track } from "lwc";
import { LabelTranslations, MultiLabelAdapter } from "tbme/localization";
import { QueryAdapter } from "tbme/queryAdapter";
import { Size } from "@devforce/th-components/modules/tds/button/button";
import { showToast } from "@devforce/th-components/modules/tds/toaster/toaster";
import { FOLLOWING_QUERY, TBC_FOLLOWING_QUERY } from "./query";
import { FollowingQuery } from "./gql/FollowingQuery";
import { GetIsFollowing, GetIsFollowingVariables } from "./gql/GetIsFollowing";
import {
  QueryAdapter as TBCQueryAdapter,
  QueryAdapterValue as TBCQueryAdapterValue,
} from "tbtbc/queryAdapter";
import { FOLLOW_USER_MUTATION, UNFOLLOW_USER_MUTATION } from "./mutation";
import { getClient } from "tbtbc/clientAdapter";
import {
  followUser_followUser_FollowUserResult,
  followUser,
} from "./gql/followUser";
import {
  unfollowUser_unfollowUser_UnfollowUserResult,
  unfollowUser,
} from "./gql/unfollowUser";

export default class extends LightningElement {
  @api size: Size = "small";

  // internal only, for stories
  @api focused: boolean = false;

  @wire(MultiLabelAdapter, {
    labels: [
      "tbcUserActions.follow",
      "tbcUserActions.unfollow",
      "tbcUserActions.following",
      "tbcUserActions.followError",
    ],
  })
  labels: { tbcUserActions: LabelTranslations } = undefined!;

  following: boolean = false;
  visible = false;
  isLoading = false;
  userId: string = undefined!;
  isExternalUser: boolean = false;
  isSelf: boolean = false;
  subscriptionId?: string | null;
  @track
  variables: GetIsFollowingVariables = {
    userSlug: "",
    userId: "",
    queryUser: false,
    queryUserData: false,
  };

  @wire(QueryAdapter, {
    query: FOLLOWING_QUERY,
  })
  private handleResult({
    data,
    error,
    errors,
  }: ApolloQueryResult<FollowingQuery>) {
    if (!data) {
      return;
    }
    const { chassis } = data;

    this.isSelf = chassis?.isSelf ?? false;

    if (error || errors) {
      this.visible = false;
      return;
    }

    if (data.chassis?.profile?.username) {
      this.variables.userSlug = data.chassis.profile.username;
      this.variables.queryUserData = true;
    }
  }

  @wire(TBCQueryAdapter, {
    query: TBC_FOLLOWING_QUERY,
    variables: "$variables",
  })
  private handleTbcResult({
    data,
    error,
    errors,
  }: TBCQueryAdapterValue<GetIsFollowing>) {
    if (!data) return;
    if (
      this.variables.queryUserData === true &&
      data.profileData?.communityUserId &&
      this.variables.queryUser === false
    ) {
      this.variables = {
        ...this.variables,
        queryUser: true,
        userId: data.profileData.communityUserId,
      };
    } else if (
      this.variables.queryUserData === true &&
      data.user &&
      this.variables.queryUser === true
    ) {
      this.following = data.user.detail?.following ?? false;
      this.visible = !data.user.detail?.isCurrentUser && !this.isSelf;
    }
  }

  private async handleFollowClick() {
    if (this.isLoading) {
      return;
    }
    const operation = this.following
      ? UNFOLLOW_USER_MUTATION
      : FOLLOW_USER_MUTATION;
    const options = {
      variables: { userId: this.variables.userId },
      mutation: operation,
    };

    const request = () =>
      getClient().mutate<unfollowUser | followUser>(options);
    try {
      this.isLoading = true;
      const { data, errors } = await request();
      if (errors || !data) {
        showToast({
          variant: "error",
          message: this.labels.tbcUserActions.followError,
        });
      } else {
        const followResult = (data as followUser)
          .followUser as followUser_followUser_FollowUserResult;
        const unfollowResult = (data as unfollowUser)
          .unfollowUser as unfollowUser_unfollowUser_UnfollowUserResult;
        if (unfollowResult) {
          this.following = unfollowResult.user.following ?? false;
        } else if (followResult) {
          this.following = followResult.user.following ?? false;
        }
      }
    } catch (error) {
      showToast({
        variant: "error",
        message: this.labels.tbcUserActions.followError,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
