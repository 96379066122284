import { Locale } from "tbme/localization";
import { locale } from "tbme/localState";
import { ProfileContext } from "../types";
import { setLanguage } from "@sfdc-www/digital-i18n";

/**
 * Update locale variable from identity object set by visualforce page.
 */
export const setLocaleState = ({ languageParam }: ProfileContext) => {
  if (!languageParam) {
    return;
  }

  locale(convertLocale(languageParam) as Locale);
  setLanguage(languageParam);
};

export function convertLocale(input: string) {
  return input.replace("en_US", "en").replace("_", "-");
}
