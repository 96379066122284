import { chassis } from "tbme/localState";
import {
  ChassisContext,
  ChassisContextProfile,
  ProfileContext,
} from "../types";
import { t } from "tbme/localization";

/**
 * Set chassis context from digital-profile chassis
 */
export const setChassisState = ({ chassis: context }: ProfileContext) => {
  if (!context) return;
  chassis(context);
};

/**
 * Get profile user full name or a default value if neither first name or last name are present
 */
export const getFullName = (
  profile?: Pick<ChassisContextProfile, "firstName" | "lastName">
) => {
  const firstName = profile?.firstName?.trim() || "";
  const lastName = profile?.lastName?.trim() || "";

  if (!firstName && !lastName) {
    return t("privateProfileOtherDefaultName");
  } else {
    return `${firstName} ${lastName}`.trim();
  }
};
