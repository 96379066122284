import { LightningElement, api } from "lwc";

/** The minimum space between a wrapped element & the left boundary */
const minLeft = 16;

/**
 * Ensures that an element does not bleed out of the viewport.
 * Should be primarily used on absolute-positioned elements, such as popovers.
 * Adjusts the position of the wrapped element by adding a transform: translateX() style.
 * Should wrap exactly one node like so:
 *
 * Valid (1 wrapped element):
 * <tbui-stay-in-bounds is-active={isActive}>
 *   <div style="position: absolute;"></div>
 * </tbui-stay-in-bounds>
 *
 * Invalid (Many wrapped elements):
 * <tbui-stay-in-bounds is-active={isActive}>
 *   <div style="position: absolute;"></div>
 *   <div style="position: absolute;"></div>
 * </tbui-stay-in-bounds>
 */
export default class StayInBounds extends LightningElement {
  /** Should the component recalculate the wrapped element's position? */
  @api isActive: boolean = false;

  /** The "left" position of the wrapped element's DOMRect */
  private positionLeft: number | undefined;
  private _wrappedElement: Element = null!;

  /** Did we already calculate the position after isActive was set to true? */
  get didCalculate() {
    return this.isActive && this.positionLeft !== undefined;
  }

  /** Did we already nullify the position after isActive was set to false */
  get didReset() {
    return !this.isActive && this.positionLeft === undefined;
  }

  /** The amount we need to translate the wrapped element to keep it in the viewport with the minLeft */
  get offsetLeft() {
    return this.positionLeft === undefined || this.positionLeft! >= minLeft
      ? null
      : minLeft - this.positionLeft!;
  }

  /** The CSS transform to apply to the wrapped element based on the offsetLeft */
  get transform() {
    const { offsetLeft } = this;
    return offsetLeft === null ? "" : `translateX(${offsetLeft}px)`;
  }

  /** The element to keep in the viewport */
  get wrappedElement() {
    if (!this._wrappedElement) {
      this._wrappedElement = (this.template
        .firstChild as HTMLSlotElement)!.assignedElements()[0];
    }
    return this._wrappedElement;
  }

  /** Set and reset the positionLeft when needed, using the DOMRect of the wrapped element */
  renderedCallback() {
    if (this.didCalculate || this.didReset) return;
    this.positionLeft = this.isActive
      ? this.wrappedElement!.getBoundingClientRect().left
      : undefined;
    (this.wrappedElement as HTMLElement).style.transform = this.transform;
  }
}
