import { LightningElement, api, wire } from "lwc";
import { MultiLabelAdapter, LabelTranslations, t } from "tbme/localization";
import classNames from "classnames";

export default class SkillButton extends LightningElement {
  @wire(MultiLabelAdapter, {
    labels: ["skillsAriaButtonFormat"],
  })
  private labels: LabelTranslations = {};

  @api skillId: string = undefined!;
  @api label: string = undefined!;
  @api unitTotalPerSkill: number = 0;

  @api color: string = undefined!;
  @api isHovered: boolean = false;
  @api isSelected: boolean = false;

  private get buttonClass(): string {
    return classNames({
      "button-selected": this.isSelected,
      "button-hovered": this.isHovered,
    });
  }

  private get buttonAriaLabel(): string {
    return t("skillsAriaButtonFormat", {
      skill: this.label,
      percent: this.unitTotalPerSkill,
    });
  }

  handleClick(): void {
    this.isSelected = true;
    const button = this.template.querySelector("button");
    button!.focus();
  }

  handleFocus(): void {
    this.isSelected = true;
    this.dispatchEvent(
      new CustomEvent("skillitemfocus", {
        detail: {
          id: this.skillId,
        },
      })
    );
  }

  handleBlur(): void {
    this.isSelected = false;
    this.dispatchEvent(new CustomEvent("skillitemblur"));
  }

  handleMouseEnter(): void {
    this.isHovered = true;
    this.dispatchEvent(
      new CustomEvent("skillitemmouseenter", {
        detail: {
          id: this.skillId,
        },
      })
    );
  }

  handleMouseLeave(): void {
    this.isHovered = false;
    this.dispatchEvent(new CustomEvent("skillitemmouseleave"));
  }
}
