import { gql } from "@apollo/client";

export const EARNED_SKILLS_QUERY = gql`
  fragment EarnedSkill on EarnedSkill {
    __typename
    earnedPointsSum
    id
    itemProgressEntryCount
    skill {
      __typename
      apiName
      id
      name
    }
  }

  query GetEarnedSkills(
    $slug: String
    $hasSlug: Boolean!
  ) {
    profile(slug: $slug) @include(if: $hasSlug) {
      __typename
      ... on PublicProfile {
        id
        earnedSkills {
          ...EarnedSkill
        }
      }
    }
  }
`;
