import { gql } from "@apollo/client";

export const PROFILE_METADATA_QUERY = gql`
  query GetChassisProfileMetadata {
    chassis @client {
      profile {
        isPublicProfile
        firstName
        lastName
        bio
        title
        role
        company {
          name
        }
        username
      }
    }

    siteInfo @client {
      urls {
        profile
      }
    }
  }
`;
